import { isEmpty } from 'lodash'

export const ROOT_HOSTNAME =
  process.env.NEXT_PUBLIC_ROOT_HOSTNAME ||
  (process.env.NODE_ENV === 'production'
    ? `https://upstreamapp.com`
    : process.env.NEXT_PUBLIC_COOKIE_DOMAIN === '.upstream.local'
    ? `http://upstream.local:5002`
    : `http://localhost:5002`)

export const isValidEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null
}

export function isPresentAndTruthy<T>(
  t: T | undefined | null | void | boolean
): t is T {
  return t !== undefined && t !== null && t !== false
}

export function empty(value: any): value is null {
  return isEmpty(value)
}

export function compactNumber(value: number) {
  if (value <= 999) {
    return value.toString()
  }

  const suffixes = ['', 'k', 'm', 'b', 't']
  const suffixNum = Math.floor(('' + value).length / 3)
  let shortValue: any = parseFloat(
    (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  )
  if (shortValue % 1 != 0) {
    shortValue = shortValue.toFixed(1)
  }
  return shortValue + suffixes[suffixNum]
}

export function dedupe(str: string, dedupe: string) {
  return str.replace(new RegExp(`${dedupe} ${dedupe}`, 'i'), dedupe)
}
