import { useApolloClient } from '@apollo/client'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import Bugsnag from 'lib/bugsnag'
import { TokenQuery, ViewerQuery } from 'graphql/documents'
import {
  Viewer,
  ViewerFieldsFragment,
  ViewerQueryResult
} from 'graphql/generated'
import useClearApolloCache from './useClearApolloCache'
import Cookies from 'js-cookie'
import { useAnalyticsContext } from 'context/AnalyticsContext'
import moment from 'moment'

type TLoginTypes = {
  method: 'Email+Password' | 'Apple' | 'Wallet' | 'Token'
  type: 'signup' | 'login'
}

type TLoginOptions = {
  tracking: TLoginTypes
  redirectExplicit?: Maybe<
    string | CallbackWithParam<ViewerFieldsFragment, string> | false
  >
  successCallback?: Callback
}

function useLogin() {
  const clearCache = useClearApolloCache()
  const client = useApolloClient()
  const router = useRouter()
  const { identify } = useAnalyticsContext()

  const redirectQueryParam = router.query._r as Maybe<string>

  const trackLogin = useCallback(
    (
      viewer: Pick<Viewer, 'createdAt' | 'id'>,
      method: TLoginTypes['method'],
      type: TLoginTypes['type']
    ) => {
      const tenSecondsAgo = moment().subtract(10, 'seconds')
      const createdRecently = moment(viewer.createdAt).isAfter(tenSecondsAgo)
      type =
        type === 'login' && method === 'Wallet' && createdRecently
          ? 'signup'
          : 'login' // WHAT A HACK. we create accounts via wallet login method but dont report back it was newly created. so had to check user created timestamp

      if (type === 'login') {
        window.analytics?.trackAll(`Account - Log In - Complete`, {
          method
        })
      } else {
        window.analytics?.trackAll(`Account - Sign Up - Complete`, {
          method
        })
      }
    },
    []
  )

  const login = useCallback(
    async (
      token: string,
      { tracking, redirectExplicit, successCallback }: TLoginOptions
    ) => {
      // Clear Apollo's local cache on login... why? the idea is that we want
      // to avoid showing the user any cached logged-out views after they complete
      // the sign up process... e.g. user visits an Event page, signs up, verifies
      // their info, and gets redirected back to the original Event page. Clearing
      // Apollo's cache now will ensure that the second visit to that page will
      // reload the required fresh data.
      await clearCache()

      Cookies.set('token', token, {
        secure: process.env.NEXT_PUBLIC_SECURE_COOKIES === 'true',
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || '.upstreamapp.com'
      })
      client.writeQuery({
        query: TokenQuery,
        data: {
          token
        }
      })

      const viewerResp = await client.query<ViewerQueryResult>({
        query: ViewerQuery,
        fetchPolicy: 'network-only',
        variables: {
          deviceInfo: {
            appVersion: '1.0',
            systemVersion: 'web',
            timezone:
              typeof window !== 'undefined'
                ? Intl.DateTimeFormat().resolvedOptions().timeZone
                : undefined
          }
        }
      })
      const viewer = viewerResp.data.me
      Bugsnag.setUser(viewer.id, viewer.email, viewer.displayName || '')
      identify(viewer)

      if (successCallback) {
        setTimeout(successCallback, 1000)
      }

      trackLogin(viewer, tracking.method, tracking.type)

      // calculate the redirect rul
      function getRedirectUrl() {
        const redirectLocalStorage = window.localStorage?.getItem('_r')

        if (redirectQueryParam) {
          return redirectQueryParam
        } else if (
          redirectExplicit !== null &&
          redirectExplicit !== undefined
        ) {
          return typeof redirectExplicit === 'function'
            ? redirectExplicit(viewer)
            : redirectExplicit
        } else if (redirectLocalStorage) {
          window.localStorage.removeItem('_r')
          return redirectLocalStorage
        }

        return null
      }

      // if the redirection url is different than where we currently
      // are, go there, otherwise, trigger a page reload so that
      // nextjs can re-read
      const redirectTo = getRedirectUrl()
      if (redirectTo) {
        window.location.href = redirectTo
      }
    },
    [clearCache, client, identify, redirectQueryParam, trackLogin]
  )

  return login
}

export default useLogin
