import StyledTooltip from 'components/Tooltip/Tooltip'
import {
  getEtherscanUrl,
  getEtherscanUrlForToken
} from 'lib/collectives/helpers'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EthNetwork } from 'graphql/generated'
import EnsResolvedAddress from './EnsResolvedAddress'
import { useMemo } from 'react'
import Typography from 'components/common/Typography'
import { twMerge } from 'tailwind-merge'

export type EtherscanLinkType = 'address' | 'block' | 'token' | 'tx' | 'nft'
interface IProps {
  inline?: boolean
  network?: EthNetwork
  type?: EtherscanLinkType
  address: string
  label?: string
  iconOnly?: boolean
  shortAddress?: boolean
  className?: string
  tokenId?: Maybe<string>
  style?: 'dotted' | 'default'
}

export const calculateShortAddress = (address: string) =>
  `${address.substring(0, 5)}...${address.slice(-4)}`

function EtherscanLink({
  network,
  type,
  address,
  iconOnly,
  label,
  shortAddress,
  inline = false,
  className,
  tokenId,
  style = 'default'
}: IProps) {
  const effectiveLink = useMemo(() => {
    return tokenId
      ? getEtherscanUrlForToken(
          network ?? EthNetwork.GOERLI,
          type ?? 'address',
          address,
          tokenId
        )
      : getEtherscanUrl(
          network ?? EthNetwork.GOERLI,
          type ?? 'address',
          address
        )
  }, [address, network, tokenId, type])

  return (
    <Typography
      size="sm"
      newTab
      href={effectiveLink}
      className={twMerge(
        'cursor-pointer hover:text-blue',
        !inline && 'flex',
        style === 'dotted' &&
          'border-b border-gray-400 border-dashed hover:text-gray-900 hover:border-gray-900',
        className
      )}
      onClick={e => e.stopPropagation()}
    >
      <StyledTooltip arrow title={`Open ${address} in Etherscan`}>
        <span className={!inline ? 'flex-1' : ''}>
          {!iconOnly &&
            (label || (
              <EnsResolvedAddress
                address={address}
                fallback={
                  iconOnly
                    ? undefined
                    : shortAddress
                    ? calculateShortAddress(address)
                    : address
                }
              />
            ))}

          {style !== 'dotted' && (
            <FontAwesomeIcon icon={faExternalLink} size="sm" className="ml-1" />
          )}
        </span>
      </StyledTooltip>
    </Typography>
  )
}

export default EtherscanLink
