import { ChangeEvent, useCallback, useEffect, useState } from 'react'

export type TEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
export type TControlledInput = [
  string,
  (e: TEvent | string) => void,
  Callback,
  CallbackWithParam<string>
]

const isClient = !!(typeof window !== 'undefined' && window.localStorage)

function useControlledInput(
  initialValue: Maybe<string> = '',
  localStoragePersistenceKey?: Maybe<string>
): TControlledInput {
  const [value, setValue] = useState(() => {
    const _persistedValue =
      localStoragePersistenceKey && isClient
        ? window.localStorage?.getItem(localStoragePersistenceKey)
        : null
    return _persistedValue || initialValue || ''
  })

  useEffect(() => {
    if (!localStoragePersistenceKey) {
      return
    }

    window.localStorage?.setItem(localStoragePersistenceKey, value)
  }, [value, localStoragePersistenceKey])

  const onChange = useCallback((e: TEvent) => {
    setValue(typeof e === 'string' ? e : e.target.value)
  }, [])

  const clearValue = useCallback(() => setValue(''), [])

  return [value, onChange, clearValue, setValue]
}

export default useControlledInput
