import { ChannelAPIResponse, StreamChat, Channel } from 'stream-chat'
import {
  DefaultChannelType,
  DefaultStreamChatGenerics,
  DefaultUserTypeInternal
} from 'stream-chat-react/dist/types/types'

export type CustomMessageDataType = {}

type LocalUserType = {
  id?: string
} & DefaultUserTypeInternal

type LocalChannelType = DefaultChannelType

export type StreamChatGenerics = {
  userType: LocalUserType
  channelType: LocalChannelType
} & DefaultStreamChatGenerics

export type CustomChannelType = Channel<StreamChatGenerics>

export type CustomStreamChatType = StreamChat<StreamChatGenerics>

export type CustomApiResponseType = ChannelAPIResponse<StreamChatGenerics>

export enum ChannelTypes {
  Collectives = 'collectives',
  Messaging = 'messaging',
  LiveStream = 'livestream'
}

export enum ChatRoles {
  User = 'user',
  Member = 'member',
  Moderator = 'moderator',
  Admin = 'admin'
}
