import React, { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

type TDuration =
  | 0
  | 75
  | 100
  | 150
  | 200
  | 300
  | 500
  | 700
  | 1000
  | 2000
  | 'DEFAULT'

const Durations: { [key in TDuration]: string } = {
  0: 'duration-0',
  75: 'duration-75',
  100: 'duration-100',
  150: 'duration-150',
  200: 'duration-200',
  300: 'duration-300',
  500: 'duration-500',
  700: 'duration-700',
  1000: 'duration-1000',
  2000: 'duration-[2000ms]',
  DEFAULT: 'duration-150'
}

export interface FadeProps {
  in: boolean
  duration?: TDuration | 'DEFAULT'
  delay?: number
  children?: React.ReactNode
  removeOnHide?: boolean
}

const Fade = React.forwardRef<HTMLElement, FadeProps>(
  (
    {
      in: inProp,
      duration = 'DEFAULT',
      children,
      delay = 0,
      removeOnHide = true
    },
    ref
  ) => {
    const [show, setShow] = useState(false)
    useEffect(() => {
      if (inProp && !show) {
        setTimeout(() => setShow(true), delay)
      } else if (!inProp) {
        setShow(false)
      }
    }, [delay, inProp, show])

    const child = React.Children.only(children)

    return React.isValidElement(child)
      ? React.cloneElement(child, {
          //@ts-ignore
          ref,
          className: twMerge(
            child.props.className,
            removeOnHide && !inProp && 'hidden',
            `transition-all ease-in-out ${Durations[duration]} delay-${delay}`,
            show ? 'opacity-100' : 'opacity-0 overflow-hidden'
          )
        })
      : null
  }
)

Fade.displayName = 'Fade'

export default Fade
