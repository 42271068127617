import { utils } from 'ethers'
import { useCallback } from 'react'
import { useWeb3 } from 'context/Web3Context'
import useSessionStorage from 'hooks/useSessionStorage'

function useResolveEns() {
  const { web3 } = useWeb3()
  const [cache, setCache] = useSessionStorage<KVMap<string>>('ensCache', {})

  return useCallback(
    async (address: Maybe<string>) => {
      if (!web3 || !address || !utils.isAddress(address)) {
        return null
      }

      try {
        if (cache[address]) {
          return cache[address]
        }

        const ensName = await web3.lookupAddress(address)
        if (ensName) {
          setCache({ ...cache, [address]: ensName })
        }

        return ensName
      } catch (ex) {
        // ignored
        console.log(ex)
      }

      return null
    },
    [web3, cache, setCache]
  )
}

export default useResolveEns
