import { FieldPolicy, Reference } from '@apollo/client/cache'

type KeyArgs = FieldPolicy<any>['keyArgs']

type Maybe<T> = T | null | undefined

type TInternalRelay<TNode> = Readonly<{
  edges: Array<{
    cursor: string
    node: TNode
  }>
  pageInfo: Readonly<{
    hasPreviousPage: boolean
    hasNextPage: boolean
    startCursor: string
    endCursor: string
  }>
}>

export function relayStylePagination<TNode extends Reference = Reference>(
  keyArgs: KeyArgs = false,
  paginationKey: Maybe<string> = null
): FieldPolicy<TInternalRelay<TNode>> {
  return {
    keyArgs,

    read(existing, { canRead }) {
      if (!existing) return
      const edges = existing.edges.filter(edge => canRead(edge.node))

      return {
        ...existing,
        edges
      }
    },

    merge(existing = makeEmptyData(), incoming, { args }) {
      const paginationArgs = paginationKey ? args?.[paginationKey] : args
      if (!paginationArgs) {
        return existing
      }

      const incomingEdges = incoming.edges.slice(0)
      if (incoming.pageInfo) {
        updateCursor(incomingEdges, 0, incoming.pageInfo.startCursor) //make sure first item's cursor is same as pages start cursor
        updateCursor(incomingEdges, -1, incoming.pageInfo.endCursor) //make sure last item's cursor is same as pages end cursor
      }

      let prefix = existing.edges
      let suffix: typeof prefix = []

      if (paginationArgs.after) {
        const index = prefix.findIndex(
          edge => edge.cursor === paginationArgs.after
        )
        if (index >= 0) {
          prefix = prefix.slice(0, index + 1)
          // suffix = []; // already true
        }
      } else if (paginationArgs.before) {
        const index = prefix.findIndex(
          edge => edge.cursor === paginationArgs.before
        )
        suffix = index < 0 ? prefix : prefix.slice(index)
        prefix = []
      } else {
        // If we have neither args.after nor args.before, the incoming
        // edges cannot be spliced into the existing edges, so they must
        // replace the existing edges. See #6592 for a motivating example.
        prefix = []
      }

      const edges = [...prefix, ...incomingEdges, ...suffix]
      const pageInfo = {
        ...incoming.pageInfo,
        ...existing.pageInfo,
        startCursor: cursorFromEdge(edges, 0),
        endCursor: cursorFromEdge(edges, -1)
      }

      const updatePageInfo = (
        name: keyof TInternalRelay<TNode>['pageInfo']
      ) => {
        const value = incoming.pageInfo?.[name]
        if (value !== undefined) {
          // @ts-ignore
          pageInfo[name] = value
        }
      }
      if (!prefix.length) updatePageInfo('hasPreviousPage')
      if (!suffix.length) updatePageInfo('hasNextPage')

      return {
        ...existing,
        ...incoming,
        edges,
        pageInfo
      }
    }
  }
}

function makeEmptyData() {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: ''
    }
  }
}

function cursorFromEdge<TNode>(
  edges: TInternalRelay<TNode>['edges'],
  index: number
): string {
  if (index < 0) index += edges.length
  const edge = edges[index]
  return (edge && edge.cursor) || ''
}

function updateCursor<TNode>(
  edges: TInternalRelay<TNode>['edges'],
  index: number,
  cursor: string | undefined
) {
  if (index < 0) index += edges.length //go to last edge
  const edge = edges[index]
  if (cursor && cursor !== edge.cursor) {
    edges[index] = { ...edge, cursor }
  }
}
