import Image from 'next/image'
import { Typography } from 'components/common'

export type Props = {
  image: string
  imgAlt: string
  description: string
  trackingString: string
  link: string
}

const bottomBoxClasses =
  'p-4 my-5 flex gap-4 bg-white rounded-[12px] shadow-light transition duration-100 ease-in-out hover:translate-y-[-3px] hover:shadow-dark'

const MarketetingContentSection: React.FC<Props> = ({
  image,
  trackingString,
  description,
  imgAlt,
  link
}) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        window.analytics.track(trackingString)
      }}
    >
      <div className={bottomBoxClasses}>
        <div className="flex-[2_1_0%]">
          <Image src={image} width={148} height={75} alt={imgAlt} />
        </div>
        <div className="flex-[3_1_0%]">
          <Typography>{description}</Typography>
        </div>
      </div>
    </a>
  )
}

export default MarketetingContentSection
