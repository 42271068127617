import {
  EphemeralCryptoAuthQueryResult,
  EphemeralCryptoAuthQueryVariables,
  useLoginCryptoMutation
} from 'graphql/generated'
import { useWeb3 } from 'context/Web3Context'
import useToasts from 'hooks/useToasts'
import { useLazyQuery } from 'lib/useLazyQuery'
import { EphemeralCryptoAuthQuery } from 'graphql/documents'
import useLogin from 'hooks/useLogin'
import { ConnectOptions } from '@web3-onboard/core'
import { useCallback, useState } from 'react'

function useEphemeralCryptoAuth() {
  const { openSelectWallet } = useWeb3()
  const loadEphemeralCryptoAuth = useLazyQuery<
    EphemeralCryptoAuthQueryResult,
    EphemeralCryptoAuthQueryVariables
  >(EphemeralCryptoAuthQuery)
  const [loginCrypto] = useLoginCryptoMutation()

  const doLogin = useLogin()
  const { addToast } = useToasts()

  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const verifyWalletAndLogin = useCallback(
    async (connectOptions?: ConnectOptions, redirect: string | false = '/') => {
      try {
        setIsLoggingIn(true)
        if (isLoggingIn) {
          return
        }
        const { signerAddress, signer } = await openSelectWallet(connectOptions)

        const res = await loadEphemeralCryptoAuth({})
        const { id, authMessage } = res.data.ephemeralCryptoAuth
        if (!signerAddress || !signer || !authMessage) {
          addToast('Missing wallet information', {
            appearance: 'warning'
          })
          return
        }

        const signature = await signer.signMessage(authMessage)

        const loginCryptoResp = await loginCrypto({
          variables: {
            login: {
              signature,
              address: signerAddress,
              ephemeralCryptoAuthId: id
            }
          }
        })
        if (loginCryptoResp.data?.loginCrypto?.success) {
          await doLogin(loginCryptoResp.data.loginCrypto.jwtToken, {
            tracking: { method: 'Wallet', type: 'login' },
            redirectExplicit: redirect
          })
          return
        }
        setIsLoggingIn(false)
        return { signature, ephemeralCryptoAuthId: id, address: signerAddress }
      } catch (err) {
        setIsLoggingIn(false)
        window.analytics.trackAll('Account - Log In - Error', {
          method: 'Wallet'
        })
        throw err
      }
    },
    [
      addToast,
      doLogin,
      isLoggingIn,
      loadEphemeralCryptoAuth,
      loginCrypto,
      openSelectWallet
    ]
  )

  return {
    verifyWalletAndLogin,
    isLoggingIn
  }
}

export default useEphemeralCryptoAuth
