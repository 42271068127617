import Cookies from 'js-cookie'
import { AppContext } from 'next/app'
import { NextPageContext } from 'next'
import type { NormalizedCacheObject } from '@apollo/client'
import { createClient } from 'graphql-ws'
import {
  ApolloClient,
  HttpLink,
  split,
  ApolloLink,
  Observable
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import {
  SynchronousCachePersistor,
  LocalStorageWrapper
} from 'apollo3-cache-persist'
import { initCache } from 'lib/apolloCache'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import Bugsnag from 'lib/bugsnag'
import { ROOT_HOSTNAME } from './helpers'
import { getWeb3ActorFromCache } from 'context/Web3Context'

const IS_PROD = process.env.NODE_ENV === 'production'

function sanitizer(key: string, value: any) {
  if (key.includes('password')) {
    return '[REDACTED]'
  }

  return value
}

export interface NextPageContextWithApollo extends NextPageContext {
  apolloClient: ApolloClient<NormalizedCacheObject> | null
  apolloState: NormalizedCacheObject
  ctx: NextPageContextApp
  token: Maybe<string>
}

export type NextPageContextApp = NextPageContextWithApollo & AppContext

export const APOLLO_STATE_PROP_NAME = 'apolloState'

const UPLOAD_LINK_OPERATIONS = [
  'UploadAvatarMutation',
  'UploadCollectiveAvatarMutation',
  'UploadCollectiveApplicationAvatarMutation',
  'UploadEventThumbMutation',
  'UploadCollectiveDraftAvatarMutation',
  'CreateThreadMutation',
  'UpdateThreadMutation'
]

const WS_LINK_OPERATIONS = ['EventHeartbeatQuery', 'CurrentEventQuery']

let apolloClient: ApolloClient<any>
let persistor: SynchronousCachePersistor<any>

function getToken(ssrToken?: Maybe<string>) {
  const isSSR = typeof window === 'undefined'
  return isSSR ? ssrToken : Cookies.get('token')
}

function createPublicUrlRewriteLink() {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      if (!IS_PROD) {
        response.data = JSON.parse(
          JSON.stringify(response.data)
            .replace(
              /"publicUrl":"https:\/\/upstreamapp.com/g,
              `"publicUrl":"${ROOT_HOSTNAME}`
            )
            .replace(
              /"publicUrl":"https:\/\/uat.upstreamapp.com/g,
              `"publicUrl":"${ROOT_HOSTNAME}`
            )
            .replace(
              /"publicUrl":"https:\/\/(.*?).upstreamapp.com/g,
              '"publicUrl":"http://$1.upstream.local:5002'
            )
        )
      }

      return response
    })
  })
}

function createHttpLink(ssrToken?: Maybe<string>) {
  const isSSR = typeof window === 'undefined'
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_HOST,
    headers: {
      'app-env': 'upstream-www',
      timezone: isSSR ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  })

  const uploadLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_HOST,
    headers: { 'Apollo-Require-Preflight': 'true' }
  })

  const splitUploadLink = split(
    ({ operationName }) => UPLOAD_LINK_OPERATIONS.includes(operationName),
    uploadLink,
    httpLink
  )

  const authLink = setContext((_, { headers }) => {
    const token = getToken(ssrToken)
    if (token) {
      if (!headers) {
        headers = {}
      }
      if (token) {
        headers.authorization = `Bearer ${token}`
      }

      const web3Actor = getWeb3ActorFromCache()
      headers['web3-actor'] = web3Actor
    }

    return {
      headers: {
        ...headers,
        'x-upstream-app': `${1}/${1}/${'web'}+${
          ssrToken ? 'ssr' : 'client'
        }/${'systemVersion'}/${'deviceId'}/${'deviceLocale'}`,
        'user-agent': `UpstreamWeb/${1} (${ssrToken ? 'SSR' : 'Client'})`,
        credentials: headers?.['credentials'] || 'same-origin'
      }
    }
  })

  return authLink.concat(splitUploadLink)
}

function selectiveLink(test: Callback<boolean>, link: ApolloLink) {
  return new ApolloLink(function (operation, forward) {
    if (test()) {
      return link.request(operation, forward)
    } else {
      return Observable.of()
    }
  })
}

function createWSLink(ssrToken?: Maybe<string>) {
  const isSSR = typeof window === 'undefined'
  if (isSSR) {
    return ApolloLink.empty()
  }

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.NEXT_PUBLIC_GRAPHQL_WS_HOST || '',
      // lazy: true,
      shouldRetry: () => true,
      connectionParams: { authToken: getToken(ssrToken) },
      onNonLazyError: err => {
        console.warn(`WS Client error: ${err}`)
      }
    })
  )

  return selectiveLink(() => !!getToken(ssrToken), wsLink)
}

function createApolloClient(ssrToken?: Maybe<string>): ApolloClient<any> {
  const isSSR = typeof window === 'undefined'
  const httpLink = IS_PROD
    ? createHttpLink(ssrToken)
    : createPublicUrlRewriteLink().concat(createHttpLink(ssrToken))

  const wsLink = createWSLink(ssrToken)

  const linkChain = wsLink
    ? split(
        ({ query, operationName }) => {
          const definition = getMainDefinition(query)
          return (
            (definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription') ||
            WS_LINK_OPERATIONS.includes(operationName)
          )
        },
        wsLink,
        httpLink
      )
    : httpLink

  const cache = initCache()
  if (!isSSR) {
    persistor = new SynchronousCachePersistor({
      cache,
      storage: new LocalStorageWrapper(window.sessionStorage)
    })
    persistor.restoreSync()
  }

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    Bugsnag.notify(
      new Error(`Apollo Error: ${operation.operationName}`),
      report => {
        report.context = 'Apollo'

        if (graphQLErrors) {
          report.errors[0].errorMessage = `${graphQLErrors[0].message}`
          report.errors[0].errorClass = 'GraphQL Error'
          report.groupingHash = 'Apollo GraphQL Error'
        } else if (networkError) {
          report.errors[0].errorMessage = `${networkError.toString()}`
          report.errors[0].errorClass = 'Network Error'
          report.groupingHash = 'Apollo Network Error'
        }

        try {
          report.addMetadata(
            'graphQl',
            'graphQLErrors',
            JSON.stringify(graphQLErrors)
          )
          report.addMetadata(
            'graphQl',
            'networkError',
            networkError
              ? `${networkError.toString()}:${
                  // @ts-ignore
                  networkError.statusCode || 0
                }`
              : ''
          )
          report.addMetadata(
            'graphQl',
            'operationName',
            operation.operationName
          )
          report.addMetadata(
            'graphQl',
            'variables',
            JSON.stringify(operation.variables || {}, sanitizer)
          )
        } catch (ex) {
          // ignored
        }

        return true
      }
    )

    if (graphQLErrors) {
      graphQLErrors.forEach(graphQLError => {
        console.log(graphQLError)

        if (
          // @ts-ignore
          graphQLError.statusCode === 401 ||
          graphQLError.extensions!.code === 'UNAUTHENTICATED'
        ) {
          const loginPath = '/auth/login'
          if (!isSSR && !window.location.pathname.includes(loginPath)) {
            window.localStorage.setItem('_r', window.location.pathname)
            window.location.replace(loginPath)
          }
        }
      })
    }

    if (networkError) {
      console.log([`[Network error]`, networkError])
      // @ts-ignore
      if (networkError.statusCode === 401) {
        // ignored
      }
    }
  })

  const link = ApolloLink.from([errorLink, linkChain])

  return new ApolloClient({
    ssrMode: isSSR,
    // nooooo idea why a high `ssrForceFetchDelay` is needed. w/o this, `network-only` queries don't
    // resolve quite right
    ssrForceFetchDelay: 0,
    link,
    cache,
    credentials: 'include',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
      }
    }
  })
}

export function usePersistor() {
  return persistor
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {NormalizedCacheObject} initialState
 */
export const initApolloClient = (
  _initialState: NormalizedCacheObject,
  ssrToken?: Maybe<string>
) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    const client = createApolloClient(ssrToken)
    // client.cache.restore(initialState)
    return client
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient()
    if (_initialState) {
      const existingCache = apolloClient.extract()
      apolloClient.cache.restore({ ...existingCache, ..._initialState })
      persistor.persist()
    }
  }

  return apolloClient
}
