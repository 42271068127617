import {
  ControlLevelTypes,
  IControlLevelTypes,
  IMemberTypes,
  Members
} from 'components/Collectives/Demo/common'
import {
  CollectiveMembersQueryResult,
  CollectiveProposalCommand,
  CollectiveProposalQueryResult,
  CollectiveProposalType,
  CollectiveQueryResult,
  CollectiveSignatorsQueryResult,
  CollectiveStatsQueryResult,
  CollectiveVotesQueryResult,
  ProposalCommandType,
  useCollectiveDemoQuery
} from 'graphql/generated'
import { POINT_ONE_ETHER_BN } from 'lib/collectives/helpers'
import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useCallback,
  useMemo
} from 'react'

interface CollectiveDemoContextProps {
  collectiveDemoId: string
}

export type ProposalType = NonNullable<
  CollectiveQueryResult['collective']
>['proposals']['edges'][0]['node']

export type ProposalTypeFull =
  CollectiveProposalQueryResult['collectiveProposal']

export type ProposalsDataType = {
  proposal: ProposalTypeFull
  votes: NonNullable<CollectiveVotesQueryResult['collectiveProposal']>['votes']
  signators: NonNullable<
    CollectiveSignatorsQueryResult['collective']
  >['signators'][]
}

export type ProposalInitData = {
  commands: CollectiveProposalCommand[]
  title: string
  description?: string
  useOrigin?: string
  durationHours?: number
}

type WalletLinkingState = 'init' | 'picking' | 'connecting'

export const ProposalCommandsAllowed = [ProposalCommandType.ADD_MEMBER]

interface CollectiveContextShape {
  collectiveDemo: CollectiveQueryResult['collective']
  collectiveProposals: ProposalsDataType[]
  setCollectiveProposals: CallbackWithParam<ProposalsDataType[]>
  collectiveMembers: NonNullable<
    CollectiveMembersQueryResult['collective']
  >['members']
  setCollectiveMembers: CallbackWithParam<
    NonNullable<CollectiveMembersQueryResult['collective']>['members']
  >
  getWalletStats: CallbackWithParam<
    string,
    Maybe<NonNullable<CollectiveStatsQueryResult['collective']>>
  >
  actionableCommands: CollectiveProposalCommand[]
  refetchDemo: Callback
  setControlLevel: CallbackWithParam<IControlLevelTypes>
  controlLevel: IControlLevelTypes | undefined
  demoWalletLinked: boolean
  setDemoWalletLinked: CallbackWithParam<boolean>
  demoWalletLinking: WalletLinkingState
  setDemoWalletLinking: CallbackWithParam<WalletLinkingState>
  totalStepsCount: number
  setTotalStepsCount: CallbackWithParam<number>
  goToStep: CallbackWithParam<number>
  resetSteps: Callback
  currentUser: IMemberTypes
  currentStep: number
  setCurrentStep: CallbackWithParam<number>
  updateDemoCache: CallbackWithParam<CollectiveQueryResult['collective']>
  updateDemoProposals: CallbackWithParam<ProposalsDataType[]>
  newProposals: Set<string>
  setNewProposals: CallbackWithParam<Set<string>>
  demoActiveProposal?: ProposalType
  setDemoActiveProposal: CallbackWithParam<ProposalType | undefined>
  viewingProposal?: ProposalTypeFull
  setViewingProposal: CallbackWithParam<ProposalTypeFull>
  viewingMembers: boolean
  setViewingMembers: CallbackWithParam<boolean>
  getProposalDataForId: CallbackWithParam<string, ProposalsDataType | undefined>
  setProposalDataForId: CallbackWithParams<
    string,
    ProposalsDataType | undefined
  >
  getProposalForId: CallbackWithParam<string, ProposalTypeFull>
  getVotesForProposalId: CallbackWithParam<
    string,
    Maybe<
      NonNullable<CollectiveVotesQueryResult['collectiveProposal']>['votes']
    >
  >
  validDemoAddress: Set<string>
  resetting: boolean
  setResetting: CallbackWithParam<boolean>
  possibleMembers: IMemberTypes[]
  setPossibleMembers: CallbackWithParam<IMemberTypes[]>
}

const CollectiveDemoContext = createContext<CollectiveContextShape>(
  {} as CollectiveContextShape
)

export const useCollectiveDemoContext = () => useContext(CollectiveDemoContext)

export const DemoTooltipText = 'Disabled for Demo Mode'

export function CollectiveDemoProvider({
  children,
  collectiveDemoId
}: PropsWithChildren<CollectiveDemoContextProps>) {
  const [controlLevel, setControlLevel] = useState<IControlLevelTypes>(
    ControlLevelTypes[0]
  )

  const [demoWalletLinked, setDemoWalletLinked] = useState<boolean>(false)

  const [demoWalletLinking, setDemoWalletLinking] =
    useState<WalletLinkingState>('init')

  const [viewingMembers, setViewingMembers] = useState<boolean>(false)

  const [currentStep, setCurrentStep] = useState<number>(0)

  const goToStep = useCallback((step: number) => setCurrentStep(step), [])

  const resetSteps = useCallback(() => setCurrentStep(0), [])

  const [resetting, setResetting] = useState<boolean>(false)

  const [totalStepsCount, setTotalStepsCount] = useState<number>(0)

  const [newProposals, setNewProposals] = useState<Set<string>>(new Set())

  const [demoActiveProposal, setDemoActiveProposal] = useState<ProposalType>()

  const [viewingProposal, setViewingProposal] = useState<ProposalTypeFull>()

  const [possibleMembers, setPossibleMembers] = useState<IMemberTypes[]>(
    Members.map(member => {
      const memberNew = { ...member }
      memberNew.membership = { ...member.membership }
      return memberNew
    })
  )

  const currentUser = useMemo(() => possibleMembers[0], [possibleMembers])

  const { refetch: refetchDemo } = useCollectiveDemoQuery({
    variables: { collectiveDemoId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    onCompleted: data => {
      const parsedData = data?.collectiveDemo?.collective
        ? JSON.parse(JSON.stringify(data?.collectiveDemo?.collective))
        : undefined

      const newCollectiveData =
        parsedData?.collective as CollectiveQueryResult['collective']

      if (!parsedData || !newCollectiveData) {
        return
      }

      newCollectiveData.membership = currentUser.membership

      newCollectiveData.parameters.enableDeposits = true
      newCollectiveData.parameters.enableNewMembershipRequests = true
      newCollectiveData.parameters.enableWithdraws = false
      newCollectiveData.parameters.gateDeposits = true
      newCollectiveData.parameters.proposalThreshold = '2'
      newCollectiveData.parameters.minEthContribution =
        POINT_ONE_ETHER_BN.toString()
      newCollectiveData.parameters.quorumNeededInPercentage = 30
      newCollectiveData.parameters.disableTokenTransfers = false

      newCollectiveData.proposals.edges.forEach(
        proposalEdge =>
          (proposalEdge.node.type = CollectiveProposalType.COMMANDS)
      )

      newCollectiveData.totalVotingMembers = 1

      setCollectiveDemo(newCollectiveData)

      const newProposalsData = parsedData.proposals as ProposalsDataType[]
      setCollectiveProposals(newProposalsData)

      const membersData = parsedData.members as NonNullable<
        CollectiveMembersQueryResult['collective']
      >['members']
      setCollectiveMembers(membersData)

      const newProposalsDataActionable =
        parsedData.actionableCommands as CollectiveProposalCommand[]

      setActionableCommands(newProposalsDataActionable)

      setWalletStats(parsedData.stats)
    }
  })

  const [collectiveDemo, setCollectiveDemo] =
    useState<CollectiveQueryResult['collective']>()

  const [collectiveProposals, setCollectiveProposals] = useState<
    ProposalsDataType[]
  >([])

  const [collectiveMembers, setCollectiveMembers] = useState<
    NonNullable<CollectiveMembersQueryResult['collective']>['members']
  >({
    __typename: 'CollectiveMemberConnection',
    edges: [],
    pageInfo: {
      __typename: 'PageInfo',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false
    }
  })

  const [actionableCommands, setActionableCommands] = useState<
    CollectiveProposalCommand[]
  >([])

  const [walletStats, setWalletStats] = useState<{
    '1D': Maybe<NonNullable<CollectiveStatsQueryResult['collective']>>
    '3D': Maybe<NonNullable<CollectiveStatsQueryResult['collective']>>
    '7D': Maybe<NonNullable<CollectiveStatsQueryResult['collective']>>
    '1M': Maybe<NonNullable<CollectiveStatsQueryResult['collective']>>
  }>()

  const getProposalDataForId = useCallback(
    id => {
      return collectiveProposals.find(cp => id === cp?.proposal?.id)
    },
    [collectiveProposals]
  )

  const setProposalDataForId = useCallback(
    (id, proposalData) => {
      const newProposalsData = [
        ...collectiveProposals.filter(proposal => id !== proposal.proposal?.id),
        { ...proposalData }
      ]

      setCollectiveProposals(newProposalsData)
    },
    [collectiveProposals]
  )

  const getProposalForId = useCallback(
    id => {
      return collectiveProposals.find(cp => id === cp?.proposal?.id)?.proposal
    },
    [collectiveProposals]
  )

  const getVotesForProposalId = useCallback(
    id => {
      return collectiveProposals.find(cp => id === cp?.proposal?.id)?.votes
    },
    [collectiveProposals]
  )

  const getWalletStats = useCallback(
    timeRange => {
      if (!walletStats) {
        return
      }
      return walletStats[timeRange]
    },
    [walletStats]
  )

  const updateDemoCache = useCallback(
    (newCollectiveDemo: CollectiveQueryResult['collective']) => {
      setCollectiveDemo(newCollectiveDemo)
    },
    []
  )

  const updateDemoProposals = useCallback(
    (newCollectiveProposalsData: ProposalsDataType[]) => {
      setCollectiveProposals(newCollectiveProposalsData)
    },
    []
  )

  const validDemoAddress = useMemo(
    () => new Set([...possibleMembers.map(member => member.address)]),
    [possibleMembers]
  )

  if (!collectiveDemo) {
    return null
  }

  return (
    <CollectiveDemoContext.Provider
      value={{
        collectiveDemo,
        collectiveProposals,
        setCollectiveProposals,
        collectiveMembers,
        setCollectiveMembers,
        getWalletStats,
        actionableCommands,
        refetchDemo,
        controlLevel,
        setControlLevel,
        demoWalletLinked,
        setDemoWalletLinked,
        demoWalletLinking,
        setDemoWalletLinking,
        totalStepsCount,
        setTotalStepsCount,
        resetSteps,
        currentUser,
        goToStep,
        currentStep,
        setCurrentStep,
        updateDemoCache,
        updateDemoProposals,
        newProposals,
        setNewProposals,
        demoActiveProposal,
        setDemoActiveProposal,
        viewingProposal,
        setViewingProposal,
        viewingMembers,
        setViewingMembers,
        getProposalDataForId,
        setProposalDataForId,
        getProposalForId,
        getVotesForProposalId,
        validDemoAddress,
        resetting,
        setResetting,
        possibleMembers,
        setPossibleMembers
      }}
    >
      {children}
    </CollectiveDemoContext.Provider>
  )
}
