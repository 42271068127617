import { useIsLoggedIn, useLogout } from 'hooks'
import { faLink, faSignOut } from '@fortawesome/pro-regular-svg-icons'
import { useCallback } from 'react'
import BaseModal from 'components/modals/BaseModal'
import Button from 'components/Button'
import useLinkWallet from 'hooks/collectives/useLinkWallet'
import Typography from 'components/common/Typography'
import StyledTooltip from 'components/Tooltip/Tooltip'
import EtherscanLink from 'components/Collectives/Common/EtherscanLink'
import useEphemeralCryptoAuth from 'hooks/collectives/useEphemeralCryptoAuth'
import TrackableClick from 'components/common/TrackableClick'
import { useWeb3 } from 'context/Web3Context'

function NewWalletModal() {
  const { verifyWalletAndLogin, isLoggingIn } = useEphemeralCryptoAuth()
  const { linkWallet } = useLinkWallet()
  const isLoggedIn = useIsLoggedIn()
  const { wallet, signerAddress, toggleNewWalletModal, showNewWalletModal } =
    useWeb3()

  const { logout, isLoggingOut } = useLogout()
  const logoutAndSwitch = useCallback(async () => {
    if (!wallet || !signerAddress) {
      return
    }

    await logout({ redirect: false })
    await verifyWalletAndLogin({
      autoSelect: {
        disableModals: true,
        label: wallet.label
      }
    })
  }, [logout, signerAddress, verifyWalletAndLogin, wallet])

  if (
    !signerAddress ||
    !isLoggedIn ||
    isLoggingOut ||
    isLoggingIn ||
    !showNewWalletModal
  ) {
    return null
  }

  return (
    <BaseModal onClose={() => toggleNewWalletModal(false)}>
      <div className="p-6 flex flex-col space-y-6">
        <Typography>
          The selected wallet{' '}
          <EtherscanLink address={signerAddress} shortAddress inline /> is not
          linked to your account.
        </Typography>

        <Typography fontWeight="light">
          Would you like to link the new wallet or login as another account?
        </Typography>

        <div className="w-full flex items-center justify-between">
          <StyledTooltip
            title="Once linked, this wallet cannot be used for another account and must be unlinked first."
            arrow
          >
            <TrackableClick event="Wallet Link Clicked">
              <Button
                label="Link Wallet"
                color="blue"
                onClick={() => {
                  linkWallet()
                  toggleNewWalletModal(false)
                }}
                icon={faLink}
                iconPlacement="after"
              />
            </TrackableClick>
          </StyledTooltip>

          <StyledTooltip
            title="This will login to another account that uses this wallet or create a new account if needed."
            arrow
          >
            <TrackableClick event="Wallet Switch Account Clicked">
              <Button
                label="Switch Account"
                color="darkgray"
                onClick={() => {
                  logoutAndSwitch()
                  toggleNewWalletModal(false)
                }}
                icon={faSignOut}
                iconPlacement="after"
              />
            </TrackableClick>
          </StyledTooltip>
        </div>
      </div>
    </BaseModal>
  )
}

export default NewWalletModal
