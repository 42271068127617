import React from 'react'
import ErrorPage from 'next/error'
import Bugsnag from '../lib/bugsnag'

function Error({ statusCode }) {
  return <ErrorPage statusCode={statusCode || '¯\\_(ツ)_/¯'} />
}

Error.getInitialProps = ctx => {
  if (ctx.err) Bugsnag.notify(ctx.err)
  return ErrorPage.getInitialProps(ctx)
}

export default Error
