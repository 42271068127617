import { useTimedTick } from 'lib/timer'
import { useMemo } from 'react'

function useSecondsLeft(until: Date) {
  const tick = useTimedTick()
  const secondsLeft = useMemo(
    () =>
      Date.now() <= until.getTime()
        ? Math.ceil((until.getTime() - Date.now()) / 1000)
        : 0,
    // eslint-disable-next-line
    [until, tick]
  )
  return secondsLeft
}

export default useSecondsLeft
