import Bugsnag from 'lib/bugsnag'
import Cookies from 'js-cookie'
import { useViewerQuery, Viewer, ViewerQueryResult } from 'graphql/generated'
import { noop } from 'lodash'
import useIsLoggedIn from './useIsLoggedIn'
import { useRef } from 'react'

interface IProps {
  onLoad?: CallbackWithParam<ViewerQueryResult['me']>
}

function useCurrentUser({ onLoad }: IProps = {}): Viewer {
  const isLoggedIn = useIsLoggedIn()
  const identified = useRef(false)

  const { data: { me = {} } = {} } = useViewerQuery({
    onError: noop,
    // SSR needs to know the current user for... many reasons. <nav/> being one of those.
    fetchPolicy: 'cache-first',
    skip: !isLoggedIn,
    onCompleted: resp => {
      if (resp.me.jwtToken) {
        Cookies.set('token', resp.me.jwtToken, {
          secure: process.env.NEXT_PUBLIC_SECURE_COOKIES === 'true',
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || '.upstreamapp.com'
        })
      }

      if (typeof window !== 'undefined' && !identified.current) {
        Bugsnag.setUser(resp.me.id, resp.me.email, resp.me.displayName || '')
        identified.current = true
      }

      onLoad?.(resp.me)
    }
  })

  return (me as Viewer) || {}
}

export default useCurrentUser
