import { useCollectivesQuery } from 'graphql/generated'
import useIsLoggedIn from 'hooks/useIsLoggedIn'
import { noop } from 'lodash'

const itemsPerPage = 500

function useCollectives() {
  const loggedIn = useIsLoggedIn()
  const { loading, data, networkStatus, refetch, fetchMore } =
    useCollectivesQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        collectiveConnection: {
          first: itemsPerPage
        }
      },
      skip: !loggedIn,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: noop
    })
  const loadingMore = networkStatus === 3
  const refetching = networkStatus === 2 || networkStatus === 4
  const edges = data?.me.collectives?.edges
  const pageInfo = data?.me.collectives?.pageInfo

  const loadMore = () => {
    if (!pageInfo || !pageInfo.hasNextPage) {
      return
    } else if (loading || loadingMore) {
      return
    }

    fetchMore({
      variables: {
        collectiveConnection: {
          first: itemsPerPage,
          after: pageInfo.endCursor
        }
      }
    })
  }

  return {
    loading,
    loadingMore,
    refetching,
    networkStatus,
    refetch,
    loadMore,
    edges,
    pageInfo
  }
}

export default useCollectives
