import EthNetworkWarningBanner from 'components/Collectives/Common/EthNetworkWarningBanner'
import { CollectiveTosModal } from 'components/Collectives/modals/CollectiveTosModal'
import {
  CollectiveQueryResult,
  useCollectiveChangedSubscription,
  useCollectiveQuery,
  useCollectiveTosQuery
} from 'graphql/generated'
import { useOnLinkedAccountChanged } from 'hooks/collectives/useOnLinkedAccountChanged'
import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react'
import { useCollectiveDemoContext } from './CollectiveDemoContext'
import { useAnalyticsContext } from 'context/AnalyticsContext'
import { useCurrentUser } from 'hooks'

interface CollectiveContextProps {
  collectiveId: string
  demoMode?: boolean
}

interface CollectiveContextShape {
  collectiveId: string
  collective: NonNullable<CollectiveQueryResult['collective']>
  showTos: CallbackWithOptionalParam<CallbackWithParam<boolean>>
  hideTos: CallbackWithParam<boolean>
  demoMode?: boolean
}

const CollectiveContext = createContext<CollectiveContextShape>(
  {} as CollectiveContextShape
)

export const useCollectiveContext = () => useContext(CollectiveContext)

export function CollectiveProvider({
  collectiveId,
  demoMode = false,
  children
}: PropsWithChildren<CollectiveContextProps>) {
  const { collectiveDemo } = useCollectiveDemoContext()

  const { data, refetch } = useCollectiveQuery({
    variables: {
      collectiveId,
      proposalConnection: {
        first: 5
      }
    },
    skip: !collectiveId || demoMode
  })

  const collective = useMemo(
    () => (!demoMode ? data?.collective : collectiveDemo),
    [collectiveDemo, data?.collective, demoMode]
  )

  const { addEventProperties, removeEventProperties, loaded } =
    useAnalyticsContext()

  useEffect(() => {
    addEventProperties({ collective })

    if (collective && typeof window !== 'undefined' && loaded) {
      window.analytics?.setCommonProperties({
        daoId: collective.id,
        daoName: collective.name,
        network: collective.network,
        role: collective.membership?.privileged ? 'admin' : 'member'
      })
    }

    return () => {
      typeof window !== 'undefined' && window.analytics?.setCommonProperties({})
    }
  }, [addEventProperties, collective, loaded])

  useEffect(() => {
    return () => {
      removeEventProperties(['collective'])
    }
  }, [removeEventProperties])

  useCollectiveChangedSubscription({
    variables: {
      collectiveId
    },
    skip: !collectiveId || demoMode,
    onData: () => !demoMode && refetch()
  })

  useOnLinkedAccountChanged(
    useCallback(() => {
      !demoMode && refetch?.()
    }, [demoMode, refetch])
  )

  // ToS Modal
  const { id: loggedInUserId } = useCurrentUser()
  const userDataHasLoaded = !!loggedInUserId
  const [shouldPromptTos, setShouldPromptTos] = useState(false)

  const [onTosClose, setOnTosClose] = useState<CallbackWithParam<boolean>>()

  const showTos = (onAgree?: CallbackWithParam<boolean>) => {
    setShouldPromptTos(true)
    setOnTosClose(() => onAgree)
  }
  const hideTos = (isAgreed: boolean) => {
    setShouldPromptTos(false)
    if (onTosClose) {
      onTosClose?.(isAgreed)
    }
    setOnTosClose(undefined)
  }

  const { data: collectiveTosData, loading } = useCollectiveTosQuery({
    variables: {
      collectiveId: collectiveId
    },
    skip: !collectiveId || demoMode
  })
  //show tos if approved and revision is not signed
  useEffect(() => {
    if (
      userDataHasLoaded &&
      collective?.membership?.approved &&
      !loading &&
      !collectiveTosData?.collectiveTos.isSigned &&
      !demoMode
    ) {
      showTos()
    }
  }, [
    collective?.membership?.approved,
    collectiveTosData,
    demoMode,
    loading,
    userDataHasLoaded
  ])

  if (!collective) {
    return null
  }

  return (
    <CollectiveContext.Provider
      value={{
        collective,
        collectiveId,
        showTos,
        hideTos,
        demoMode
      }}
    >
      {!demoMode && <EthNetworkWarningBanner />}

      {children}

      {shouldPromptTos && <CollectiveTosModal onClose={hideTos} />}
    </CollectiveContext.Provider>
  )
}
