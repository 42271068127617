import { useIsLoggedIn } from 'hooks'
import dynamic from 'next/dynamic'
import React from 'react'

const NoSsrComponent: React.FC<{
  children?: React.ReactNode
  // eslint-disable-next-line react/jsx-no-useless-fragment
}> = props => <>{props.children}</>

export const NoSsr = dynamic(() => Promise.resolve(NoSsrComponent), {
  ssr: false
})

const ConditionalSsr: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const isLoggedIn = useIsLoggedIn()
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isLoggedIn ? <NoSsr>{children}</NoSsr> : <>{children}</>
}

export default ConditionalSsr
