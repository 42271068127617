import tailwind from 'lib/tailwind' // Your tailwind config
import { useEffect, useState } from 'react'
import useWindowDimensions from './useWindowDimensions'

const breakpoints = tailwind.theme!.screens!
type TPossibleBreakpoint = 'sm' | 'md' | 'lg' | 'xl'

const breakpointsPossible: TPossibleBreakpoint[] = ['sm', 'md', 'lg', 'xl']

type Key = `is${Capitalize<TPossibleBreakpoint>}`

const initObject: Record<Key, boolean> = {
  isLg: false,
  isMd: false,
  isSm: false,
  isXl: false
}

export function useBreakpoint() {
  const screen = useWindowDimensions()

  const [isBreakpoint, setIsBreakpoint] =
    useState<Record<Key, boolean>>(initObject)

  useEffect(() => {
    const newData: Record<Key, boolean> = { ...initObject }
    breakpointsPossible.forEach(bk => {
      const bool = window.matchMedia(`(min-width: ${breakpoints[bk]})`).matches

      const capitalizedKey = bk[0].toUpperCase() + bk.substring(1)
      newData[`is${capitalizedKey}`] = bool
    })

    setIsBreakpoint(newData)
  }, [screen.width])

  return { ...isBreakpoint, isMobile: !isBreakpoint.isSm }
}
