import { BigNumber } from 'bignumber.js'
import { BigNumber as EthersBigNumber, utils } from 'ethers'

export { BigNumber }

export type PseudoBigNumber = string

export function initBigNumberJSON() {
  try {
    if (typeof BigNumber !== 'undefined' && BigNumber.prototype) {
      // @ts-ignore
      BigNumber.prototype.toJSON = function () {
        return this.toString()
      }
    }
  } catch (ex) {
    // ignore
  }

  try {
    BigNumber.config({ EXPONENTIAL_AT: 1e9 })

    BigNumber.prototype.toEthersBigNumber = function () {
      return EthersBigNumber.from(this.toString())
    }

    BigNumber.prototype.formatEther = function (
      decimals = 2,
      inputDecimals = 18
    ): string {
      return this.shiftedBy(-inputDecimals)
        .toFormat(decimals)
        .replace(/\.0+$/, '')
    }

    BigNumber.prototype.formatEtherNumeric = function (
      decimals = 2,
      inputDecimals = 18
    ): number {
      return this.shiftedBy(-inputDecimals).decimalPlaces(decimals).toNumber()
    }
  } catch (ex) {
    // ignore
  }
}

// try to init helper methods asap
initBigNumberJSON()

export function parseEther(value: string, decimals = 18) {
  try {
    return new BigNumber(
      utils.parseUnits((value || '0').replace(/,/g, ''), decimals).toString()
    )
  } catch (ex) {
    return new BigNumber(0)
  }
}

export function formatNumber(value: PseudoBigNumber | string | number): string {
  try {
    return new BigNumber(value).toFormat()
  } catch (ex) {
    return `0`
  }
}
