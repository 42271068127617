import { useSetChain } from '@web3-onboard/react'
import { useCollectiveContext } from 'context/CollectiveContext'
import { getChainIdForNetwork } from 'lib/collectives/helpers'

export function useIsEthNetworkDifferent() {
  const { collective } = useCollectiveContext()
  const [{ connectedChain }] = useSetChain()

  if (
    !collective ||
    !connectedChain ||
    connectedChain.id === getChainIdForNetwork(collective.network)
  ) {
    return false
  }

  return true
}

export default useIsEthNetworkDifferent
