import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import Bugsnag from 'lib/bugsnag'
import useClearApolloCache from './useClearApolloCache'
import fetch from 'isomorphic-unfetch'
import Cookies from 'js-cookie'
import { clearWalletCache, useWeb3 } from 'context/Web3Context'

interface IProps {
  redirect?: string | false
  persistCacheKeys?: string[]
}

function useLogout() {
  const router = useRouter()
  const clearApolloCache = useClearApolloCache()
  const { disconnectWallet } = useWeb3()
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const logout = useCallback(
    async ({ redirect = '/auth/login', persistCacheKeys }: IProps = {}) => {
      setIsLoggingOut(true)
      if (isLoggingOut) {
        return
      }
      await disconnectWallet()
      await clearApolloCache()

      await fetch('/api/logout', {
        credentials: 'include'
      })

      const persistKeys = persistCacheKeys?.map(key => ({
        key,
        value: localStorage.getItem(key)
      }))
      localStorage.clear()
      persistKeys?.forEach(
        ({ key, value }) => value && localStorage.setItem(key, value)
      )

      Cookies.remove('token', {
        secure: process.env.NEXT_PUBLIC_SECURE_COOKIES === 'true',
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || '.upstreamapp.com'
      })

      clearWalletCache()

      Bugsnag.setUser(undefined, undefined, undefined)
      typeof window !== 'undefined' && window.analytics.reset()

      if (redirect) {
        router.push(redirect)
      }
      setIsLoggingOut(false)
    },
    [clearApolloCache, disconnectWallet, isLoggingOut, router]
  )

  return { isLoggingOut, logout }
}

export default useLogout
