import {
  CollectiveProposalQueryResult,
  UserType,
  VoteType
} from 'graphql/generated'
import moment from 'moment'

export type IControlLevelTypes = { label: string; value: string }

export type IMemberTypes = {
  displayName: string
  role: string
  membership: NonNullable<
    CollectiveProposalQueryResult['collectiveProposal']
  >['proposedBy']
  address: string
  votes: KVMap<string, VoteType>
}

export const Members: IMemberTypes[] = [
  {
    displayName: 'Guest User (You)',
    role: 'guest',
    membership: {
      id: 'guest1',
      __typename: 'CollectiveMember',
      totalTokens: '0',
      percentOfTotalTokenSupply: 0,
      privileged: false,
      approved: false,
      createdAt: new Date(),
      user: {
        id: 'guest1',
        __typename: 'CollectiveUser',
        address: '0xDEMO000000000000000000000000000000000you',
        platformUser: {
          __typename: 'User',
          id: 'guest1',
          blocked: false,
          displayName: 'Guest User (You)',
          shortDisplayName: 'You',
          publicUrl: 'guest1',
          isBot: false,
          userType: UserType.NORMAL
        }
      }
    } as NonNullable<
      CollectiveProposalQueryResult['collectiveProposal']
    >['proposedBy'],
    address: '0xDEMO000000000000000000000000000000000you',
    votes: {}
  },
  {
    displayName: 'Guest User 2',
    role: 'guest',
    membership: {
      id: 'guest2',
      __typename: 'CollectiveMember',
      totalTokens: '0',
      percentOfTotalTokenSupply: 0,
      privileged: false,
      approved: false,
      createdAt: new Date(),
      user: {
        id: 'guest2',
        __typename: 'CollectiveUser',
        address: '0xDEMO000000000000000000000000000000guest2',
        platformUser: {
          __typename: 'User',
          id: 'guest2',
          blocked: false,
          displayName: 'Guest User 2',
          shortDisplayName: 'Guest',
          publicUrl: 'guest2',
          isBot: false,
          userType: UserType.NORMAL
        }
      }
    } as NonNullable<
      CollectiveProposalQueryResult['collectiveProposal']
    >['proposedBy'],
    address: '0xDEMO000000000000000000000000000000guest2',
    votes: {}
  },
  {
    displayName: 'Friend of Guest 1',
    role: 'guest',
    membership: {
      id: 'guest3',
      __typename: 'CollectiveMember',
      totalTokens: '0',
      percentOfTotalTokenSupply: 0,
      privileged: false,
      approved: false,
      createdAt: new Date(),
      user: {
        id: 'guest3',
        __typename: 'CollectiveUser',
        address: '0xDEMO000000000000000000000000000000guest3',
        platformUser: {
          __typename: 'User',
          id: 'guest3',
          blocked: false,
          displayName: 'Guest User 3',
          shortDisplayName: 'Guest',
          publicUrl: 'guest3',
          isBot: false,
          userType: UserType.NORMAL
        }
      }
    } as NonNullable<
      CollectiveProposalQueryResult['collectiveProposal']
    >['proposedBy'],
    address: '0xDEMO000000000000000000000000000000guest3',
    votes: {}
  },
  {
    displayName: 'Existing Collective Member',
    role: 'member',
    membership: {
      id: 'member1',
      __typename: 'CollectiveMember',
      totalTokens: '0',
      percentOfTotalTokenSupply: 0,
      privileged: false,
      approved: true,
      createdAt: moment().subtract(1, 'days').toDate(),
      user: {
        id: 'member1',
        __typename: 'CollectiveUser',
        address: '0xDEMO00000000000000000000000000000member1',
        platformUser: {
          __typename: 'User',
          id: 'member1',
          blocked: false,
          displayName: 'Existing Collective Member 1',
          shortDisplayName: 'Existing',
          publicUrl: 'member1',
          isBot: false,
          userType: UserType.NORMAL
        }
      }
    } as NonNullable<
      CollectiveProposalQueryResult['collectiveProposal']
    >['proposedBy'],
    address: '0xDEMO00000000000000000000000000000member1',
    votes: {}
  },
  {
    displayName: 'Existing Collective Signator',
    role: 'signator',
    membership: {
      id: 'signator1',
      __typename: 'CollectiveMember',
      totalTokens: '0',
      percentOfTotalTokenSupply: 0,
      privileged: true,
      approved: true,
      createdAt: moment().subtract(2, 'days').toDate(),
      user: {
        id: 'signator1',
        __typename: 'CollectiveUser',
        address: '0xDEMO000000000000000000000000000signator1',
        platformUser: {
          __typename: 'User',
          id: 'signator1',
          blocked: false,
          displayName: 'Existing Signator 1',
          shortDisplayName: 'Existing',
          publicUrl: 'signator1',
          isBot: false,
          userType: UserType.NORMAL
        }
      }
    } as NonNullable<
      CollectiveProposalQueryResult['collectiveProposal']
    >['proposedBy'],
    address: '0xDEMO000000000000000000000000000signator1',
    votes: {}
  }
]

export const ControlLevelTypes: IControlLevelTypes[] = [
  { value: 'tutorial', label: 'Tutorial' },
  { value: 'unauth', label: 'Unauthorized/Guest View' },
  { value: 'member', label: 'Member View' },
  { value: 'signator', label: 'Signator View' }
]
