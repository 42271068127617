import moment from 'moment'
import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Event } from 'graphql/generated'

type TimedCallback = (stop: Callback) => void
type CallbackMap = {
  [key: string]: { callback: TimedCallback; kill: Callback }
}

const callbacksById: CallbackMap = {}
const timerAction = function () {
  Object.values(callbacksById).forEach(({ callback, kill }) => {
    callback(kill)
  })
}
setInterval(timerAction, 1000)

export default function useTimerTask(callback: TimedCallback) {
  useEffect(() => {
    const id = Math.random().toString(36).substr(2, 10)
    const kill = () => {
      delete callbacksById[id]
    }
    callbacksById[id] = {
      callback,
      kill
    }

    return () => {
      kill()
    }
  }, [callback])
}

export function useTimedTick() {
  const [tick, setTick] = useState(0)

  useTimerTask(
    useCallback(() => {
      setTick(t => t + 1)
    }, [])
  )

  return tick
}

export function useStopwatch(event: Pick<Event, 'actualStartTime'>) {
  const since = useMemo(
    () => moment(event.actualStartTime),
    [event.actualStartTime]
  )
  const [timeElapsed, setTimeElapsed] = useState(calculateTimeElapsed(since))
  const updateTimeElapsed = useCallback(
    () => setTimeElapsed(calculateTimeElapsed(since)),
    [since]
  )
  useTimerTask(updateTimeElapsed)

  return timeElapsed
}

function calculateTimeElapsed(since: moment.Moment) {
  return moment(moment().diff(since)).format('mm:ss')
}

export function useActionAtTime(fireAt: Maybe<Date>, callback: Callback) {
  const fired = useRef(false)
  const msUntilFire = useMemo(
    () =>
      fireAt
        ? Date.now() < fireAt.getTime()
          ? fireAt.getTime() - Date.now()
          : 0
        : null,
    [fireAt]
  )

  useEffect(() => {
    fired.current = false
  }, [msUntilFire])

  useEffect(() => {
    let timer: any = null
    if (!fired.current && msUntilFire !== null) {
      timer = setTimeout(() => {
        callback()
        fired.current = true
      }, msUntilFire)
    }

    return () => {
      timer && clearTimeout(timer)
    }
  }, [msUntilFire, callback])
}
