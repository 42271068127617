import React from 'react'
import Typography, { ITypograhyProps } from './Typography'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface AdditionalFieldLabelProps {
  additionalLabelProps?: ITypograhyProps
  children?: React.ReactNode
}

interface ILabelProps extends AdditionalFieldLabelProps {
  label: React.ReactNode
  icon?: IconProp
  className?: string
  required?: boolean
}

function FieldLabel({
  label,
  icon,
  className,
  required,
  additionalLabelProps,
  children
}: ILabelProps) {
  return (
    <div className="flex items-center space-x-1 mb-1">
      <Typography
        fontWeight="light"
        size="sm"
        component={'span'}
        className={className}
        {...additionalLabelProps}
      >
        {icon && (
          <>
            <FontAwesomeIcon icon={icon} />{' '}
          </>
        )}
        {label}
      </Typography>

      {required && (
        <Typography color="red" size="xs">
          {`*`}
        </Typography>
      )}

      {children}
    </div>
  )
}

export default FieldLabel
