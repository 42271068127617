import { useSiteFlagsQuery } from 'graphql/generated'

type Value = string | number | boolean | null | undefined

function useFlag(key: string): Value
function useFlag(key: string, defaultValue: string): string
function useFlag(key: string, defaultValue: number): number
function useFlag(key: string, defaultValue: boolean): boolean
function useFlag(key: string, defaultValue?: Value): Value {
  const { data } = useSiteFlagsQuery()
  if (!data?.flags.length) {
    return defaultValue
  }

  const flag = data!.flags.find(x => x!.key === key)
  if (flag) {
    if (flag.__typename === 'SiteFlagString') {
      return flag.stringValue
    }
    if (flag.__typename === 'SiteFlagNumber') {
      return flag.numberValue
    }
    if (flag.__typename === 'SiteFlagBoolean') {
      return flag.booleanValue
    }
  }

  return defaultValue
}

export default useFlag
