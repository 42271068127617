import { useEventEmitterContext } from 'context/EventEmitterContext'
import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useEffect,
  useMemo
} from 'react'
import { ethers } from 'ethers'
import { init, useConnectWallet } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import { useCurrentUser } from 'hooks'
import { ConnectOptions, WalletState } from '@web3-onboard/core'
import walletConnectModule from '@web3-onboard/walletconnect'
import useIsLoggedIn from 'hooks/useIsLoggedIn'
import NewWalletModal from 'components/Nav/UserWallet/NewWalletModal'
import Cookies from 'js-cookie'

interface Web3ContextShape {
  web3: ethers.providers.Web3Provider | undefined
  openSelectWallet: CallbackWithOptionalParam<
    ConnectOptions,
    Promise<{
      signer: Maybe<ethers.providers.JsonRpcSigner>
      signerAddress: Maybe<string>
    }>
  >
  disconnectWallet: Callback
  signer: ethers.providers.JsonRpcSigner | undefined
  signerAddress: Maybe<string>
  wallet: Maybe<WalletState>
  isWalletLinkedToUser: boolean
  connecting: boolean
  showNewWalletModal: boolean
  toggleNewWalletModal: CallbackWithParam<boolean>
}

const injected = injectedModule()
const walletConnect = walletConnectModule()

const onboard = init({
  wallets: [injected, walletConnect],
  accountCenter: {
    desktop: {
      enabled: false
    },
    mobile: {
      enabled: false
    }
  },
  chains: [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl: 'https://mainnet.infura.io/v3/df9e3230e74f409e82283f5aaf712c9e'
    },
    {
      id: '0x5',
      token: 'gETH',
      label: 'Ethereum Goerli Testnet',
      rpcUrl: 'https://goerli.infura.io/v3/df9e3230e74f409e82283f5aaf712c9e'
    }
  ],
  appMetadata: {
    name: 'Upstream',
    icon: '<svg><svg/>',
    description: 'Upstream via Onboard',
    recommendedInjectedWallets: [
      { name: 'MetaMask', url: 'https://metamask.io' },
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' }
    ]
  },
  i18n: {
    en: {
      connect: {
        // @ts-ignore
        selectingWallet: {
          header: 'Available Wallets',
          sidebar: {
            heading: 'Connect your wallet',
            subheading: ' ',
            paragraph:
              'Log into your crypto wallet. If you don’t currently have a wallet, you’ll need to create one to join a DAO.'
          }
        }
      }
    }
  }
})

export const handleError = (error: Error): Error => {
  throw error
}

const isSSR = typeof window === 'undefined'
const isDomReady = !isSSR && window?.ethereum != null
const Web3Context = createContext<Web3ContextShape>({} as Web3ContextShape)

function loadWalletFromCache() {
  if (typeof window === 'undefined') {
    return null
  }
  return Cookies.get('selectedWallet')
}

function updateWalletCache(walletLabel: string) {
  Cookies.set('selectedWallet', walletLabel, {
    secure: process.env.NEXT_PUBLIC_SECURE_COOKIES === 'true',
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || '.upstreamapp.com'
  })
}

export function clearWalletCache() {
  Cookies.remove('selectedWallet', {
    secure: process.env.NEXT_PUBLIC_SECURE_COOKIES === 'true',
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || '.upstreamapp.com'
  })
}

export function getWeb3ActorFromCache() {
  if (typeof window === 'undefined') {
    return null
  }
  return Cookies.get('web3Actor')
}
function updateWeb3ActorCache(walletLabel: string) {
  Cookies.set('web3Actor', walletLabel, {
    secure: process.env.NEXT_PUBLIC_SECURE_COOKIES === 'true',
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || '.upstreamapp.com'
  })
}
function clearWeb3ActorCache() {
  Cookies.remove('web3Actor', {
    secure: process.env.NEXT_PUBLIC_SECURE_COOKIES === 'true',
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || '.upstreamapp.com'
  })
}

export const useWeb3 = () => useContext(Web3Context)

export function Web3Provider({ children }: PropsWithChildren<{}>) {
  const { emitter } = useEventEmitterContext()

  const [web3Provider, setWeb3Provider] = useState<
    ethers.providers.Web3Provider | undefined
  >()
  const [signerAddress, setSignerAddress] = useState<Maybe<string>>()
  const [selectedWallet, setSelectedWallet] = useState<Maybe<WalletState>>()
  const [showNewWalletModal, toggleNewWalletModal] = useState(false)

  const isLoggedIn = useIsLoggedIn()
  const user = useCurrentUser()
  const isWalletLinkedToUser = useMemo(
    () =>
      user?.collectiveUser?.some(
        linkedUser =>
          linkedUser.address.toLowerCase() === signerAddress?.toLowerCase()
      ),
    [user, signerAddress]
  )
  const hasLoadedLinkedAccounts = !!user?.collectiveUser

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
  const walletAccount = wallet?.accounts[0]?.address
  useEffect(() => {
    setSignerAddress(walletAccount)
  }, [walletAccount])

  const signer = useMemo(() => web3Provider?.getSigner(), [web3Provider])

  // Keep track of the user's selected active wallet in cache,
  // so we can auto reload and reuse it on subsequent page loads
  useEffect(() => {
    if (!wallet) {
      return
    }

    if (wallet.provider != null) {
      const web3Provider = new ethers.providers.Web3Provider(wallet.provider)

      setWeb3Provider(web3Provider)
      setSelectedWallet(wallet)
    }
    if (wallet.label != null) {
      updateWalletCache(wallet.label)
    }
  }, [wallet])

  // auto reload and reuse the user's previously selected active wallet
  useEffect(() => {
    if (!isDomReady || selectedWallet || !connect) {
      return
    }

    const connectPreviousWallet = async (): Promise<void> => {
      try {
        const walletProvider = new ethers.providers.Web3Provider(
          window.ethereum
        )
        const previouslySelectedWallet = loadWalletFromCache()
        const accounts = await walletProvider.listAccounts()

        if (!accounts) {
          window.analytics.trackAll(
            'Cached Wallet Found but no accounts found in wallet'
          )
          return
        }

        if (previouslySelectedWallet != null && isLoggedIn) {
          await connect({
            autoSelect: {
              label: previouslySelectedWallet,
              disableModals: true
            }
          })
        }
      } catch (error) {
        console.log({ error })
        handleError(error)
      }
    }

    connectPreviousWallet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWallet, connect, isLoggedIn])

  const openSelectWallet = async (
    connectOptions?: ConnectOptions | undefined
  ) => {
    try {
      await connect(connectOptions)
      // you're probably wondering: why eval `onboard.state.get().wallets[0]`
      // and not just use the `wallet` var from useConnectWallet() hook...
      // well, as it turns out, there's am unknown delay between the time
      // a wallet is selected, and when this async function finds out about it...
      // a race-condition, if you will
      const selectedWallet = onboard.state.get().wallets[0]
      if (!selectedWallet) {
        return { signer: undefined, signerAddress: undefined }
      }

      return {
        signerAddress: selectedWallet.accounts[0]?.address,
        signer: new ethers.providers.Web3Provider(
          selectedWallet.provider
        ).getSigner()
      }
    } catch (error) {
      console.log({ error })
      handleError(error as Error)
      return { signer: undefined, signerAddress: undefined }
    }
  }

  const disconnectWallet = async (): Promise<void> => {
    try {
      if (!wallet) {
        return
      }
      await disconnect({ label: wallet?.label })

      clearWalletCache()
      clearWeb3ActorCache()

      setSelectedWallet(undefined)
      emitter.emit('LINKED_ACCOUNT_CHANGED')
    } catch (error) {
      handleError(error as Error)
    }
  }

  useEffect(() => {
    if (!isLoggedIn || !signerAddress || !hasLoadedLinkedAccounts) {
      return
    }

    toggleNewWalletModal(!isWalletLinkedToUser)
    updateWeb3ActorCache(signerAddress)
    emitter.emit('LINKED_ACCOUNT_CHANGED')
    // eslint-disable-next-line
  }, [isLoggedIn, signerAddress, hasLoadedLinkedAccounts, isWalletLinkedToUser])

  return (
    <Web3Context.Provider
      value={{
        web3: web3Provider,
        openSelectWallet,
        disconnectWallet,
        signer,
        signerAddress,
        wallet: selectedWallet,
        isWalletLinkedToUser,
        connecting,
        showNewWalletModal,
        toggleNewWalletModal
      }}
    >
      {children}

      <NewWalletModal />
    </Web3Context.Provider>
  )
}
