import { useEffect, useState } from 'react'
import useResolveEns from 'hooks/collectives/useResolveEns'

interface IProps {
  address: string
  fallback?: string
}

export function shortAddress(address: string) {
  return `${address.substring(0, 5)}...${address.slice(-4)}`
}

function EnsResolvedAddress({ address, fallback }: IProps) {
  const resolveEns = useResolveEns()
  const [ensName, setEnsName] = useState<Maybe<string>>()

  useEffect(() => {
    resolveEns(address).then(setEnsName)
  }, [resolveEns, address])

  return <span>{ensName ? ensName : fallback ?? shortAddress(address)}</span>
}

export default EnsResolvedAddress
