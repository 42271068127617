import { DataProxy } from '@apollo/client/cache'
import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from 'lib/pagination'
import introspectionQueryResultData from 'graphql/fragmentTypes.json'
import {
  Event,
  ViewerQueryResult,
  Collective,
  CollectiveProposalStatus,
  CollectiveProposal,
  EventState,
  CollectiveMember,
  UserType
} from 'graphql/generated'
import { ViewerQuery } from 'graphql/documents'
import { TypedTypePolicies } from 'graphql/apollo-helpers'
import { isUserBot } from './utils'
import { findPreference } from 'hooks/usePreference'

export function initCache() {
  const typePolicies: TypedTypePolicies = {
    Query: {
      fields: {
        event(_, { args, toReference }) {
          return toReference({
            __typename: 'Event',
            id: args?.id
          })
        },
        user(_, { args, toReference }) {
          return toReference({
            __typename: 'User',
            id: args?.id
          })
        },

        discoverEngagements: relayStylePagination(
          ['sort', 'filters'],
          'engagementConnection'
        ),

        // local fields
        hasGmail() {
          return false
        },
        hasChrome() {
          return false
        },
        isConnected() {
          return false
        }
      }
    },
    Mutation: {
      fields: {}
    },
    Collective: {
      fields: {
        proposals: relayStylePagination(['searchString'], 'proposalConnection'),
        members: relayStylePagination(false, 'memberConnection'),
        logs: relayStylePagination(['where'], 'requestConnection'),
        engagements: relayStylePagination(
          ['sort', 'filters'],
          'engagementConnection'
        ),
        parameters: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming)
          }
        },
        assets: relayStylePagination(['where'], 'assetsConnection')
      }
    },
    CollectiveProposal: {
      fields: {
        survey: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming)
          }
        },
        votes: relayStylePagination(false, 'voteConnection'),
        isAuthor(_, { readField, cache }) {
          const viewerResp = safeReadQuery<ViewerQueryResult>(cache, {
            query: ViewerQuery
          })
          if (!viewerResp) {
            return false
          }

          const proposedBy =
            readField<CollectiveProposal['proposedBy']>('proposedBy')
          if (!proposedBy) {
            return false
          }

          const proposedByUser = readField<
            CollectiveProposal['proposedBy']['user']
          >('user', proposedBy)
          if (!proposedByUser) {
            return false
          }

          const proposedByPlatformUser = readField<
            CollectiveProposal['proposedBy']['user']['platformUser']
          >('platformUser', proposedByUser)
          if (!proposedByPlatformUser) {
            return false
          }

          const proposedByPlatformUserId = readField<
            NonNullable<
              CollectiveProposal['proposedBy']['user']['platformUser']
            >['id']
          >('id', proposedByPlatformUser)

          return proposedByPlatformUserId === viewerResp?.me.id
        },
        isUsingMatchingWallet(_, { readField }) {
          const collective =
            readField<CollectiveProposal['collective']>('collective')
          if (!collective) {
            return false
          }

          const membership = readField<Collective['membership']>(
            'membership',
            collective
          )
          if (!membership) {
            return false
          }

          const approved = readField<
            NonNullable<Collective['membership']>['approved']
          >('approved', membership)

          return approved
        },
        isVotable(_, { readField }) {
          const isProposalAuthor =
            readField<CollectiveProposal['isAuthor']>('isAuthor')
          const status = readField<CollectiveProposal['status']>('status')
          const isBatch = readField<CollectiveProposal['isBatch']>('isBatch')
          return (
            (status === CollectiveProposalStatus.ACTIVE ||
              (status === CollectiveProposalStatus.PENDING &&
                isProposalAuthor)) &&
            !isBatch
          )
        },
        isShortForm(_, { readField }) {
          const title = readField<CollectiveProposal['title']>('title')
          const hasDescription =
            !!readField<CollectiveProposal['description']>('description')
          const survey = readField<CollectiveProposal['survey']>('survey')
          const hasOnlyOneQuestion = survey
            ? readField<number>('totalQuestions', survey) === 1
            : false

          return (
            !!title &&
            title === survey?.questions[0].question &&
            !hasDescription &&
            hasOnlyOneQuestion
          )
        },
        canVote(_, { readField }) {
          const isVotable =
            readField<CollectiveProposal['isVotable']>('isVotable')
          const isUsingMatchingWallet = readField<
            CollectiveProposal['isUsingMatchingWallet']
          >('isUsingMatchingWallet')

          return isVotable && isUsingMatchingWallet
        }
      }
    },
    CollectiveMember: {
      fields: {
        proposalsProposed: relayStylePagination(false, 'proposalConnection')
      }
    },
    CollectiveUser: {
      fields: {
        proposalsProposed: relayStylePagination(false, 'proposalConnection')
      }
    },
    Event: {
      fields: {
        isPrivileged(_, { readField, cache }) {
          // current user is the event's host
          const collective = readField<Event['collective']>('collective')

          const membership: Maybe<CollectiveMember> = collective
            ? readField<Collective['membership']>('membership', collective)
            : null
          const approvedMember: Maybe<boolean> = membership
            ? readField<CollectiveMember['approved']>('approved', membership)
            : null
          const privilegedMember: Maybe<boolean> = membership
            ? readField<CollectiveMember['privileged']>(
                'privileged',
                membership
              )
            : null

          const isEventOwner = readField<Event['isOwner']>('isOwner')

          if (
            collective
              ? (isEventOwner && approvedMember) ||
                (!isEventOwner && privilegedMember)
              : isEventOwner
          ) {
            return true
          }

          // current user is an upstream superuser
          const viewerResp = safeReadQuery<ViewerQueryResult>(cache, {
            query: ViewerQuery
          })
          if (viewerResp) {
            const viewer = readField<ViewerQueryResult['me']>('me', viewerResp)
            const preferences = readField<
              ViewerQueryResult['me']['preferences']
            >('preferences', viewer)
            if (preferences) {
              const isSuperAdmin = findPreference(
                // @ts-ignore
                preferences,
                'isSuperAdmin',
                false
              )
              if (isSuperAdmin) {
                return true
              }
            }
          }

          return false
        },
        isOwner(_, { readField, cache }) {
          const viewerResp = safeReadQuery<ViewerQueryResult>(cache, {
            query: ViewerQuery
          })
          const eventUser = readField<Event['user']>('user')
          const eventUserId = readField('id', eventUser)

          return !!(viewerResp && eventUserId === viewerResp.me.id)
        },

        isCurrentlySpeaking() {
          return false
        },
        canEdit(_, { readField }) {
          const state = readField<Event['state']>('state')
          const isPrivileged = readField<Event['isPrivileged']>('isPrivileged')

          return state === EventState.SCHEDULED && isPrivileged
        },
        registrations: relayStylePagination(false, 'registrationConnection'),
        messages: relayStylePagination(false, 'messageConnection'),
        onlineGuestIds: {
          merge(_, incoming) {
            return [...incoming]
          }
        },
        media: {
          merge(_, incoming) {
            return incoming
          }
        }
      }
    },
    Viewer: {
      fields: {
        collectives: relayStylePagination(false, 'collectiveConnection'),
        isBot(_, { readField }) {
          const userType = readField<UserType>('userType')

          return userType && isUserBot(userType)
        }
      }
    },
    Thread: {
      fields: {
        comments: relayStylePagination(false, 'commentConnection'),
        collaborators: relayStylePagination(false, 'collaboratorConnection')
      }
    },
    Comment: {
      fields: {
        comments: relayStylePagination(false, 'commentConnection')
      }
    },
    User: {
      fields: {
        isBot(_, { readField }) {
          const userType = readField<UserType>('userType')

          return userType && isUserBot(userType)
        },
        engagements: relayStylePagination(
          ['sort', 'filters', 'dateFilter'], // ['sort', 'filters'], @TODO: revert back with apollo 3.3
          'engagementConnection'
        )
      }
    },
    UserPreference: {
      keyFields: ['key']
    }
  }

  return new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    typePolicies
  })
}

export function safeReadQuery<QueryType, TVariables = any>(
  client: DataProxy,
  options: DataProxy.Query<TVariables, QueryType>,
  defaultValue: Maybe<QueryType> = null
): QueryType | null {
  try {
    return client.readQuery(options)
  } catch (_err) {
    return defaultValue
  }
}

export function safeReadFragment<FragmentType, TVariables = any>(
  client: DataProxy,
  options: DataProxy.Fragment<TVariables, FragmentType>
): FragmentType | null {
  try {
    return client.readFragment(options)
  } catch (_err) {
    return null
  }
}
