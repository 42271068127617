import BaseModal from 'components/modals/BaseModal'
import { useEffect, useState } from 'react'
import {
  TosAgreementType,
  useAgreeToCollectiveTosMutation,
  useCollectiveTosQuery
} from 'graphql/generated'
import Spinner from 'components/Spinner'
import { useInView } from 'react-intersection-observer'
import Button from 'components/Button'
import { Typography } from 'components/common'
import { useCollectiveContext } from 'context/CollectiveContext'
import StyledTooltip from 'components/Tooltip/Tooltip'

interface IProps {
  onClose?: CallbackWithParam<boolean>
}

export function CollectiveTosModal({ onClose }: IProps) {
  const { collective } = useCollectiveContext()
  const { ref, inView } = useInView()
  const [reachedBottom, setReachedBottom] = useState(false)
  useEffect(() => {
    if (inView) {
      setReachedBottom(true)
    }
  }, [inView])

  const [saveAgreement, { loading: saving }] = useAgreeToCollectiveTosMutation({
    onCompleted: () => {
      onClose?.(true)
    }
  })

  const { data, loading } = useCollectiveTosQuery({
    variables: {
      collectiveId: collective.id
    },
    fetchPolicy: 'network-only'
  })

  const tos = data?.collectiveTos
  const canConfirm = reachedBottom && !saving
  const showAgreeButton = !!tos && !loading && !data?.collectiveTos.isSigned
  const alreadySigned = !!tos && !loading && data?.collectiveTos.isSigned

  return (
    <BaseModal onClose={() => onClose?.(false)} classNames="sm:max-w-2xl">
      <div className="px-6 py-4 flex flex-col flex-1 overflow-hidden gap-2 w-full">
        <Typography size="heading">{`${
          tos?.collectiveId ? collective.name.replace('DAO', '') : 'Upstream'
        } DAO \u00b7 Terms of Service`}</Typography>

        {loading && (
          <div>
            <Spinner size={'2x'} />
            <Typography className="text-center pt-10" size="lg">
              Loading latest Terms of Service...
            </Typography>
          </div>
        )}

        {!loading && !!tos && (
          <div className="flex-1 overflow-y-auto p-4 bg-gray-50 border border-gray-100 rounded-md">
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: tos.tosHtml }}
            />

            <div ref={ref} style={{ height: 1 }}>{`\u2004`}</div>
          </div>
        )}

        {showAgreeButton && (
          <div className="bg-gray-50 p-4 rounded-md flex">
            <Typography size="sm">
              {`By Clicking Agree, you are also agreeing to Upstream's`}&nbsp;
            </Typography>
            <Typography href="/privacy" newTab color="blue" size="sm">
              Privacy Policy
            </Typography>
          </div>
        )}

        {alreadySigned && (
          <div className="bg-gray-50 p-4 rounded-md flex">
            <Typography size="sm">{`And also Upstream's`}&nbsp;</Typography>
            <Typography href="/privacy" newTab color="blue" size="sm">
              Privacy Policy
            </Typography>
          </div>
        )}

        <div className="mt-4 flex flex-wrap items-center justify-center sm:justify-between gap-2">
          <div>
            {showAgreeButton && (
              <Typography size="sm" color="darkGray">
                Scroll to bottom of terms to agree
              </Typography>
            )}
          </div>

          <div className="flex items-center justify-end gap-8">
            <Button
              color="outline"
              onClick={() => onClose?.(alreadySigned)}
              label={showAgreeButton ? `Cancel` : `Continue`}
            />

            {showAgreeButton && (
              <StyledTooltip
                title={!reachedBottom && 'Scroll to bottom of terms to agree'}
              >
                <span>
                  <Button
                    color="blue"
                    onClick={() =>
                      saveAgreement({
                        variables: {
                          type: TosAgreementType.DETAILED,
                          id: tos.id
                        }
                      })
                    }
                    label={`Agree`}
                    loading={saving}
                    disabled={!canConfirm}
                  />
                </span>
              </StyledTooltip>
            )}
          </div>
        </div>
      </div>
    </BaseModal>
  )
}
