import { ApolloCache } from '@apollo/client'
import { SetPreferenceMutationResult } from 'graphql/generated'

export const updateViewerPreferences = (
  cache: ApolloCache<object>,
  viewerId: string,
  results: SetPreferenceMutationResult
) => {
  cache.modify({
    id: cache.identify({ __typename: 'Viewer', id: viewerId }),
    fields: {
      preferences(values, { readField, toReference }) {
        const newPref = results?.setPreference
        if (!newPref) {
          return values
        }
        const existingPref = values.find(
          value => readField('key', value) === newPref.key
        )
        if (!existingPref) {
          return [...values, toReference(newPref)]
        }
        return values
      }
    }
  })
}
