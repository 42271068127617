import { BigNumber, parseEther } from 'lib/BigInt'
import {
  CollectiveProposalQueryResult,
  CollectiveQueryResult,
  EthNetwork
} from 'graphql/generated'
import Safe, { EthersAdapter } from '@safe-global/protocol-kit'
import { ethers, Signer, utils } from 'ethers'
import {
  MetaTransactionData,
  SafeTransaction
} from '@safe-global/safe-core-sdk-types'
import { isPresent } from 'lib/helpers'

export const EMPTY_BYTES = '0x00'

export const TX_STATUSES = {
  succeeded: 1,
  failed: 0
}

export enum TTxUiState {
  WAITING_FOR_PROVIDER,
  WAITING_FOR_NETWORK,
  WAITING_FOR_DELEGATES,
  WAITING_FOR_APPROVAL,
  TX_SUCCESS,
  TX_FAILURE
}

type Type = 'address' | 'block' | 'token' | 'tx' | 'nft'

export const NO_SUPPORT_CHAIN = 'NO_SUPPORT'

export interface ILaunchCollectiveBlockchain {
  id: string
  label: string
  value: EthNetwork
  upstreamWalletAddress: string
  proxyAdminAddress: string
  ownedLogicAddress: string
  externalLogicAddress: string
  voteLogicAddress: string
}

export const blockchainOptions: ILaunchCollectiveBlockchain[] = [
  {
    id: '0x1',
    label: 'Ethereum Mainnet',
    value: EthNetwork.MAINNET,
    upstreamWalletAddress: '0x10E15cF20AF79F06AD2E15d4CA10cb68Cee102c4',
    proxyAdminAddress: '0xa5836689Cb1D1fBfA027259BF19a58e01472b409',
    ownedLogicAddress: '0xffc84649842EF827E2cBe4d0D5E94Ac6B81D54DC',
    externalLogicAddress: '0x358ce262aC5e085A294C32C7788c1402a968354b',
    voteLogicAddress: '0xfC73Dd76004f9fdaAE5a2800478cA162A8B7eAA6'
  },
  {
    id: '0x5',
    label: 'Goerli Test Network',
    value: EthNetwork.GOERLI,
    upstreamWalletAddress: '0x10E15cF20AF79F06AD2E15d4CA10cb68Cee102c4',
    proxyAdminAddress: '0x3a373e292341792fC1F175a98ABCe5039d69990B',
    ownedLogicAddress: '0x909D01E012310cC9D3399af0f1E55BA385Fec30F',
    externalLogicAddress: '0x9E2750d98C87181415Db9A7f0b4246E19223162B',
    voteLogicAddress: '0xCDcE213eefE7Ab36e554579a5845B38a12ee0B87'
  }
]

export function parseWeb3ErrorMessage(err: any) {
  return (
    err.reason?.trim() ||
    err.error?.message?.trim() ||
    err.message?.trim() ||
    'Something went wrong...'
  )
}

export function getEtherscanUrlForCollective(
  collective: Pick<
    NonNullable<CollectiveQueryResult['collective']>,
    'network' | 'governanceType' | 'tokens' | 'address'
  >,
  type: Type,
  filterByHolder?: Maybe<string>
) {
  return getEtherscanUrl(
    collective.network,
    type,
    collective.governanceType === 'OWNED'
      ? collective.address
      : collective.tokens[0].address,
    filterByHolder
  )
}

export function getEtherscanUrlForToken(
  network: EthNetwork,
  type: Type,
  address: string,
  tokenId: string
) {
  return `https://${
    network === EthNetwork.GOERLI ? 'goerli.' : ''
  }etherscan.io/${type}/${address}/${tokenId}`
}

export function getEtherscanUrl(
  network: EthNetwork,
  type: Type,
  address: string,
  filterByHolder?: Maybe<string>
) {
  return `https://${
    network === EthNetwork.GOERLI ? 'goerli.' : ''
  }etherscan.io/${type}/${address}${
    filterByHolder ? `?a=${filterByHolder}` : ``
  }`
}

// conform to https://docs.blocknative.com/onboard#networkid-required
export function getChainIdForNetwork(network: EthNetwork) {
  switch (network) {
    case EthNetwork.MAINNET:
      return '0x1'
    case EthNetwork.GOERLI:
      return '0x5'
    default:
      return '0x1337'
  }
}

export async function getSafe(safeAddress: string, owner1: Signer) {
  const ethAdapter = new EthersAdapter({
    ethers,
    signerOrProvider: owner1
  })
  const safe = await Safe.create({
    ethAdapter,
    safeAddress
  })

  return safe
}

interface IFormatTokenOptions {
  roundToDecimals?: Maybe<number>
  decimals?: Maybe<number>
}
export function formatToken(
  amount: BigNumber | string | number,
  options?: IFormatTokenOptions
): string {
  return new BigNumber(amount)
    .shiftedBy(-(options?.decimals ?? 18))
    .toFormat(options?.roundToDecimals || undefined)
    .replace(/\.0+$/, '')
}

export const ZERO_BN = new BigNumber(0)
export const ONE_BN = new BigNumber(1)
export const ONE_ETHER_BN = new BigNumber(utils.parseEther('1').toString())
export const POINT_ONE_ETHER_BN = new BigNumber(
  utils.parseEther('0.1').toString()
)
export const TEN_ETHER_BN = new BigNumber(utils.parseEther('10').toString())

export function getWeiBN(wei: string): BigNumber {
  return new BigNumber(wei)
}

export function ceilWeiBN(wei: BigNumber, ethToAdd: number): BigNumber {
  return parseEther((Math.ceil(wei.formatEtherNumeric()) + ethToAdd).toString())
}

// created to handle super commands - a command that encompasses many actions in one
export function getSafeInstructionsFromCommands(
  commands: NonNullable<
    CollectiveProposalQueryResult['collectiveProposal']
  >['commands']
) {
  const txPartials = commands.reduce((acc, currentCommand) => {
    if (
      currentCommand.__typename ===
      'CollectiveProposalSendEtherDisbursementCommands'
    ) {
      const consolidatedCommands = currentCommand.commands.map(
        x => x.safeInstructions as MetaTransactionData
      )

      acc.push(...consolidatedCommands)
    } else {
      acc.push(currentCommand.safeInstructions as MetaTransactionData)
    }

    return acc
  }, [] as MetaTransactionData[])

  return txPartials.filter(isPresent)
}

export async function gasEstimation(
  network: EthNetwork,
  safeAddress: string,
  tx: SafeTransaction,
  defaultValue = new BigNumber(250000)
) {
  try {
    const resp = await fetch(
      `https://safe-relay.${network.toLowerCase()}.gnosis.io/api/v2/safes/${safeAddress}/transactions/estimate/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          to: tx.data.to,
          value: tx.data.value,
          data: tx.data.data,
          operation: tx.data.operation
        })
      }
    )
    const gasEstimationBody = await resp.json()
    const baseGas: string = gasEstimationBody['baseGas']
    const safeTxGas: string = gasEstimationBody['safeTxGas']

    if (!baseGas || !safeTxGas) {
      return defaultValue
    }

    return new BigNumber(baseGas).plus(new BigNumber(safeTxGas))
  } catch (err) {
    console.log(err)
    return defaultValue
  }
}
