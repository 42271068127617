import React, { createContext, useContext, PropsWithChildren } from 'react'

interface AuthenticationContextShape {
  token: Maybe<string>
  isSSR: boolean
  isLoggedIn: boolean
}

export const AuthenticationContext = createContext<AuthenticationContextShape>(
  {} as AuthenticationContextShape
)

export const useAuthenticationContext = () => useContext(AuthenticationContext)

export function AuthenticationProvider({
  token,
  children
}: PropsWithChildren<Pick<AuthenticationContextShape, 'token'>>) {
  const isSSR = typeof window === 'undefined'
  const isLoggedIn = !!token

  return (
    <AuthenticationContext.Provider
      value={{
        token,
        isSSR,
        isLoggedIn
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}
