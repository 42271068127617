import { useModalContext } from 'context/ModalContext'
import { useCallback, useState } from 'react'
import BaseModal from './BaseModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShield, faShieldCheck } from '@fortawesome/pro-regular-svg-icons'
import Button from 'components/Button'
import { useCurrentUser } from 'hooks'
import {
  useVerifyEmailMutation,
  useResendVerificationEmailMutation,
  ViewerQueryResult
} from 'graphql/generated'
import Spinner from 'components/Spinner'
import { useApolloClient } from '@apollo/client'
import { ViewerQuery } from 'graphql/documents'

export function useEmailVerificationModal() {
  const { setModalContent } = useModalContext()
  return useCallback(() => {
    setModalContent(<EmailVerificationModal />)
  }, [setModalContent])
}

export function useEmailVerificationHandler() {
  const showEmailVerificationModal = useEmailVerificationModal()
  const client = useApolloClient()

  // We can't rely on useCurrentUser() because there's a delay
  // between useLogin() and the success callback fire... This causes
  // the login's successCallback to not realize there was a login
  // event... to counteract this race condition, we'll just read
  // ViewerQuery directly from cache
  // const { emailVerified } = useCurrentUser()

  return useCallback(
    async (callback: Callback<any>) => {
      const viewerResp = await client.query<ViewerQueryResult>({
        query: ViewerQuery,
        fetchPolicy: 'network-only'
      })
      const { emailVerified } = viewerResp.data.me
      if (!emailVerified) {
        showEmailVerificationModal()
        return
      }

      return callback()
    },
    [client, showEmailVerificationModal]
  )
}

export function EmailVerificationModal() {
  const { hideModal } = useModalContext()
  const { emailVerified } = useCurrentUser()

  const [error, setError] = useState('')
  const [, { loading: verifyingEmail }] = useVerifyEmailMutation({
    onError: err => setError(err.graphQLErrors.map(x => x.message).join('\n'))
  })

  const [resentEmail, setResentEmail] = useState(false)
  const [resendVerificationEmail, { loading: resendingVerificationEmail }] =
    useResendVerificationEmailMutation({
      onCompleted: () => setResentEmail(true),
      onError: err => setError(err.graphQLErrors.map(x => x.message).join('\n'))
    })

  return (
    <BaseModal>
      <div className="p-6">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <FontAwesomeIcon
              icon={emailVerified ? faShieldCheck : faShield}
              className="text-green-600"
            />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              {emailVerified ? `Email Verified` : `Verify Your Email Address`}
            </h3>

            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {emailVerified
                  ? `Thanks for verifying your email address! You may now freely post, reply, direct message, and RSVP to events.`
                  : `Before you can post, reply, direct message, and RSVP, please verify your email address by clicking the link in the email we've sent you.`}
              </p>

              {verifyingEmail && <Spinner className="mt-4" />}

              {resendingVerificationEmail && (
                <>
                  <Spinner className="mt-4 mb-1" />
                  <p className="text-sm text-gray-700">{`Resending your verification email...`}</p>
                </>
              )}

              {!emailVerified &&
                !verifyingEmail &&
                !resendingVerificationEmail &&
                !resentEmail && (
                  <Button
                    onClick={() => resendVerificationEmail()}
                    label={'Resend verification email'}
                    color={'lightblue'}
                    rounded={'full'}
                    className="mt-4 mb-2"
                  />
                )}
              {resentEmail && (
                <p className="mt-4 text-sm text-gray-700">{`Verification email has been resent!`}</p>
              )}

              {error ? (
                <p className="mt-4 text-sm font-bold text-red-600">{`Error: ${error}`}</p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="mt-5 grid">
          <Button color={'white'} onClick={hideModal} label={'Close'} />
        </div>
      </div>
    </BaseModal>
  )
}
