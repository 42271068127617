import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import useCollectives from 'hooks/collectives/useCollectives'
import { useWeb3 } from 'context/Web3Context'
import { useCurrentUser, useIsLoggedIn } from 'hooks'
import { Collective, Event, Viewer, ViewerQueryResult } from 'graphql/generated'
import useAnalytics from 'hooks/useAnalytics'
import { useRouter } from 'next/router'
import { useSubdomain } from 'lib/subdomainUtils'

type TIdentifyUser = Pick<
  ViewerQueryResult['me'],
  | 'id'
  | 'collectiveUser'
  | 'createdAt'
  | 'emailVerified'
  | 'email'
  | 'username'
  | 'displayName'
>

interface ContextShape {
  loaded: boolean
  addEventProperties: CallbackWithParam<{
    collective?: Maybe<
      Pick<Collective, 'id' | 'name' | 'network'> & {
        membership?: Maybe<{
          approved: boolean
          privileged: boolean
          user: { address: string }
        }>
      }
    >
    event?: Maybe<Pick<Event, 'id' | 'title'>>
    connectedWallet?: Maybe<string>
    user?: Maybe<Pick<Viewer, 'id' | 'displayName'>>
  }>
  removeEventProperties: CallbackWithParam<
    Array<'collective' | 'event' | 'connectedWallet' | 'user'>
  >
  identify: CallbackWithParam<TIdentifyUser>
}

const AnalyticsContext = createContext<ContextShape>({} as ContextShape)

export const useAnalyticsContext = () => useContext(AnalyticsContext)

interface ChatProviderProps {
  children?: React.ReactNode
}

export function AnalyticsProvider({ children }: ChatProviderProps) {
  const { isReady } = useAnalytics()

  const { edges: collectiveEdges } = useCollectives()
  const [identified, setIdentified] = useState(false)
  const isLoggedIn = useIsLoggedIn()
  const viewer = useCurrentUser()
  const router = useRouter()

  // route change track
  useEffect(() => {
    function handleRouteChange(url: string) {
      if (isReady) {
        window.analytics.pageView(url)
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
    // eslint-disable-next-line
  }, [isReady])

  const identify = useCallback(
    (user: TIdentifyUser) => {
      if (!isReady) {
        return
      }
      window.analytics.identify(
        user.id,
        {
          wallets: user.collectiveUser?.map(cUser => cUser.address).join(', '),
          createdAt: user.createdAt,
          emailVerified: user.emailVerified,
          username: user.username,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        ['UA']
      )
      window.analytics.identify(
        user.id,
        {
          email: user.email,
          wallets: user.collectiveUser?.map(cUser => cUser.address).join(', '),
          createdAt: user.createdAt,
          emailVerified: user.emailVerified,
          username: user.username,
          displayName: user.displayName,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        ['HEAP']
      )

      setIdentified(true)
    },
    [isReady]
  )

  // user identification
  useEffect(() => {
    if (!viewer.id || !isLoggedIn) {
      setIdentified(false)
      // identity reset is handled in logout
      return
    }
    if (viewer.id && isLoggedIn) {
      identify(viewer)
    }
  }, [viewer, identify, isLoggedIn])

  // additional identified user properties
  useEffect(() => {
    if (!collectiveEdges || !identified) {
      return
    }
    window.analytics.heap.addUserProperties({
      collectiveMembership: collectiveEdges
        .filter(
          collective =>
            collective.node.membership?.approved &&
            !collective.node.membership?.privileged
        )
        .map(collective => `${collective.node.name} (${collective.node.id})`)
        .join(', '),
      collectiveSignatorship: collectiveEdges
        .filter(
          collective =>
            collective.node.membership?.approved &&
            !!collective.node.membership?.privileged
        )
        .map(collective => `${collective.node.name} (${collective.node.id})`)
        .join(', ')
    })
  }, [collectiveEdges, identified])

  // Event Properties

  const addEventProperties: ContextShape['addEventProperties'] = useCallback(
    ({ collective, event, connectedWallet, user }) => {
      if (!isReady) {
        return
      }

      let eventProps: any = {}

      if (connectedWallet) {
        eventProps = {
          ...eventProps,
          connectedWallet: connectedWallet
        }
      }

      if (collective) {
        eventProps = {
          ...eventProps,
          collective: collective.name,
          collectiveId: collective.id,
          collectiveNetwork: collective.network,
          collectiveRole: collective.membership?.approved
            ? collective?.membership.privileged
              ? 'Signator'
              : 'Member'
            : undefined,
          walletUsedForCollective: collective.membership?.user.address,
          membershipApprroved: !!collective.membership?.approved
        }
      }

      if (event) {
        eventProps = {
          ...eventProps,
          event: event.title,
          eventId: event.id
        }
      }

      if (user) {
        eventProps = {
          ...eventProps,
          userId: user.id,
          user: user.displayName
        }
      }
      window.analytics.heap.addEventProperties(eventProps)
    },
    [isReady]
  )

  const removeEventProperties: ContextShape['removeEventProperties'] =
    useCallback(
      type => {
        if (!isReady) {
          return
        }

        if (type.includes('collective')) {
          window.analytics.heap.removeEventProperty('collective')
          window.analytics.heap.removeEventProperty('collectiveId')
          window.analytics.heap.removeEventProperty('collectiveRole')
          window.analytics.heap.removeEventProperty('collectiveNetwork')
          window.analytics.heap.removeEventProperty('walletUsedForCollective')
          window.analytics.heap.removeEventProperty('membershipApprroved')
        }
        if (type.includes('event')) {
          window.analytics.heap.removeEventProperty('event')
          window.analytics.heap.removeEventProperty('eventId')
        }
        if (type.includes('connectedWallet')) {
          window.analytics.heap.removeEventProperty('connectedWallet')
        }
        if (type.includes('user')) {
          window.analytics.heap.removeEventProperty('userId')
          window.analytics.heap.removeEventProperty('user')
        }
      },
      [isReady]
    )

  // Auto handle event properties (collective and event add are from contexts)
  useEffect(() => {
    if (identified && viewer.id && isLoggedIn) {
      addEventProperties({ user: viewer })
      window.analytics.heap.addEventProperties({ isLoggedIn })
    } else {
      removeEventProperties(['user'])
      window.analytics.heap.addEventProperties({ isLoggedIn })
    }
  }, [
    addEventProperties,
    identified,
    isLoggedIn,
    removeEventProperties,
    viewer
  ])

  const { signerAddress } = useWeb3()
  useEffect(() => {
    if (signerAddress) {
      addEventProperties({ connectedWallet: signerAddress })
    } else {
      removeEventProperties(['connectedWallet'])
    }
  }, [addEventProperties, removeEventProperties, signerAddress])

  // handled in contexts
  const isEvent = router.pathname.includes('/events/[eventId]')
  useEffect(() => {
    if (!isEvent) {
      removeEventProperties(['event'])
    }
  }, [removeEventProperties, isEvent])

  const subdomain = useSubdomain()
  const isCollective =
    subdomain || router.pathname.includes('/collectives/[collectiveId]')
  useEffect(() => {
    if (!isCollective) {
      removeEventProperties(['collective'])
    }
  }, [removeEventProperties, isCollective])

  return (
    <AnalyticsContext.Provider
      value={{
        loaded: isReady,
        addEventProperties,
        removeEventProperties,
        identify
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
