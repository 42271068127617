import {
  LoginMutationResult,
  useLoginMutation,
  ViewerFieldsFragment
} from 'graphql/generated'
import { useControlledInput, useLogin } from 'hooks'
import { useCallback, useState } from 'react'
import { parseGqlErrors } from 'hooks/useQueryErrorHandler'

const deviceInfo = {
  appVersion: '1.0',
  systemVersion: 'web',
  timezone:
    typeof window !== 'undefined'
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : undefined
}

export default function (
  onRedirect: CallbackWithParam<ViewerFieldsFragment, string>,
  successCallback: Callback | undefined,
  defaultEmail?: string,
  defaultPassword?: string
) {
  const [email, setEmail] = useControlledInput(defaultEmail)
  const [password, setPassword] = useControlledInput(defaultPassword)

  const [loggingIn, setLoggingIn] = useState(false)
  const onLogin = useLogin()

  const onAfterLogin = useCallback(
    async (resp: LoginMutationResult) => {
      setLoggingIn(false)
      if (!resp.login?.success) {
        return
      }

      const token = resp.login.jwtToken
      await onLogin(token, {
        tracking: { method: 'Email+Password', type: 'login' },
        redirectExplicit: onRedirect,
        successCallback
      })
    },
    [onLogin, onRedirect, successCallback]
  )

  const [login, { loading: loadingPassword, error: errorPassword }] =
    useLoginMutation({
      variables: {
        login: {
          email,
          password,
          deviceInfo
        }
      },
      onCompleted: resp => {
        onAfterLogin(resp)
      },
      onError: () => {
        setLoggingIn(false)
        window.analytics?.trackAll(`Account - Log In - Error`, {
          type: 'Email+Password'
        })
      }
    })

  const errors = parseGqlErrors(errorPassword)
  const errorMessage =
    errors['login.email'] || errors['login.password'] || errors._generic

  return {
    email,
    setEmail,
    password,
    setPassword,
    errorMessage,
    login: async () => {
      setLoggingIn(true)
      await login()
    },
    loadingPassword: loadingPassword || loggingIn
  }
}
