import { useEventEmitterContext } from 'context/EventEmitterContext'
import { usePopper } from 'react-popper'
import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTriangle } from '@fortawesome/pro-solid-svg-icons'
import { Placement } from '@popperjs/core'
import { twMerge } from 'tailwind-merge'
import PopperPortal from 'components/PopperPortal'
import Fade, { FadeProps } from './Fade'

export const CLOSE_POPOVER = 'close_popover'

interface IProps {
  from: (
    ref: (instance: HTMLElement | null) => void,
    onClick: Callback,
    visible: boolean,
    show: Callback,
    hide: Callback
  ) => React.ReactElement
  showDismissButton?: boolean
  placement?: Placement
  children:
    | React.ReactNode
    | CallbackWithParam<
        {
          toggle: Callback
          visible: boolean
          setState: CallbackWithParam<boolean>
        },
        React.ReactNode
      >
  useArrow?: boolean
  overflowOptions?: object
  className?: string
  arrowClassName?: string
  usePortal?: boolean
  closeOnEvent?: string
  useFade?: boolean
  fadeProps?: Omit<FadeProps, 'in'>
}

const Popover = React.forwardRef<HTMLElement, IProps>(
  (
    {
      from,
      showDismissButton,
      placement = 'bottom-end',
      className,
      useArrow = false,
      arrowClassName,
      overflowOptions = { name: 'preventOverflow' },
      usePortal = false,
      closeOnEvent,
      useFade,
      fadeProps,
      children
    },
    ref
  ) => {
    const [arrowElement, setArrowElement] = useState()
    const triggerElement = useRef<HTMLElement | null>(null)
    const popperElement = useRef(null)
    const [visible, setVisible] = useState(false)

    const { styles, attributes } = usePopper(
      triggerElement.current,
      popperElement.current,
      {
        modifiers: [
          { name: 'arrow', options: { element: arrowElement } },
          overflowOptions,
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          }
        ],
        placement
      }
    )

    useEffect(() => {
      function onDocumentClick(event) {
        // @ts-ignore
        if (popperElement.current?.contains(event.target)) {
          return
        }
        // @ts-ignore
        else if (triggerElement.current?.contains(event.target)) {
          return
        }

        setVisible(false)
      }

      function onKeyDown(event) {
        if (event.key === 'Escape') {
          setVisible(false)
        }
      }

      document.addEventListener('mousedown', onDocumentClick)
      document.addEventListener('keydown', onKeyDown)

      return () => {
        document.removeEventListener('mousedown', onDocumentClick)
        document.removeEventListener('keydown', onKeyDown)
      }
    }, [])

    const { emitter } = useEventEmitterContext()
    useEffect(() => {
      // emitter doesn't exist in SSR
      if (typeof window === 'undefined' || !emitter) {
        return
      }

      function hidePopover() {
        setVisible(false)
      }

      emitter.on(CLOSE_POPOVER, hidePopover)
      if (closeOnEvent) {
        emitter.on(closeOnEvent, hidePopover)
      }

      return () => {
        emitter.off(CLOSE_POPOVER, hidePopover)
        if (closeOnEvent) {
          emitter.off(closeOnEvent, hidePopover)
        }
      }
    }, [closeOnEvent, emitter])

    const popper = (
      <div
        ref={popperElement}
        style={{ ...styles.popper, zIndex: 9999 }}
        {...attributes.popper}
      >
        <Fade in={true} duration={useFade ? 'DEFAULT' : 0} {...fadeProps}>
          <div
            className={twMerge(
              'relative bg-white border border-gray-200 shadow-lg rounded-lg z-40 overflow-visible',
              className
            )}
          >
            {showDismissButton && (
              <div
                className="absolute top-3 right-[15px] z-9999 flex flex-row justify-center items-center cursor-pointer"
                onClick={() => setVisible(false)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="relative text-xl text-gray-500"
                />
              </div>
            )}

            {typeof children === 'function'
              ? children({
                  toggle: () => setVisible(!visible),
                  visible,
                  setState: (showHide: boolean) => setVisible(showHide)
                })
              : children}

            {useArrow ? (
              <div
                // @ts-ignore
                ref={setArrowElement}
                // style={styles.arrow}
                className={twMerge('arrow absolute text-white', arrowClassName)}
              >
                <FontAwesomeIcon icon={faTriangle} size="xs" />
              </div>
            ) : (
              <div
                // @ts-ignore
                ref={setArrowElement}
                // style={styles.arrow}
                className="absolute w-0.5 h-0.5"
              />
            )}
          </div>
        </Fade>
      </div>
    )

    const updateRef = (el: HTMLElement) => {
      triggerElement.current = el

      if (typeof ref === 'function') {
        ref(triggerElement.current)
      } else if (ref) {
        ref.current = triggerElement.current
      }
    }

    return (
      <>
        {from(
          updateRef,
          () => setVisible(!visible),
          visible,
          () => setVisible(true),
          () => setVisible(false)
        )}

        {visible &&
          (usePortal ? <PopperPortal>{popper}</PopperPortal> : popper)}
      </>
    )
  }
)

Popover.displayName = 'Popover'
export default Popover
