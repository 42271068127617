/* eslint-disable @typescript-eslint/no-var-requires */
const colors = require('tailwindcss/colors')

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './pages/**/*.ts',
    './pages/**/*.tsx',
    './components/**/*.ts',
    './components/**/*.tsx'
  ],
  theme: {
    extend: {
      boxShadow: {
        small: '0px 5px 12px 0px rgba(0,0,0,0.12)',
        smallest: '0px 2px 4px 0px rgba(0,0,0,0.10)',
        common:
          'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
        light:
          '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        dark: '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)'
      },
      transitionProperty: {
        width: 'width',
        height: 'height'
      },
      fontSize: {
        xxs: '.65rem'
      },
      borderWidth: {
        3: '3px'
      },
      colors: {
        viz: {
          lightGray: '#F3F4F6',
          quorumRed: '#E36981',
          abstained: '#D5D9DE',
          no: '#9CA3AF',
          yesActive: '#0C5FE3',
          yesInactive: '#C4D6F2',
          green: '#48980E'
        },
        blue: {
          light: '#2172E8',
          DEFAULT: '#0C5FE3',
          dark: '#004BC3'
        },
        lightBlue: {
          DEFAULT: '#1258d0'
        },
        'text-primary': {
          DEFAULT: '#25283c'
        },
        'text-secondary': {
          DEFAULT: '#8e8e9a'
        },
        'gradient-blue': {
          light: '#2CB4FF',
          DEFAULT: '#0B9CFF'
        },
        'light-blue': colors.sky,
        teal: colors.teal,
        cyan: colors.cyan,
        green: colors.emerald,
        yellow: colors.amber,
        purple: colors.violet
      },
      minWidth: {
        xs: '20rem',
        sm: '30rem',
        md: '40rem',
        lg: '60rem'
      },
      maxWidth: {
        xxs: '15rem',
        '3/4': '75%'
      },
      minHeight: {
        unset: 'unset',
        xs: '20rem',
        md: '40rem',
        50: '50rem',
        lg: '60rem',
        '9/10-screen': '90vh',
        'screen-minus-navbar': 'calc(100vh - 72px)'
      },
      maxHeight: {
        '8/10-screen': '80vh',
        '9/10-screen': '90vh',
        'screen-minus-navbar': 'calc(100vh - 72px)'
      },
      height: {
        'screen-minus-navbar': 'calc(100vh - 72px)',
        18: '4.5rem'
      },
      fontFamily: {
        sans: 'museo-sans, sans-serif',
        nunito: 'Nunito'
      },
      keyframes: {
        'scale-x': {
          '0%': { transform: 'scaleX(1)' },
          '100%': { transform: 'scaleX(0)' }
        }
      },
      filter: {
        'brightness-95': 'brightness(95%)',
        'blur-16': 'blur(16px)',
        'blur-50': 'blur(50px)'
      },
      zIndex: {
        75: 75,
        100: 100,
        200: 200,
        2000: 2000,
        3000: 3000,
        4000: 4000,
        9999: 9999
      },
      aspectRatio: { 'w2/h1': '2 / 1' },
      backgroundImage: () => ({
        'hero-image': "url('/static/people3.png')",
        'about-image': "url('/static/people-network2.png')",
        'jobfair-bg': "url('/static/jobfair-bg.png')"
      })
    }
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@tailwindcss/forms'),
    require('tailwindcss-filters'),
    require('@tailwindcss/aspect-ratio')
  ]
}
