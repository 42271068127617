import { defineLocale, locale } from 'moment'
import momentTz from 'moment-timezone'

defineLocale('en-abbrv', {
  parentLocale: 'en',
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ds',
    ss: '%ds',
    m: 'a minute',
    mm: '%d mins',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    M: '%dM',
    MM: '%dM',
    y: '%dY',
    yy: '%dY'
  },
  meridiem: hour => (hour < 12 ? 'a' : 'p')
})
locale('en')

momentTz.tz.setDefault(momentTz.tz.guess())

export const groupKeyFormat = 'YYYY-MM-DD'

export const calendarFormats = {
  sameDay: '[Today] \u00B7 ddd, MMM Do',
  nextDay: '[Tomorrow] \u00B7 ddd, MMM Do',
  nextWeek: '[This] dddd \u00B7 ddd, MMM Do',
  lastDay: '[Yesterday] \u00B7 ddd, MMM Do',
  lastWeek: '[Last] dddd \u00B7 ddd, MMM Do',
  sameElse: 'ddd, MMM Do, YYYY'
}
