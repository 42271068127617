import { print } from 'graphql'
import { SEOQuery } from 'graphql/documents'
import { SEO, SEOQueryResult } from 'graphql/generated'
import { NextPageContextApp } from './apolloClient'
import { ROOT_HOSTNAME } from './helpers'
import { extractValidSubdomainFromHost } from './subdomainUtils'

type CachedSEOEntry = {
  seo: SEO
  loadedAt: number
}

class SEOCache {
  readonly pathToSEO: Map<string, CachedSEOEntry>
  readonly maxAgeSeconds: number

  constructor() {
    this.pathToSEO = new Map()
    this.maxAgeSeconds = process.env.NODE_ENV === 'development' ? 1 : 60

    setInterval(() => this.clearStaleEntries(), 10 * 1000)
  }

  private clearStaleEntries() {
    const keys = Array.from(this.pathToSEO.keys())
    keys.forEach(key => {
      const cachedEntry = this.pathToSEO.get(key)
      if (!cachedEntry) {
        return
      }

      if ((Date.now() - cachedEntry.loadedAt) / 1000 > this.maxAgeSeconds) {
        this.pathToSEO.delete(key)
      }
    })
  }

  async loadFromAppContext(
    appContext: NextPageContextApp
  ): Promise<Maybe<CachedSEOEntry['seo']>> {
    try {
      const host =
        appContext.ctx.req?.headers.host ||
        (typeof window !== 'undefined' && window.location.hostname) ||
        null
      const path = appContext.asPath || appContext.ctx.asPath || '/'

      if (!host) {
        return null
      } else if (path.includes('/_next/data')) {
        return null
      }

      const url = new URL(ROOT_HOSTNAME)
      const subdomain = extractValidSubdomainFromHost(host)
      url.hostname = subdomain ? `${subdomain}.${url.hostname}` : url.hostname
      url.pathname = path
      return this.load(url.toString())
    } catch (ex) {
      return null
    }
  }

  async load(path: Maybe<string>): Promise<Maybe<CachedSEOEntry['seo']>> {
    if (!path) {
      return null
    }

    const cachedEntry = this.pathToSEO.get(path)
    if (cachedEntry) {
      if ((Date.now() - cachedEntry.loadedAt) / 1000 < this.maxAgeSeconds) {
        return cachedEntry.seo
      }

      this.pathToSEO.delete(path)
    }

    const seo = await this.resolvePath(path)
    if (!seo) {
      return null
    }

    this.pathToSEO.set(path, {
      loadedAt: Date.now(),
      seo
    })
    return seo
  }

  private async resolvePath(path: string) {
    try {
      const resp = await fetch(`${process.env.NEXT_PUBLIC_GRAPHQL_HOST}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: print(SEOQuery),
          operationName: 'SEOQuery',
          variables: {
            path
          }
        })
      })
      const json = await resp.json()
      const data = json.data as SEOQueryResult

      return data.seo
    } catch (ex) {
      console.warn(`Failed to resolve path(${path}): ${ex}`)
      return null
    }
  }
}

export default SEOCache
