import {
  LoginWithAppleIdMutationResult,
  useLoginWithAppleIdMutation,
  ViewerFieldsFragment
} from 'graphql/generated'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { parseGqlErrors } from 'hooks/useQueryErrorHandler'
import jwt_decode from 'jwt-decode'
import useLogin from 'hooks/useLogin'
import Button, { IProps } from 'components/Button'
import { faApple } from '@fortawesome/free-brands-svg-icons'
import AppleLogin from 'react-apple-login'
import { ROOT_HOSTNAME } from 'lib/helpers'
import { twMerge } from 'tailwind-merge'

const AppleClientId = process.env.NEXT_PUBLIC_APPLE_BUNDLE_ID_WWW_SID!

const deviceInfo = {
  appVersion: '1.0',
  systemVersion: 'web',
  timezone:
    typeof window !== 'undefined'
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : undefined
}

const PopupClosedError = 'popup_closed_by_user'

export default function useAppleLogin({
  onRedirect,
  successCallback,
  disabled
}: {
  onRedirect?: CallbackWithParam<ViewerFieldsFragment, string>
  successCallback?: Callback | undefined
  disabled?: boolean
}) {
  const [loggingIn, setLoggingIn] = useState(false)
  const [appleAuthError, setAppleAuthError] = useState<any>()
  const [showAppleLogin, setShowAppleLogin] = useState(false)

  // to avoid `AppleID is not defined` client side errors, we'll delay using the snippet script...
  // @see https://github.com/patelmayankce/react-apple-login/issues/41#issuecomment-1211994975
  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      if (typeof window.AppleID !== 'undefined') {
        setShowAppleLogin(true)
      }
    }, 2000)
  }, [])

  const onLogin = useLogin()

  const onAfterLogin = useCallback(
    async (resp: LoginWithAppleIdMutationResult) => {
      setLoggingIn(false)
      if (!resp.loginWithAppleId?.success) {
        return
      }

      const token = resp.loginWithAppleId.jwtToken
      await onLogin(token, {
        tracking: { method: 'Apple', type: 'login' },
        redirectExplicit: onRedirect,
        successCallback
      })
    },
    [onLogin, onRedirect, successCallback]
  )

  const [loginApple, { error: appleIdLoginError, loading: loadingApple }] =
    useLoginWithAppleIdMutation({
      onCompleted: onAfterLogin,
      onError: () => {
        window.analytics?.trackAll(`Account - Log In - Error`, {
          type: 'Apple'
        })
        setLoggingIn(false)
      }
    })

  const appleResponse = useCallback(
    (response: {
      authorization: { state?: string; code: string; id_token: string }
      user?: {
        email?: string
        name?: {
          firstName?: string
          lastName?: string
        }
      }
      error: any
    }) => {
      if (response.error) {
        setAppleAuthError(response.error)
        return
      }

      const decodedData = jwt_decode<{ email: string; sub: string }>(
        response.authorization.id_token
      )

      loginApple({
        variables: {
          login: {
            appleUserAccountId: decodedData.sub,
            deviceInfo,
            identityToken: response.authorization.id_token,
            code: response.authorization.code
          }
        }
      })
    },
    [loginApple]
  )

  const appleError = useMemo(() => {
    const error =
      appleAuthError && appleAuthError.error !== PopupClosedError
        ? JSON.stringify(appleAuthError.error)
        : parseGqlErrors(appleIdLoginError)._generic

    if (!error) {
      setLoggingIn(false)
    }

    return error
  }, [appleAuthError, appleIdLoginError])

  const AppleButton = useCallback(
    ({
      classnames = '',
      buttonSize = 'lg',
      fullWidth = false
    }: {
      buttonSize?: IProps['size']
      classnames?: string
      fullWidth?: boolean
    }) => {
      if (!AppleClientId || !showAppleLogin) {
        return null
      }

      return (
        <AppleLogin
          clientId={AppleClientId}
          redirectURI={`${ROOT_HOSTNAME}/auth/login`}
          usePopup={true}
          callback={appleResponse} // Catch the response
          scope="email name"
          responseMode="query"
          render={(
            renderProps //Custom Apple Sign in Button
          ) => (
            <Button
              label="Sign in with Apple"
              icon={faApple}
              color={'white'}
              disabled={loadingApple || disabled}
              loading={loadingApple}
              onClick={e => {
                setLoggingIn(true)
                setAppleAuthError(undefined)
                renderProps.onClick(e)
              }}
              size={buttonSize}
              className={twMerge(
                'border border-gray-300 rounded-md text-gray-500 text-sm',
                fullWidth && 'w-full',
                classnames
              )}
            />
          )}
        />
      )
    },
    [showAppleLogin, appleResponse, disabled, loadingApple]
  )

  return {
    appleError,
    loading: loggingIn || loadingApple,
    AppleButton
  }
}
