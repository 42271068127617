import { useCollectiveContext } from 'context/CollectiveContext'
import useCurrentUser from './useCurrentUser'
import useFlag from './useFlag'
import useIsLoggedIn from './useIsLoggedIn'
import usePreference from './usePreference'

const InternalCollectives = ['Kazinori Collectivez']

/**
 *
 * @param featureTag flag name in DB, ie. ENABLE_DAO_FORUMS (if flag does not exist, response will be considered beta by default since hook is being used)
 * @param options: {allowForInternalCollectives: should the feature be allowed for internal collectives like kazinori, allowForCollectives: array of collective names or ids to enable features for}
 * @returns an object {isBeta, allowedAccess} which shows the feature is enabled
 */

function useBetaFeatureFlag(
  featureTag: string,
  options: {
    allowForInternalCollectives?: boolean
    allowForCollectives?: string[]
  } = { allowForCollectives: [], allowForInternalCollectives: false }
) {
  const { allowForCollectives, allowForInternalCollectives } = options
  const loggedIn = useIsLoggedIn()
  const user = useCurrentUser()
  const { collective } = useCollectiveContext()

  const isSuperAdmin = usePreference('isSuperAdmin', false)
  const isOutOfBeta = useFlag(featureTag, false) // false means it's beta, beta by default if this hook is used and flag isnt found

  const isInternalCollective =
    collective &&
    InternalCollectives.find(
      collectiveIdName =>
        collectiveIdName.toLowerCase() === collective.name.toLowerCase() ||
        collectiveIdName === collective.id
    )

  const allowedAccessCollectives =
    collective &&
    !!allowForCollectives?.find(
      collectiveIdName =>
        collectiveIdName.toLowerCase() === collective.name.toLowerCase() ||
        collectiveIdName === collective.id
    )

  const isEnabled =
    (loggedIn && user && isSuperAdmin) ||
    isOutOfBeta ||
    (allowForInternalCollectives && isInternalCollective) ||
    allowedAccessCollectives

  return { isBeta: !isOutOfBeta, allowedAccess: isEnabled }
}

export default useBetaFeatureFlag
