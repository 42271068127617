import { Switch } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import FieldLabel from './FieldLabel'

export interface IProps {
  label?: React.ReactNode
  info?: string
  checked: boolean
  onChange: CallbackWithParam<boolean>
  className?: string
  labelClassName?: string
  switchClassName?: string
  disabled?: boolean
}

function Toggle({
  label,
  info,
  checked,
  onChange,
  className,
  labelClassName,
  switchClassName,
  disabled
}: IProps) {
  return (
    <Switch.Group
      as="div"
      className={twMerge(
        'flex items-center justify-between space-x-4',
        className
      )}
    >
      {!!label && (
        <Switch.Label>
          <FieldLabel
            label={label}
            className={twMerge(disabled && 'text-gray-400', labelClassName)}
          />
          {!!info && (
            <p className="text-xs font-light text-text-secondary">{info}</p>
          )}
        </Switch.Label>
      )}
      <Switch
        as="button"
        checked={checked}
        onChange={onChange}
        className={twMerge(
          checked ? 'bg-blue-600' : 'bg-gray-300',
          'relative inline-flex shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline',
          disabled && 'cursor-not-allowed bg-gray-200',
          switchClassName
        )}
        disabled={disabled}
      >
        {({ checked }) => (
          <span
            className={`${
              checked ? 'translate-x-5' : 'translate-x-0'
            } inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`}
          />
        )}
      </Switch>
    </Switch.Group>
  )
}

export default Toggle
