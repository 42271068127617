import { twMerge } from 'tailwind-merge'
import { Dialog } from '@headlessui/react'
import {
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState
} from 'react'
import { noop } from 'lodash'
import { useModalContext } from 'context/ModalContext'
import { useModalContext as useEventModalContext } from 'context/EventModalContext'
import RoundedIconButton from 'components/common/RoundedIconButton'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { useBreakpoint } from 'hooks/useBreakpoint'
import { Fade } from 'components/common'
import { FadeProps } from 'components/common/Fade'

function BaseModal({
  classNames,
  children,
  zIndex = 3000,
  onClose = noop,
  disableBackdropClick = false,
  floatOnMobile = false,
  hideCloseButtonOnMobile,
  fadeProps
}: PropsWithChildren<{
  classNames?: string
  zIndex?: number
  onClose?: Callback
  disableBackdropClick?: boolean
  floatOnMobile?: boolean
  hideCloseButtonOnMobile?: boolean
  fadeProps?: Omit<FadeProps, 'in'>
}>) {
  const { hideModal } = useModalContext()
  const { hideModal: hideEventModal } = useEventModalContext()
  const { isMobile } = useBreakpoint()

  const doClose = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()
      hideModal?.()
      hideEventModal?.()
      onClose?.()
    },
    [hideModal, hideEventModal, onClose]
  )

  const floating = !isMobile || floatOnMobile
  const [slideInMobile, setSlideInMobile] = useState(floating)

  useEffect(() => {
    if (!slideInMobile) {
      setSlideInMobile(true)
    }
  }, [slideInMobile])

  const classes = floating
    ? 'min-w-[50%] max-w-[90%] sm:min-w-sm sm:max-w-lg max-h-[85%] rounded-lg'
    : twMerge('w-screen h-0 rounded-t-lg', slideInMobile && 'h-[95%]') // animate mobile slidein
  const showCloseButton =
    (isMobile || disableBackdropClick) && !hideCloseButtonOnMobile

  return (
    <Dialog open={true} onClose={noop}>
      <Fade in duration={!floating ? 0 : 'DEFAULT'} {...fadeProps}>
        <div className="fixed inset-0" style={{ zIndex }}>
          <div
            className="absolute inset-0 bg-gray-500 opacity-75"
            style={{ zIndex: zIndex - 1 }} //for clicks to work
            onClick={!disableBackdropClick ? doClose : noop}
          />

          <div
            className={twMerge(
              'absolute inset-0 flex items-center justify-center',
              !floatOnMobile && 'max-sm:items-end'
            )}
          >
            <div
              className={twMerge(
                'relative bg-white shadow-xl', //base
                'flex flex-col overflow-hidden', //flex flex-col overflow-hidden required for children to be scrollable on web when they also have 'flex-1 flex flex-col and overflow-auto'
                'transition-all duration-300 ease-in-out', // mobile transition slide in from bottom
                classes,
                classNames // generally to override widths
              )}
              style={{ zIndex }} //for clicks to work
            >
              {children}

              {showCloseButton && (
                <div className="absolute right-3 top-3">
                  <RoundedIconButton
                    icon={faTimes}
                    color="white"
                    onClick={doClose}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </Dialog>
  )
}

export default BaseModal
