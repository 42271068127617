import { useApolloClient } from '@apollo/client'
import { usePersistor } from 'lib/apolloClient'
import { useCallback } from 'react'

function useClearApolloCache() {
  const client = useApolloClient()
  const persistor = usePersistor()

  const clearCache = useCallback(async () => {
    await client.clearStore()
    await persistor.purge()
  }, [client, persistor])

  return clearCache
}

export default useClearApolloCache
