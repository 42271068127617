import { twMerge } from 'tailwind-merge'
import Typography, { ITypograhyProps } from './Typography'
import { noop } from 'lodash'

const checkboxSizeMap = {
  xl: 'h-6 w-6',
  lg: 'h-5 w-5',
  default: 'h-4 w-4'
}

const checkboxColorMap = {
  default: 'text-gray-500 border-gray-500',
  blue: 'text-blue border-blue',
  green: 'text-green-600 border-green-600',
  yellow: 'text-yellow-500 border-yellow-500',
  red: 'text-red-500 border-red-500'
}

export type Props = {
  size?: keyof typeof checkboxSizeMap
  color?: keyof typeof checkboxColorMap
  typographyProps?: ITypograhyProps
  label?: string
  checked: boolean
  onChange?: CallbackWithParam<boolean>
  customColor?: string
  disabled?: boolean
}

const Checkbox: React.FC<Props> = ({
  size = 'default',
  color = 'default',
  typographyProps = {},
  label,
  checked,
  onChange = noop,
  customColor,
  disabled = false
}) => {
  return (
    <div
      className="relative flex items-center cursor-pointer"
      onClick={() => onChange(!checked)}
    >
      <div className="flex h-6 items-center">
        <input
          name="checkboxName"
          type="checkbox"
          checked={checked}
          className={twMerge(
            'rounded border-2 focus:ring-transparent cursor-pointer',
            checkboxSizeMap[size],
            !customColor && checkboxColorMap[color],
            disabled && 'opacity-50 cursor-not-allowed'
          )}
          readOnly
          style={{
            color: customColor,
            borderColor: customColor
          }}
        />
      </div>
      {!!label && (
        <div className="ml-3 leading-6">
          <Typography {...typographyProps}>{label}</Typography>
        </div>
      )}
    </div>
  )
}

export default Checkbox
