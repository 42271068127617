import {
  AuthMessageQueryResult,
  AuthMessageQueryVariables,
  useAuthCryptoMutation
} from 'graphql/generated'
import { useWeb3 } from 'context/Web3Context'
import useToasts from 'hooks/useToasts'
import { useLazyQuery } from 'lib/useLazyQuery'
import { AuthMessageQuery } from 'graphql/documents'
import { useQueryErrorHandler } from 'hooks'

function useLinkWallet() {
  const { signerAddress, signer } = useWeb3()

  const loadAuthMessage = useLazyQuery<
    AuthMessageQueryResult,
    AuthMessageQueryVariables
  >(AuthMessageQuery)
  const onError = useQueryErrorHandler('Failed to authenticate wallet')
  const [authCrypto] = useAuthCryptoMutation({
    onError
  })
  const { addToast } = useToasts()

  const linkWallet = async () => {
    const res = await loadAuthMessage({})

    const authMessage = res.data.authMessage
    if (!signerAddress || !authMessage) {
      addToast('Missing wallet information', {
        appearance: 'warning'
      })
      return
    }

    const signedMessage = await signer?.signMessage(authMessage)
    if (!signedMessage) {
      addToast('Invalid signature', {
        appearance: 'warning'
      })
      return
    }

    await authCrypto({
      variables: {
        address: signerAddress,
        signature: signedMessage
      }
    })
  }

  return {
    linkWallet
  }
}

export default useLinkWallet
