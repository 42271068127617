import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

function PopperPortal(props: { children: React.ReactNode }) {
  const { children } = props
  const [mounted, setMounted] = useState(false)
  const el = useMemo(
    () =>
      typeof document !== 'undefined'
        ? document.querySelector('#popper-root')
        : null,
    []
  )

  useEffect(() => setMounted(true), [])
  if (!mounted || !el) {
    return null
  }

  return createPortal(children, el)
}

export default PopperPortal
