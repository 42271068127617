import { useMemo } from 'react'

const IGNORE_SUBDOMAINS = ['email', 'uat', 'help', 'beta', 'clicks']

export function isIPAddress(host: string) {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:\d{0,5})?$/.test(
    host
  )
}

export function isIgnoredSubdomain(subdomain: string) {
  return IGNORE_SUBDOMAINS.includes(subdomain.toLowerCase())
}

export function isLocalHost(host: string) {
  return host === 'localhost'
}

export function getSubdomainFromHost(host: string) {
  const subdomainRegex = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i
  const matches = host.match(subdomainRegex)
  return matches?.[1]
}

export function extractValidSubdomainFromHost(host: Maybe<string>) {
  if (!host) {
    return null
  }

  if (isIPAddress(host) || isLocalHost(host)) {
    return null
  }

  const subdomain = getSubdomainFromHost(host)
  if (!subdomain) {
    return null
  }

  if (isIgnoredSubdomain(subdomain)) {
    return null
  }

  return subdomain
}

export function useSubdomain() {
  return useMemo(() => {
    if (typeof window === 'undefined') {
      return null
    }

    const host = window.location?.host
    return extractValidSubdomainFromHost(host)
  }, [])
}
