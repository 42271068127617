import Bugsnag from 'lib/bugsnag'
import { useEffect, useState } from 'react'

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID as string

const UA_ENABLED = !!process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID
const HEAP_ENABLED = !!process.env.NEXT_PUBLIC_HEAP_ID

function useAnalytics() {
  const [commonProperties, setCommonProperties] = useState<KVMap<any>>({})

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    async function init() {
      function reset() {
        try {
          typeof gtag !== 'undefined' &&
            UA_ENABLED &&
            gtag('config', GA_MEASUREMENT_ID, {
              user_id: null
            })

          typeof heap !== 'undefined' && HEAP_ENABLED && heap.resetIdentity()
        } catch (ex) {
          Bugsnag.notify(ex)
        }
      }

      function identify(
        userId: string,
        userProperties?: object,
        destinations?: TAnalyticsDestination[]
      ) {
        try {
          if (!destinations || destinations.includes('UA')) {
            typeof gtag !== 'undefined' &&
              UA_ENABLED &&
              gtag('config', GA_MEASUREMENT_ID, {
                user_id: userId,
                ...userProperties
              })
          }

          if (
            (!destinations || destinations.includes('HEAP')) &&
            HEAP_ENABLED &&
            typeof heap !== 'undefined'
          ) {
            heap.identify(userId)
            if (userProperties) {
              heap.addUserProperties(userProperties)
            }
          }
        } catch (ex) {
          Bugsnag.notify(ex)
        }
      }

      function trackPageView(
        url: string,
        destinations?: TAnalyticsDestination[]
      ) {
        try {
          if (!destinations || destinations.includes('UA')) {
            typeof gtag !== 'undefined' &&
              UA_ENABLED &&
              gtag('config', GA_MEASUREMENT_ID, {
                page_path: url
              })
          }
        } catch (ex) {
          Bugsnag.notify(ex)
        }
      }

      function trackEvent(
        eventName: string,
        eventProperties?: object,
        destinations?: TAnalyticsDestination[]
      ) {
        try {
          if (!destinations || destinations.includes('UA')) {
            typeof gtag !== 'undefined' &&
              UA_ENABLED &&
              gtag('event', eventName, {
                ...commonProperties,
                ...eventProperties
              })
          }

          if (!destinations || destinations.includes('HEAP')) {
            typeof heap !== 'undefined' &&
              HEAP_ENABLED &&
              heap.track(eventName, {
                ...commonProperties,
                ...eventProperties
              })
          }
        } catch (ex) {
          Bugsnag.notify(ex)
        }
      }

      //HEAP ONLY
      function addUserProperties(props: object) {
        try {
          if (HEAP_ENABLED && typeof heap !== 'undefined') {
            heap.addUserProperties(props)
          }
        } catch (ex) {
          Bugsnag.notify(ex)
        }
      }

      function addEventProperties(props: object) {
        try {
          if (HEAP_ENABLED && typeof heap !== 'undefined') {
            heap.addEventProperties(props)
          }
        } catch (ex) {
          Bugsnag.notify(ex)
        }
      }

      function clearEventProperties() {
        try {
          if (HEAP_ENABLED && typeof heap !== 'undefined') {
            heap.clearEventProperties()
          }
        } catch (ex) {
          Bugsnag.notify(ex)
        }
      }

      function removeEventProperty(prop: string) {
        try {
          if (HEAP_ENABLED && typeof heap !== 'undefined') {
            heap.removeEventProperty(prop)
          }
        } catch (ex) {
          Bugsnag.notify(ex)
        }
      }

      function trackEventAllDestinations(
        eventName: string,
        eventProperties?: object
      ) {
        trackEvent(eventName, eventProperties)
      }

      window.analytics = {
        reset,
        identify,
        track: trackEvent,
        trackAll: trackEventAllDestinations,
        pageView: trackPageView,
        setCommonProperties,
        heap: {
          addUserProperties,
          addEventProperties,
          clearEventProperties,
          removeEventProperty
        }
      }
      setIsReady(true)
    }

    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isReady }
}

export default useAnalytics
