import React from 'react'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export interface HiddenProps {
  lgDown?: boolean
  lgUp?: boolean
  mdDown?: boolean
  mdUp?: boolean
  only?: Breakpoint | Breakpoint[]
  smDown?: boolean
  smUp?: boolean
  xlDown?: boolean
  xsDown?: boolean
  xsUp?: boolean
  children?: React.ReactNode
}

const Hidden: React.FC<HiddenProps> = ({
  lgDown = false,
  lgUp = false,
  mdDown = false,
  mdUp = false,
  only,
  smDown = false,
  smUp = false,
  xlDown = false,
  xsDown = false,
  xsUp = false,
  children
}) => {
  const breakpoints = only ? (Array.isArray(only) ? only : [only]) : []
  const breakpointClasses: string[] = []

  if (xsDown || breakpoints.includes('xs')) {
    breakpointClasses.push('hidden', 'sm:block')
  }
  if (smDown || breakpoints.includes('sm')) {
    breakpointClasses.push('sm:hidden', 'md:block')
  }
  if (mdDown || breakpoints.includes('md')) {
    breakpointClasses.push('md:hidden', 'lg:block')
  }
  if (lgDown || breakpoints.includes('lg')) {
    breakpointClasses.push('lg:hidden', 'xl:block')
  }
  if (xlDown || breakpoints.includes('xl')) {
    breakpointClasses.push('xl:hidden')
  }

  if (xsUp) {
    breakpointClasses.push('sm:hidden')
  }
  if (smUp) {
    breakpointClasses.push('md:hidden')
  }
  if (mdUp) {
    breakpointClasses.push('lg:hidden')
  }
  if (lgUp) {
    breakpointClasses.push('xl:hidden')
  }

  return <div className={breakpointClasses.join(' ')}>{children}</div>
}

export default Hidden
