import App, { AppProps } from 'next/app'
import React, { useEffect, useMemo } from 'react'
import { ApolloProvider } from '@apollo/client'
import {
  initApolloClient,
  NextPageContextApp,
  NextPageContextWithApollo
} from 'lib/apolloClient'
import { DefaultSeo } from 'next-seo'
import Bugsnag from 'lib/bugsnag'
import Error from './_error'
import cookies from 'next-cookies'
import { AuthenticationProvider } from 'context/AuthenticationContext'
import { ModalProvider } from 'context/ModalContext'

import 'tailwindcss/tailwind.css'
import 'node_modules/react-quill/dist/quill.snow.css'
import '../styles/globals.css'
import 'lib/moment'
import 'react-datepicker/dist/react-datepicker.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'stream-chat-react/dist/css/index.css'
import '../styles/chat/chat.css'
import '../styles/quill.css'
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

import ConditionalSsr from 'components/ConditionalSsr/ConditionalSsr'
import { initBigNumberJSON } from 'lib/BigInt'
import { EventEmitterProvider } from 'context/EventEmitterContext'
import { ChatProvider } from 'components/Chats/ChatProvider'
import SEOCache from 'lib/seoCache'
import { SEO } from 'graphql/generated'
import { MetaTag } from 'next-seo/lib/types'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Web3Provider } from 'context/Web3Context'
import { ToastProvider } from 'context/ToastContext'
import { AnalyticsProvider } from 'context/AnalyticsContext'

export interface SEOPageProps {
  seoData: Maybe<SEO>
}

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)
const seo = new SEOCache()

function UpstreamApp({
  Component,
  pageProps,
  apolloClient,
  apolloState,
  token,
  seoData
}: AppProps & NextPageContextWithApollo & SEOPageProps) {
  const client = apolloClient || initApolloClient(apolloState)

  // SSR-condition init for material UI
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    jssStyles?.parentElement?.removeChild(jssStyles)
  }, [])

  // make bigints JSON-serializable
  useEffect(() => {
    initBigNumberJSON()
  }, [])

  const additionalMetaTags = useMemo(() => {
    if (!seoData) {
      return undefined
    }

    const tags: MetaTag[] = []
    if (seoData.title) {
      tags['twitter:title'] = seoData.title
    }
    if (seoData.metaDescription) {
      tags['twitter:description'] = seoData.metaDescription
    }
    if (seoData.metaKeywords) {
      tags.push({ name: 'keywords', content: seoData.metaKeywords.join(',') })
    }
    if (seoData.additionalMetaTags) {
      seoData.additionalMetaTags.forEach(tag =>
        tags.push({ name: tag.name, content: tag.content })
      )
    }

    return tags.length ? tags : undefined
  }, [seoData])

  return (
    // @ts-ignore
    <ErrorBoundary FallbackComponent={Error}>
      <AuthenticationProvider token={token}>
        <ApolloProvider client={client}>
          <DefaultSeo
            title={
              seoData?.title || `Upstream - The Easiest Way to Create a DAO`
            }
            description={
              seoData?.metaDescription ||
              `The Easiest Way to Start a DAO. Upstream DAOs make it simple to launch a DAO with its no-code, full-stack platform. No technical experience required to set up a blockchain community.`
            }
            additionalMetaTags={additionalMetaTags}
            openGraph={{
              type: 'website',
              title: seoData?.title ?? undefined,
              description: seoData?.metaDescription ?? undefined,
              url: 'https://upstreamapp.com/',
              site_name: 'Upstream',
              images: [
                {
                  url: 'https://upstreamapp.com/static/robot.png',
                  width: 1267,
                  height: 728,
                  alt: 'Upstream Robot Mascot & Logo'
                }
              ]
            }}
            twitter={{
              handle: '@joinupstream',
              site: '@joinupstream',
              cardType: 'app'
            }}
          />
          <ToastProvider>
            <ConditionalSsr>
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY!}
                useEnterprise
                container={{
                  element: 'badge-root',
                  parameters: {
                    badge: 'bottomleft'
                  }
                }}
              >
                <EventEmitterProvider>
                  <AnalyticsProvider>
                    <Web3Provider>
                      <ModalProvider>
                        <ChatProvider>
                          <Component {...pageProps} />
                        </ChatProvider>
                      </ModalProvider>
                    </Web3Provider>
                  </AnalyticsProvider>
                </EventEmitterProvider>
              </GoogleReCaptchaProvider>
            </ConditionalSsr>
          </ToastProvider>
        </ApolloProvider>
      </AuthenticationProvider>
    </ErrorBoundary>
  )
}

export default UpstreamApp

UpstreamApp.getInitialProps = async (appContext: NextPageContextApp) => {
  const seoData = await seo.loadFromAppContext(appContext)

  // Read token cookie for auth'd SSRs
  const token = cookies(appContext.ctx)['token']

  // Initialize ApolloClient if not already done
  const apolloClient =
    appContext.apolloClient || initApolloClient(appContext.apolloState, token)

  // We send the Apollo Client as a prop to the component to avoid calling initApollo() twice in the server.
  // Otherwise, the component would have to call initApollo() again but this
  // time without the context. Once that happens, the following code will make sure we send
  // the prop as `null` to the browser.
  // @ts-ignore
  apolloClient.toJSON = () => null

  // Add apolloClient to NextPageContext & NextAppContext.
  // This allows us to consume the apolloClient inside our
  // custom `getInitialProps({ apolloClient })`.
  appContext.apolloClient = appContext.ctx.apolloClient = apolloClient
  if (appContext.ctx.req) {
    // @ts-ignore
    appContext.ctx.req.apolloClient = apolloClient
  }

  // Run wrapped getInitialProps methods
  const appProps = await App.getInitialProps(appContext)

  // Only on the server:
  if (typeof window === 'undefined') {
    // When redirecting, the response is finished.
    // No point in continuing to render
    if (appContext.res && appContext.res.writableEnded) {
      return { ...appProps, seoData }
    }

    const { AppTree } = appContext
    if (!AppTree) {
      return { ...appProps, seoData }
    }

    try {
      // Import `@apollo/client/react/ssr` dynamically.
      // We don't want to have this in our client bundle.
      const { getDataFromTree } = await import('@apollo/client/react/ssr')

      // Take the Next.js AppTree, determine which queries are needed to render,
      // and fetch them.
      const treeProps = { ...appProps, apolloClient, token, seoData }
      await getDataFromTree(<AppTree {...treeProps} />)
    } catch (error) {
      // Prevent Apollo Client GraphQL errors from crashing SSR.
      // Handle them in components via the data.error prop:
      // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
      console.error('Error while running `getDataFromTree`', error)
    }
  }

  const apolloState = apolloClient?.cache.extract()

  return {
    ...appProps,
    // Extract query data from the Apollo store
    apolloState,
    // Provide the client for ssr. As soon as this payload
    apolloClient,
    // pass the cookie token down the pages
    token,
    seoData
  }
}
