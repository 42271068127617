import Link from 'next/link'
import React, { MouseEvent, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

type TFontSizes =
  | 'xs'
  | 'sm'
  | 'md'
  | 'default'
  | 'lg'
  | 'xl'
  | 'heading'
  | 'heading-xl'
  | 'heading-2xl'
  | 'heading-3xl'
  | 'inherit'
type TColors =
  | 'lightGray'
  | 'gray'
  | 'darkGray'
  | 'default'
  | 'black'
  | 'white'
  | 'blue'
  | 'success'
  | 'warning'
  | 'error'
  | 'red'
type FontWeight =
  | 'light'
  | 'default'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'black'

const fontSizeMap: { [key in TFontSizes]: string } = {
  xs: 'text-xs', //12 caption
  sm: 'text-sm', //14 body2 subtitle2
  md: 'text-base', //16
  default: 'text-base', //16 body1 subtitle1
  lg: 'text-lg', //18
  xl: 'text-xl', //20
  heading: 'text-2xl', //24
  'heading-xl': 'text-3xl',
  'heading-2xl': 'text-4xl',
  'heading-3xl': 'text-5xl',
  inherit: 'text-inherit'
}

const colorsMap: { [key in TColors]: string } = {
  lightGray: 'text-gray-400',
  gray: 'text-gray-500',
  darkGray: 'text-gray-700',
  default: 'text-gray-800',
  black: 'text-black',
  white: 'text-white',
  blue: 'text-blue', //from config
  success: 'text-green-600', //from config
  warning: 'text-yellow-500', //from config
  error: 'text-red-500',
  red: 'text-red-500'
}

const fontWeightMap: { [key in FontWeight]: string } = {
  light: 'font-light', //300
  default: 'font-normal', //400
  medium: 'font-medium', //500
  semibold: 'font-semibold', //600
  bold: 'font-bold', //700
  extrabold: 'font-extrabold', //800,
  black: 'font-black' //900
}

const lineClamps = [
  'line-clamp-none',
  'line-clamp-1',
  'line-clamp-2',
  'line-clamp-3',
  'line-clamp-4',
  'line-clamp-5'
]

export function getFullClassName({
  size = 'md',
  color = 'default',
  underline = false,
  uppercase = false,
  italics = false,
  truncate = 'none',
  fontWeight = 'default',
  className,
  textCenter = false,
  isLink,
  linkHover
}: BaseStyles & {
  isLink?: boolean
}) {
  return twMerge(
    fontSizeMap[size],
    colorsMap[color],
    truncate != 'none' && lineClamps[truncate],
    underline && 'underline',
    uppercase && 'uppercase',
    italics && 'italic',
    fontWeightMap[fontWeight],
    linkHover && isLink && 'hover:text-blue cursor-pointer',
    textCenter && 'text-center',
    className
  )
}

interface BaseStyles {
  size?: TFontSizes
  fontWeight?: FontWeight
  color?: TColors
  underline?: boolean
  uppercase?: boolean
  italics?: boolean
  truncate?: 0 | 1 | 2 | 3 | 4 | 5 | 'none'
  textCenter?: boolean
  linkHover?: boolean
  className?: string
}

export interface ITypograhyProps extends BaseStyles {
  component?: React.ElementType
  children?: React.ReactNode

  dangerouslySetInnerHTML?: boolean
  // link props
  href?: Maybe<string>
  newTab?: boolean
  onClick?: CallbackWithParam<MouseEvent>
}

const Typography = React.forwardRef<HTMLElement, ITypograhyProps>(
  (
    {
      dangerouslySetInnerHTML,
      component: Tag = 'p',
      //link props
      href,
      newTab = false,
      onClick,
      children,
      ...rest
    },
    ref
  ) => {
    const childrenEl = dangerouslySetInnerHTML
      ? { dangerouslySetInnerHTML: { __html: children } }
      : { children }

    const isLink = !!href
    const makeNextJSLink = useMemo(
      () =>
        isLink &&
        (href.startsWith('/') ||
          href.includes('upstreamapp') ||
          href.includes('upstream.local') ||
          href.includes('localhost')),
      [href, isLink]
    )

    Tag = isLink && !makeNextJSLink ? 'a' : Tag

    const classNameFull = getFullClassName({ ...rest, isLink })

    const Content = (
      <Tag
        ref={ref}
        className={!makeNextJSLink ? classNameFull : undefined}
        onClick={onClick}
        {...childrenEl}
        {...(isLink && !makeNextJSLink
          ? {
              href
            }
          : {})}
        {...(isLink && !makeNextJSLink && newTab
          ? {
              target: '_blank',
              rel: 'noreferrer'
            }
          : {})}
      />
    )

    if (makeNextJSLink && !!href) {
      return (
        <Link
          href={href}
          target={newTab ? '_blank' : '_self'}
          className={classNameFull}
        >
          {Content}
        </Link>
      )
    }

    return Content
  }
)

Typography.displayName = 'Typography'

export default Typography
