import { useState, useEffect, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'

const useWindowDimensions = (howOften = 300) => {
  const hasWindow = typeof window !== 'undefined'

  const getWindowDimensions = useCallback(() => {
    const width = hasWindow ? window.innerWidth || window.screen.width : null
    const height = hasWindow ? window.innerHeight || window.screen.height : null
    return {
      width,
      height
    }
  }, [hasWindow])

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  const handleResize = useDebouncedCallback(() => {
    setWindowDimensions(getWindowDimensions())
  }, howOften)

  useEffect(() => {
    if (!hasWindow) {
      return
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [getWindowDimensions, handleResize, hasWindow])

  return windowDimensions
}

export default useWindowDimensions
