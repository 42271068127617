import { faChevronCircleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from 'components/common'
import { useSetChain } from '@web3-onboard/react'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useIsEthNetworkDifferent } from 'hooks/collectives/useIsEthNetworkDifferent'
import { getChainIdForNetwork } from 'lib/collectives/helpers'
import React from 'react'

function EthNetworkWarningBanner() {
  const { collective } = useCollectiveContext()
  const [, setChain] = useSetChain()
  const warningShouldExist = useIsEthNetworkDifferent()

  if (!warningShouldExist) {
    return null
  }

  const onClick = () =>
    setChain({ chainId: getChainIdForNetwork(collective.network) })

  return (
    <div
      className="bg-[#F0B328] py-[5px] px-[10px] flex flex-row items-center justify-center cursor-pointer"
      onClick={onClick}
    >
      <Typography className={'text-center'}>
        Your wallet is using the wrong network for this Collective.
        <br />
        Click here to switch to the {collective.network} network.
      </Typography>
      <FontAwesomeIcon icon={faChevronCircleRight} />
    </div>
  )
}

EthNetworkWarningBanner.displayName = 'EthNetworkWarningBanner'

export default EthNetworkWarningBanner
