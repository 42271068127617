import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useMemo,
  useEffect
} from 'react'
import EventEmitter from 'eventemitter3'

interface EventEmitterContextShape {
  emitter: EventEmitter<string | symbol, any>
}

const EventEmitterContext = createContext<EventEmitterContextShape>(
  {} as EventEmitterContextShape
)

export const useEventEmitterContext = () => useContext(EventEmitterContext)

export function useOnEvent(eventName: string, fn: Callback) {
  const { emitter } = useEventEmitterContext()

  useEffect(() => {
    // emitter doesn't exist in SSR
    if (typeof window === 'undefined' || !emitter) {
      return
    }

    emitter.on(eventName, fn)

    return () => {
      emitter.off(eventName, fn)
    }
  }, [emitter, eventName, fn])
}

export function EventEmitterProvider({ children }: PropsWithChildren<{}>) {
  const emitter = useMemo(() => new EventEmitter(), [])

  return (
    <EventEmitterContext.Provider value={{ emitter }}>
      {children}
    </EventEmitterContext.Provider>
  )
}
