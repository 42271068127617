import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react'
import { StreamChat } from 'stream-chat'
import { useCurrentUser, useIsLoggedIn } from 'hooks'
import {
  ChatRoles,
  CustomChannelType,
  CustomStreamChatType,
  StreamChatGenerics
} from './ChatTypes'

export type TEventListeners = { unsubscribe: () => void } | null

interface ChatChannelContextShape {
  channel: CustomChannelType | undefined
  setChannel: CallbackWithParam<CustomChannelType | undefined>
  chatClient: CustomStreamChatType
  logout: () => void
  isModerator: boolean
  isReady: boolean
  bannedUsers: Set<string>
  setBannedUsers: CallbackWithParam<Set<string>>
  reconnectChat: Callback<Promise<boolean>>
  resetChat: Callback
}

const ChatContext = createContext<ChatChannelContextShape>(
  {} as ChatChannelContextShape
)

export const useChat = () => useContext(ChatContext)

interface ChatProviderProps {
  children?: React.ReactNode
}

export function ChatProvider({ children }: ChatProviderProps) {
  const isLoggedin = useIsLoggedIn()

  const chatClient = useMemo(
    () =>
      StreamChat.getInstance<StreamChatGenerics>(
        process.env.NEXT_PUBLIC_GET_STREAM_KEY!
      ),
    []
  )

  const [isReady, setIsReady] = useState(false)
  const [channel, setChannel] = useState<CustomChannelType | undefined>()
  const [bannedUsers, setBannedUsers] = useState<Set<string>>(new Set())

  const user = useCurrentUser()

  const isModerator = useMemo(
    () =>
      chatClient.user?.role === ChatRoles.Moderator ||
      chatClient.user?.role === ChatRoles.Admin,
    [chatClient.user?.role]
  )

  const disconnectChat = useCallback(async () => {
    console.log(`Disconnecting Chat`)
    setIsReady(false)
    await chatClient.disconnectUser()
    console.log(`Disconnected from chat`)
  }, [chatClient])

  const resetChat = useCallback(() => {
    setIsReady(false)
    setChannel(undefined)
    setBannedUsers(new Set())
  }, [])

  const reconnectChat = useCallback(async () => {
    if (
      //user statuses
      !isLoggedin ||
      !user.id ||
      !user.streamChatToken
    ) {
      // console.log(`Cannot connect to chat as ${user.id}`)
      return false
    }

    if (
      chatClient.wsConnection?.isConnecting ||
      chatClient.wsConnection?.isHealthy
    ) {
      setIsReady(true)
      // console.log(`Already Connected to chat ${user.id}`)
      return true
    }

    // console.log(`Connecting to chat as ${user?.id}`)
    const connectionResp = await chatClient.connectUser(
      {
        id: user.id,
        name: user.displayName || user.id,
        username: user.username || undefined,
        image: user.thumb || undefined
      },
      user.streamChatToken
    )

    if (connectionResp && connectionResp.me) {
      //   console.log(`Connected to chat as ${user.id}`)
      setIsReady(true)
    }

    return true
  }, [
    isLoggedin,
    user.id,
    user.streamChatToken,
    user.displayName,
    user.username,
    user.thumb,
    chatClient
  ])

  useEffect(() => {
    const run = async () => {
      if (
        isLoggedin &&
        user.streamChatToken &&
        (!chatClient.user || chatClient.user.id !== user.id)
      ) {
        resetChat()
        await reconnectChat()
      } else if (!isLoggedin) {
        await disconnectChat()
        resetChat()
      }
    }
    run()
  }, [
    user.streamChatToken,
    isLoggedin,
    reconnectChat,
    chatClient,
    resetChat,
    user.id,
    disconnectChat
  ])

  useEffect(() => {
    window.addEventListener('unload', disconnectChat)
    return () => {
      window.removeEventListener('unload', disconnectChat)
    }
  }, [disconnectChat])

  // setup initial data and events and user actions

  return (
    <ChatContext.Provider
      value={{
        channel,
        setChannel,
        bannedUsers,
        setBannedUsers,
        logout: disconnectChat,
        isModerator,
        isReady,
        chatClient,
        reconnectChat,
        resetChat
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}
