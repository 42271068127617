import { useEffect, useState, useCallback } from 'react'

function useSessionStorage<T>(
  key: string,
  initialValue?: T
): [T, CallbackWithParam<T>] {
  const [storedValue, setStoredValue] = useState<T>(initialValue!)

  // load from stroage only after rendered on client side, as to
  // not coflict with SSR renders
  useEffect(() => {
    try {
      const item = window.sessionStorage.getItem(key)
      const parsedValue = item ? JSON.parse(item) : initialValue
      setStoredValue(parsedValue)
    } catch (error) {
      console.log(error)
      setStoredValue(initialValue!)
    }
    // eslint-disable-next-line
  }, [])

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        setStoredValue(value)
        // Save to session storage
        window.sessionStorage.setItem(key, JSON.stringify(value))
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error)
      }
    },
    [key]
  )

  return [storedValue, setValue]
}

export default useSessionStorage
