import Link from 'next/link'
import {
  BaseCollectiveQueryResult,
  ViewerFieldsFragment
} from 'graphql/generated'
import { FormEvent, useCallback, useState } from 'react'
import { faLock } from '@fortawesome/pro-solid-svg-icons'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'
import useEphemeralCryptoAuth from 'hooks/collectives/useEphemeralCryptoAuth'
import useToasts from 'hooks/useToasts'
import { useSubdomain } from 'lib/subdomainUtils'
import { ROOT_HOSTNAME } from 'lib/helpers'
import Typography from 'components/common/Typography'
import Button from 'components/Button'
import useAppleLogin from 'hooks/login/useAppleLogin'
import usePasswordLogin from 'hooks/login/usePasswordLogin'
import TrackableClick from 'components/common/TrackableClick'
import Image from 'next/image'

interface IProps {
  defaultEmail?: string
  defaultPassword?: string
  onLogin?: Callback
  collective?: Maybe<BaseCollectiveQueryResult['collective']>
}

function Login({
  defaultEmail = '',
  defaultPassword = '',
  onLogin: successCallback,
  collective
}: IProps) {
  const subdomain = useSubdomain()

  const [walletLogginIn, setWalletLoggingIn] = useState(false)

  const { addToast } = useToasts()
  const { verifyWalletAndLogin } = useEphemeralCryptoAuth()

  const signInWithWallet = async () => {
    setWalletLoggingIn(true)
    try {
      const resp = await verifyWalletAndLogin()
      if (resp) {
        setWalletLoggingIn(false)
      }
    } catch (e) {
      addToast(e.message, { appearance: 'warning' })
      setWalletLoggingIn(false)
    }
  }

  const onRedirect = useCallback(
    (viewer: ViewerFieldsFragment) => {
      if (subdomain) {
        return `/`
      } else if (viewer.hasCollectives) {
        return `${ROOT_HOSTNAME}/collectives/dashboard`
      }

      return `${ROOT_HOSTNAME}/events`
    },
    [subdomain]
  )

  const {
    email,
    password,
    setEmail,
    setPassword,
    errorMessage: passwordError,
    loadingPassword,
    login
  } = usePasswordLogin(
    onRedirect,
    successCallback,
    defaultEmail,
    defaultPassword
  )

  const {
    loading: loadingApple,
    appleError,
    AppleButton
  } = useAppleLogin({
    onRedirect,
    successCallback,
    disabled: walletLogginIn || loadingPassword
  })

  const canContinue =
    !!(email.trim() && password) &&
    !loadingPassword &&
    !walletLogginIn &&
    !loadingApple

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (!canContinue) {
        return
      }

      await login()
    },
    [login, canContinue]
  )

  return (
    <div className="max-w-md w-full space-y-8 pt-4 mt-5 pb-6 px-6 bg-white rounded-lg shadow">
      <div className="relative">
        <Image
          className="mx-auto mt-3 h-8 w-auto"
          src="/static/new-logo-dark-horizontal@2x.png"
          alt="Upstream"
          width={121}
          height={32}
        />
        <h2 className="mt-6 text-center text-xl font-bold text-gray-900">
          Log in to your account
        </h2>
      </div>

      <div className="pt-2">
        <TrackableClick
          event={`Wallet Login Button Clicked`}
          props={
            collective
              ? {
                  collectiveId: collective.id,
                  collectiveName: collective.name
                }
              : {}
          }
        >
          <Button
            label="Connect Web3 Wallet"
            icon={faEthereum}
            type="button"
            color={'white'}
            disabled={loadingApple || loadingPassword || walletLogginIn}
            onClick={signInWithWallet}
            loading={walletLogginIn}
            className="border border-gray-300 rounded-md w-full py-3 mb-6 text-gray-500"
          />
        </TrackableClick>

        <AppleButton classnames="mb-6 py-2.5" fullWidth />

        {appleError && (
          <div className="-mt-2 mb-6 flex w-full justify-center">
            <Typography color="error" size="sm" component={'span'}>
              {appleError}
            </Typography>
          </div>
        )}

        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-400">or</span>
          </div>
        </div>
      </div>

      <form className="mt-8 space-y-6" onSubmit={onSubmit}>
        <input type="hidden" name="remember" value="true" />

        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="email-address" className="sr-only">
              Email or Username
            </label>
            <input
              required
              autoFocus
              autoComplete="email"
              className="appearance-none relative block w-full px-3 py-3 border font-thin border-gray-300 placeholder-gray-500 text-gray-900 rounded-md mb-5 focus:outline-none focus:ring-blue-500 focus:border-blue focus:z-10 sm:text-sm"
              placeholder="Email or Username"
              value={email}
              onChange={setEmail}
              disabled={loadingPassword || loadingApple || walletLogginIn}
            />
          </div>

          <div>
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              type="password"
              autoComplete="current-password"
              required
              className="appearance-none relative block w-full px-3 py-3 border font-thin border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue focus:z-10 sm:text-sm"
              placeholder="Password"
              value={password}
              onChange={setPassword}
              disabled={loadingPassword || loadingApple || walletLogginIn}
            />
          </div>
        </div>

        {!!passwordError && (
          <p className="text-red-500 text-center font-medium transition">
            {passwordError}
          </p>
        )}

        <Button
          type="submit"
          label="Log in"
          color="blue"
          className="w-full"
          size="lg"
          loading={loadingPassword}
          disabled={!canContinue}
          icon={faLock}
        />

        <Link
          href={{
            pathname: `/resetPassword/recoverPassword`,
            query: {
              email
            }
          }}
        >
          <Button
            size="md"
            color="textOnly"
            className="w-full"
            label="Forgot password?"
          />
        </Link>
      </form>
    </div>
  )
}

export default Login
