import { twMerge } from 'tailwind-merge'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React, {
  ButtonHTMLAttributes,
  ForwardedRef,
  forwardRef,
  useCallback
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons'

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string
  fullWidth?: boolean
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  rounded?: 'xs' | 'sm' | 'md' | 'lg' | 'full'
  icon?: IconProp
  color?:
    | 'blue'
    | 'lightblue'
    | 'darkblue'
    | 'white'
    | 'gray'
    | 'lightgray'
    | 'darkgray'
    | 'red'
    | 'green'
    | 'transparent'
    | 'textOnly'
    | 'link'
    | 'outline'
  as?: 'button' | 'a'
  target?: string
  href?: string
  loading?: boolean
  preventDefault?: boolean
  showFocusRing?: boolean
  labelClassName?: string
  iconClassName?: string
  iconPlacement?: 'before' | 'after'
}

function Button(
  {
    icon,
    label,
    onClick,
    href,
    className,
    iconClassName,
    labelClassName,
    target,
    disabled = false,
    type = 'button',
    size = 'md',
    rounded = 'md',
    color = 'blue',
    as: Tag = 'button',
    loading = false,
    preventDefault = false,
    showFocusRing = true,
    iconPlacement = 'before',
    fullWidth,
    children,
    ...props
  }: IProps,
  ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>
) {
  const onClickProxy = useCallback(
    evt => {
      if (preventDefault) {
        evt.preventDefault()
      }

      onClick?.(evt)
    },
    [preventDefault, onClick]
  )

  const isBefore = iconPlacement === 'before'
  return (
    <Tag
      type={Tag === 'button' ? type : undefined}
      className={twMerge(
        'inline-flex items-center justify-center shadow-sm focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed',
        color === 'blue' &&
          'text-white bg-blue hover:bg-blue-700 border border-transparent',
        color === 'lightblue' &&
          'text-blue-700 bg-blue-100 hover:bg-blue-200 border border-transparent',
        color === 'darkblue' &&
          'text-white  bg-light-blue-700 hover:bg-light-blue-800 border border-transparent',
        color === 'white' &&
          'text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 shadow-sm',
        color === 'gray' &&
          'text-black bg-gray-200 hover:bg-gray-300 border border-transparent',
        color === 'lightgray' &&
          'text-gray-600 bg-gray-100 hover:bg-gray-200 border border-gray-300',
        color === 'darkgray' &&
          'text-black bg-gray-300 hover:bg-gray-400 border border-transparent',
        color === 'red' &&
          'text-white bg-red-600 hover:bg-red-700 border border-transparent',
        color === 'green' &&
          'text-white bg-green-500 hover:bg-green-600 border border-transparent',
        color === 'transparent' &&
          'text-blue bg-transparent hover:text-white hover:bg-blue border-2 border-blue',
        color === 'textOnly' &&
          'text-gray-500 bg-transparent hover:text-black shadow-none',
        color === 'outline' &&
          'text-gray-500 bg-transparent  hover:bg-gray-100 shadow-none border border-gray-300',
        color === 'link' &&
          'text-blue bg-transparent hover:text-black shadow-none',
        size === 'xxs' && 'px-1.5 py-0.5 text-xs font-medium',
        size === 'xs' && 'px-2.5 py-1.5 text-xs font-medium',
        size === 'sm' && 'px-3 py-2 text-sm leading-4 font-medium',
        size === 'md' && 'px-4 py-2 text-sm font-medium',
        size === 'lg' && 'px-4 py-2 text-base font-medium',
        size === 'xl' && 'px-6 py-4 text-lg font-medium',
        fullWidth && 'w-full',
        rounded === 'xs' && 'rounded',
        rounded === 'sm' && 'rounded-sm',
        rounded === 'md' && 'rounded-md',
        rounded === 'lg' && 'rounded-lg',
        rounded === 'full' && 'rounded-full',
        disabled && 'opacity-50 cursor-not-allowed',
        showFocusRing && 'focus:ring-2 focus:ring-offset-2 focus:ring-blue',
        className
      )}
      // @ts-ignore
      ref={ref}
      // @ts-ignore
      onClick={onClickProxy}
      href={href}
      disabled={disabled}
      target={target}
      {...props}
    >
      {loading && isBefore && (
        <FontAwesomeIcon spin icon={faSpinner} className={iconClassName} />
      )}
      {!loading && icon && isBefore && (
        <FontAwesomeIcon icon={icon} className={iconClassName} />
      )}

      {(!!label || !!children) && (
        <span
          className={twMerge(
            labelClassName,
            icon || loading ? (isBefore ? 'ml-3' : 'mr-3') : ''
          )}
        >
          {label}
          {children}
        </span>
      )}

      {loading && !isBefore && (
        <FontAwesomeIcon spin icon={faSpinner} className={iconClassName} />
      )}

      {!loading && icon && !isBefore && (
        <FontAwesomeIcon icon={icon} className={iconClassName} />
      )}
    </Tag>
  )
}

export default forwardRef<HTMLButtonElement, IProps>(Button)
