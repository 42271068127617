import { useAuthPromptHandler } from 'components/modals/AuthPromptModal'
import { useEmailVerificationHandler } from 'components/modals/EmailVerificationModal'
import { useCallback } from 'react'

function useGatedCallback(
  authPromptTitle?: string,
  authPromptBody?: string,
  authPromptUrl?: string
) {
  const showAuthPromptModal = useAuthPromptHandler(
    authPromptTitle,
    authPromptBody,
    authPromptUrl
  )
  const requireEmailVerification = useEmailVerificationHandler()
  return useCallback(
    (callback: Callback<any>) => {
      return () => showAuthPromptModal(() => requireEmailVerification(callback))
    },
    [showAuthPromptModal, requireEmailVerification]
  )
}

export default useGatedCallback
