/* eslint-disable */
import gql from 'graphql-tag'
export const BaseProfileFields = gql`
  fragment BaseProfileFields on User {
    id
    publicUrl
    thumb
    shortDisplayName
    displayName
    blocked
    userType
    isBot @client
  }
`
export const EventSeriesFields = gql`
  fragment EventSeriesFields on EventSeries {
    id
    slug
    label
  }
`
export const EventFields = gql`
  fragment EventFields on Event {
    id
    thumb
    title
    description
    format
    formatLabel
    formatDescription
    capacity
    rsvp
    waitlisted
    passive
    actualStartTime
    startTime
    counts {
      attending
      notAttending
      maybeAttending
      attended
      matched
      passive
    }
    speakers {
      ...BaseProfileFields
    }
    user {
      ...BaseProfileFields
    }
    state
    createdAt
    deleted
    publicUrl
    canJoinVideo
    canJoinNotice
    isOwner @client
    isPrivileged @client
    isCurrentlySpeaking @client
    canEdit @client
    introductionTime
    location
    geolocation {
      latitude
      longitude
    }
    discoverable
    collective {
      id
      name
      thumb
      publicUrl
      membership {
        id
        approved
        privileged
      }
    }
    shareable
    series {
      ...EventSeriesFields
    }
    recording
  }
  ${BaseProfileFields}
  ${EventSeriesFields}
`
export const EngagementFields = gql`
  fragment EngagementFields on Engagement {
    __typename
    ... on Event {
      ...EventFields
    }
  }
  ${EventFields}
`
export const ChannelFields = gql`
  fragment ChannelFields on EventVideoChannel {
    provider
    sessionId
    token
  }
`
export const EventUserPlanFields = gql`
  fragment EventUserPlanFields on EventUserPlan {
    currentSpeakers {
      ...BaseProfileFields
    }
    currentLayout
    mainChannel {
      ...ChannelFields
    }
    lobbyChannel {
      ...ChannelFields
    }
    currentChannel {
      ...ChannelFields
    }
    fetchedAt
    nextRefecthAt
  }
  ${BaseProfileFields}
  ${ChannelFields}
`
export const PageInfoFields = gql`
  fragment PageInfoFields on PageInfo {
    hasPreviousPage
    hasNextPage
    startCursor
    endCursor
  }
`
export const ProfileFields = gql`
  fragment ProfileFields on User {
    id
    publicUrl
    thumb
    shortDisplayName
    displayName
    url
    twitter
    linkedin
    instagram
    createdAt
    blocked
    deleted
    userType
    isBot @client
  }
`
export const TagFields = gql`
  fragment TagFields on Tag {
    label
  }
`
export const UserAssetFields = gql`
  fragment UserAssetFields on UserAssetType {
    id
    contractName
    contractType
    estimatedValueEth
    symbol
    address
    details {
      ... on UserERC20Asset {
        decimals
        balance
        thumbnail
      }
      ... on UserNFTAsset {
        tokenId
        url
        amount
        mediaUrls
        name
        nftImageUrl
      }
    }
  }
`
export const ViewerFields = gql`
  fragment ViewerFields on Viewer {
    id
    thumb
    username
    appleIdConnected
    shortDisplayName
    displayName
    url
    twitter
    linkedin
    instagram
    createdAt
    deleted
    emailVerified
    email
    collectivesActionablesCount
    jwtToken
    possiblySpam
    streamChatToken
    preferences {
      key
      value
    }
    userType
    collectiveUser {
      id
      address
      collectives {
        id
      }
    }
    hasCollectives
    canCreateDiscoverableEvents
    syncStatus
    syncStatusDate
  }
`
export const CollectiveMemberFields = gql`
  fragment CollectiveMemberFields on CollectiveMember {
    id
    user {
      id
      address
      platformUser {
        ...BaseProfileFields
      }
    }
    totalTokens
    percentOfTotalTokenSupply
    privileged
    approved
    bannedFromEvents
    createdAt
  }
  ${BaseProfileFields}
`
export const CollectiveProposalVoteBaseFieldsFragment = gql`
  fragment CollectiveProposalVoteBaseFieldsFragment on CollectiveProposalVote {
    id
    type
    weight
    surveyAnswers {
      choices {
        dateChoice
        stringChoice
      }
    }
    signatureUrl
    signature
    member {
      ...CollectiveMemberFields
    }
  }
  ${CollectiveMemberFields}
`
export const CollectiveProposalVoteFieldsFragment = gql`
  fragment CollectiveProposalVoteFieldsFragment on CollectiveProposalVote {
    ...CollectiveProposalVoteBaseFieldsFragment
    masterVote {
      ...CollectiveProposalVoteBaseFieldsFragment
    }
  }
  ${CollectiveProposalVoteBaseFieldsFragment}
`
export const CastVoteMutationResponseFields = gql`
  fragment CastVoteMutationResponseFields on CastVoteMutationResponse {
    votes {
      ...CollectiveProposalVoteFieldsFragment
    }
    delegationFailures {
      message
      collectiveMember {
        ...CollectiveMemberFields
      }
    }
  }
  ${CollectiveProposalVoteFieldsFragment}
  ${CollectiveMemberFields}
`
export const CollectiveAnnouncementFields = gql`
  fragment CollectiveAnnouncementFields on CollectiveAnnouncement {
    id
    bodyHtml
    postedBy {
      ...CollectiveMemberFields
    }
    createdAt
  }
  ${CollectiveMemberFields}
`
export const CollectiveAssetFieldsFragment = gql`
  fragment CollectiveAssetFieldsFragment on CollectiveAsset {
    __typename
    ... on ERCAsset {
      id
      tokenId
      name
      symbol
      address
      balance
      decimals
      thumb
      contractType
      contractName
      mediaUrls
      externalUrl
      lastSynced
      estimatedValueEth
      estimatedValueUSD
      wallet {
        id
        isMainWallet
      }
    }
    ... on ExternalAsset {
      id
      type
      address
      thumb
      externalUrl
      symbol
      formattedAmount
      lastSynced
      estimatedValueUSD
      wallet {
        id
        isMainWallet
      }
    }
  }
`
export const CollectiveDraftFields = gql`
  fragment CollectiveDraftFields on CollectiveDraft {
    id
    subdomain
    avatar
    externalTokenAddress
    name
    intent
    description
    quorumNeededInPercentage
    proposalThreshold
    depositFeeInPercentage
    withdrawFeeInPercentage
    enableNewMembershipRequests
    enableDeposits
    enableWithdraws
    gateDeposits
    exchangeRate
    minEthContribution
    disableTokenTransfers
    requireVotingSignature
    votingAnonymity
    firstName
    lastName
    email
    symbol
    customerSupport
    members
    createdAt
    lastStep
    jumpableSteps
  }
`
export const MinimalUser = gql`
  fragment MinimalUser on User {
    id
    thumb
    shortDisplayName
    displayName
    userType
    isBot @client
  }
`
export const MinimalCollectiveUser = gql`
  fragment MinimalCollectiveUser on CollectiveUser {
    id
    address
    platformUser {
      ...MinimalUser
    }
  }
  ${MinimalUser}
`
export const MinimalCollectiveMember = gql`
  fragment MinimalCollectiveMember on CollectiveMember {
    id
    user {
      ...MinimalCollectiveUser
    }
  }
  ${MinimalCollectiveUser}
`
export const MinimalCollectiveProposalBase = gql`
  fragment MinimalCollectiveProposalBase on CollectiveProposal {
    id
    status
    title
    description
    type
    batchedProposals {
      id
    }
    survey {
      questions {
        question
      }
      totalQuestions
    }
    proposedBy {
      ...MinimalCollectiveMember
    }
    isShortForm @client
    collective {
      id
      name
    }
    publicUrl
  }
  ${MinimalCollectiveMember}
`
export const MinimalCollectiveProposal = gql`
  fragment MinimalCollectiveProposal on CollectiveProposal {
    ...MinimalCollectiveProposalBase
    eligibleDelegations {
      ...MinimalCollectiveMember
    }
  }
  ${MinimalCollectiveProposalBase}
  ${MinimalCollectiveMember}
`
export const MinimalCollectiveProposalVoteBase = gql`
  fragment MinimalCollectiveProposalVoteBase on CollectiveProposalVote {
    id
    type
    weight
    surveyAnswers {
      choices {
        dateChoice
        stringChoice
      }
    }
    member {
      ...MinimalCollectiveMember
    }
  }
  ${MinimalCollectiveMember}
`
export const MinimalCollectiveProposalVote = gql`
  fragment MinimalCollectiveProposalVote on CollectiveProposalVote {
    ...MinimalCollectiveProposalVoteBase
    masterVote {
      ...MinimalCollectiveProposalVoteBase
    }
  }
  ${MinimalCollectiveProposalVoteBase}
`
export const CollectiveLogFieldsFragment = gql`
  fragment CollectiveLogFieldsFragment on CollectiveLog {
    __typename
    id
    eventType
    txHash
    blockNumber
    date
    collectiveUser {
      ...MinimalCollectiveUser
    }
    data {
      ... on CollectiveLogDataOnProposalCanceled {
        proposal {
          ...MinimalCollectiveProposal
        }
        reason
      }
      ... on CollectiveLogDataOnProposalExecuted {
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnProposalExecutionReset {
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnProposalSigned {
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnProposalsBatched {
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnFundsDeposited {
        etherAmount
      }
      ... on CollectiveLogDataOnFundsDeposited {
        etherAmount
      }
      ... on CollectiveLogDataOnCollectiveMembershipProposed {
        proposal {
          ...MinimalCollectiveProposal
        }
        request {
          id
          user {
            ...MinimalCollectiveUser
          }
          status
          createdAt
        }
      }
      ... on CollectiveLogDataOnMemberDepositedFunds {
        etherAmount
        tokenAmount
      }
      ... on CollectiveLogDataOnMemberWithdrewFunds {
        etherAmount
      }
      ... on CollectiveLogDataOnMemberAdded {
        targetMember
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnMemberRemoved {
        targetMember
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnGateDepositsChanged {
        gateDeposits
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnEnableWithdrawsChanged {
        enableWithdraws
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnDisableTokenTransfersChanged {
        disableTokenTransfers
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnEnableDepositsChanged {
        enableDeposits
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnEnableNewMembershipRequestsChanged {
        enableNewMembershipRequests
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnQuorumNeededInPercentageChanged {
        quorumNeededInPercentage
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnExchangeRateChanged {
        previousRate
        newRate
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnFeesChanged {
        depositFee
      }
      ... on CollectiveLogDataOnVoteCast {
        vote {
          ...MinimalCollectiveProposalVote
        }
        user {
          ...MinimalCollectiveMember
        }
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnVoteInvalidated {
        invalidatedVote {
          ...MinimalCollectiveProposalVote
        }
        user {
          ...MinimalCollectiveMember
        }
        proposal {
          ...MinimalCollectiveProposal
        }
        oldWeight
      }
      ... on CollectiveLogDataOnVoteWeightUpdated {
        updatedVote {
          ...MinimalCollectiveProposalVote
        }
        user {
          ...MinimalCollectiveMember
        }
        proposal {
          ...MinimalCollectiveProposal
        }
        oldWeight
        newWeight
      }
      ... on CollectiveLogDataOnVoteChanged {
        previousVote {
          ...MinimalCollectiveProposalVote
        }
        newVote {
          ...MinimalCollectiveProposalVote
        }
        user {
          ...MinimalCollectiveMember
        }
        proposal {
          ...MinimalCollectiveProposal
        }
        masterVote {
          ...MinimalCollectiveProposalVote
        }
      }
      ... on CollectiveLogDataOnNonDepositEtherReceived {
        etherAmount
        sender
      }
      ... on CollectiveLogDataOnTransfer {
        value
        tokenId
        token {
          id
          address
          symbol
          decimals
          thumb
          name
          contractType
        }
        from
        to
      }
      ... on CollectiveLogDataOnTransferSingle {
        value
        tokenId
        token {
          id
          address
          symbol
          decimals
          thumb
          name
          contractType
        }
        from
        to
      }
      ... on CollectiveLogDataOnTransferBatch {
        values
        tokenIds
        token {
          id
          address
          symbol
          decimals
          thumb
          name
          contractType
        }
        from
        to
      }
      ... on CollectiveLogDataOnDelegationsGranted {
        delegator {
          ...MinimalCollectiveUser
        }
        delegations {
          delegatee {
            ...MinimalCollectiveUser
          }
          command
          maxValue
          wildcard
          surveys
        }
      }
      ... on CollectiveLogDataOnDelegationsRevoked {
        delegator {
          ...MinimalCollectiveUser
        }
        delegations {
          delegatee {
            ...MinimalCollectiveUser
          }
          command
          maxValue
          wildcard
          surveys
        }
      }
      ... on CollectiveLogDataOnChangedThreshold {
        threshold
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnAddedOwner {
        owner
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnRemovedOwner {
        owner
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnWalletUnlinked {
        walletId
      }
      ... on CollectiveLogDataOnMinEthContributionChanged {
        contributionAmount
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnWalletLinked {
        address
        blockchain
        nickname
      }
      ... on CollectiveLogDataOnAnnouncementCreated {
        announcement {
          bodyHtml
        }
      }
      ... on CollectiveLogDataOnAnnouncementChanged {
        announcement {
          bodyHtml
        }
      }
      ... on CollectiveLogDataOnAnnouncementDeleted {
        announcement {
          bodyHtml
        }
      }
      ... on CollectiveLogDataOnProposalRequireVoteSignatureChanged {
        requireVotingSignature
        proposal {
          ...MinimalCollectiveProposal
        }
      }
      ... on CollectiveLogDataOnVotingAnonymityChanged {
        votingAnonymity
        proposal {
          ...MinimalCollectiveProposal
        }
      }
    }
  }
  ${MinimalCollectiveUser}
  ${MinimalCollectiveProposal}
  ${MinimalCollectiveProposalVote}
  ${MinimalCollectiveMember}
`
export const BundledCollectiveLogFieldsFragment = gql`
  fragment BundledCollectiveLogFieldsFragment on BundledCollectiveLog {
    date
    eventType
    logs {
      ...CollectiveLogFieldsFragment
    }
    collectiveUsers {
      ...MinimalCollectiveUser
    }
  }
  ${CollectiveLogFieldsFragment}
  ${MinimalCollectiveUser}
`
export const SurveyFieldsFragment = gql`
  fragment SurveyFieldsFragment on Survey {
    questions {
      question
      questionType
      options {
        required
        dateOptions {
          min
          max
          includeTime
        }
      }
      choices {
        __typename
        stringChoice
      }
    }
    myAnswers {
      choices {
        dateChoice
        stringChoice
      }
    }
    answeredOn
    surveyResults {
      __typename
      ... on ChoiceBasedResults {
        labels
        dataset
        datasetPercent
        totalAnswers
      }
    }
    options {
      showResultsAfter
    }
    totalQuestions
    totalAnswers
    isAnswered
    proposal {
      id
    }
  }
`
export const CollectiveProposalFieldsBase = gql`
  fragment CollectiveProposalFieldsBase on CollectiveProposal {
    id
    status
    statusHistory {
      status
      createdAt
    }
    execution {
      txHash
      blockNumber
      status
      errorMessage
    }
    title
    descriptionHtml
    commands {
      __typename
      ... on CollectiveProposalUpdateQuorumCommand {
        quorumNeededInPercentage
        safeInstructions
      }
      ... on CollectiveProposalSendEtherCommand {
        target
        value
        safeInstructions
      }
      ... on CollectiveProposalSendEtherDisbursementCommand {
        target
        value
        safeInstructions
      }
      ... on CollectiveProposalSendEtherDisbursementCommands {
        commands {
          target
          value
          safeInstructions
        }
        value
      }
      ... on CollectiveProposalSendTokenCommand {
        target
        value
        safeInstructions
      }
      ... on CollectiveProposalTransferTokensCommand {
        contractAddress
        target
        value
        asset {
          name
          symbol
          thumb
        }
        safeInstructions
      }
      ... on CollectiveProposalTransferNFTCommand {
        contractAddress
        target
        tokenId
        asset {
          name
          symbol
          thumb
        }
        safeInstructions
      }
      ... on CollectiveProposalTransferERC721Command {
        contractAddress
        target
        tokenId
        asset {
          name
          symbol
          thumb
        }
        safeInstructions
      }
      ... on CollectiveProposalTransferERC1155Command {
        contractAddress
        target
        tokenId
        amount
        asset {
          name
          symbol
          thumb
        }
        safeInstructions
      }
      ... on CollectiveProposalChangeExchangeRateCommand {
        exchangeRate
        safeInstructions
      }
      ... on CollectiveProposalChangeEnableWithdrawsCommand {
        enableWithdraws
        safeInstructions
      }
      ... on CollectiveProposalChangeDisableTokenTransfersCommand {
        disableTokenTransfers
        safeInstructions
      }
      ... on CollectiveProposalChangeEnableDepositsCommand {
        enableDeposits
        safeInstructions
      }
      ... on CollectiveProposalChangeGateDepositsCommand {
        gateDeposits
        safeInstructions
      }
      ... on CollectiveProposalAddMemberCommand {
        memberAddress
        safeInstructions
      }
      ... on CollectiveProposalRemoveMemberCommand {
        memberAddress
        safeInstructions
      }
      ... on CollectiveProposalAddSignatorCommand {
        signatorAddress
        safeInstructions
      }
      ... on CollectiveProposalRemoveSignatorCommand {
        signatorAddress
        safeInstructions
      }
      ... on CollectiveProposalChangeSafeThresholdCommand {
        safeThreshold
        safeInstructions
      }
      ... on CollectiveProposalChangeEnableNewMembershipRequests {
        enableNewMembershipRequests
        safeInstructions
      }
      ... on CollectiveProposalChangeRequireVotingSignatureCommand {
        requireVotingSignature
        safeInstructions
      }
      ... on CollectiveProposalChangeVotingAnonymityCommand {
        votingAnonymity
        safeInstructions
      }
      ... on CollectiveProposalCallRemoteCommand {
        target
        data
        value
        safeInstructions
      }
      ... on CollectiveProposalLinkWalletCommand {
        target
        blockchain
        walletNickname
        safeInstructions
      }
      ... on CollectiveProposalUnlinkWalletCommand {
        walletId
        safeInstructions
      }
      ... on CollectiveProposalChangeMinEthContributionCommand {
        minEthContribution
        safeInstructions
      }
    }
    type
    survey {
      ...SurveyFieldsFragment
    }
    origin {
      __typename
      ... on CollectiveMembershipRequest {
        id
        user {
          id
          address
          platformUser {
            ...BaseProfileFields
          }
        }
        notes
      }
    }
    startTime
    endTime
    requireVotingSignature
    votingAnonymity
    quorumNeeded
    isQuorumReached
    myVote
    isAuthor @client
    isUsingMatchingWallet @client
    isVotable @client
    isShortForm @client
    canVote @client
    totalVoters
    totalVotes
    totalVotersFor
    votesFor
    totalVotersAgainst
    votesAgainst
    totalVotersAbstain
    votesAbstain
    signatures {
      id
      signature
      valid
      user {
        id
        address
        platformUser {
          ...BaseProfileFields
        }
      }
      createdAt
    }
    proposedBy {
      ...CollectiveMemberFields
    }
    ogImageUrl
    isBatch
    isPartOfBatch
    batchProposal {
      id
      title
      publicUrl
    }
    batchedProposals {
      id
      title
      publicUrl
    }
    collective {
      id
      name
    }
    publicUrl
    createdAt
    hasOnChainCommands
    thread {
      id
      replyCount
      title
    }
  }
  ${SurveyFieldsFragment}
  ${BaseProfileFields}
  ${CollectiveMemberFields}
`
export const CollectiveProposalFields = gql`
  fragment CollectiveProposalFields on CollectiveProposal {
    ...CollectiveProposalFieldsBase
    eligibleDelegations {
      ...CollectiveMemberFields
    }
  }
  ${CollectiveProposalFieldsBase}
  ${CollectiveMemberFields}
`
export const CollectiveUserFields = gql`
  fragment CollectiveUserFields on CollectiveUser {
    id
    address
    platformUser {
      ...BaseProfileFields
    }
  }
  ${BaseProfileFields}
`
export const CommentFields = gql`
  fragment CommentFields on Comment {
    id
    body
    replyCount
    user {
      ...BaseProfileFields
    }
    attachments {
      id
      url
      type
      name
    }
    createdAt
    updatedAt
    deletedAt
  }
  ${BaseProfileFields}
`
export const CategoryFields = gql`
  fragment CategoryFields on Category {
    id
    name
    description
    order
    removable
    governance
    archived
    notificationSettings {
      id
      allowNotifications
    }
    totalThreads
    createdAt
    updatedAt
    deletedAt
    publicUrl
    discussionBoard {
      id
    }
    notificationSettings {
      allowNotifications
    }
  }
`
export const DiscussionBoardFields = gql`
  fragment DiscussionBoardFields on DiscussionBoard {
    id
    categories {
      ...CategoryFields
    }
    notificationSettings {
      allowGlobalNotifications
    }
  }
  ${CategoryFields}
`
export const CollectiveProposalSummaryFields = gql`
  fragment CollectiveProposalSummaryFields on CollectiveProposal {
    id
    type
    title
    status
    execution {
      txHash
      blockNumber
      status
      errorMessage
    }
    totalVoters
    totalVotes
    votesFor
    votesAgainst
    requireVotingSignature
    votingAnonymity
    votesAbstain
    isBatch
    isPartOfBatch
    publicUrl
    myVote
    quorumNeeded
    isQuorumReached
    isAuthor @client
    isUsingMatchingWallet @client
    isVotable @client
    isShortForm @client
    canVote @client
    survey {
      ...SurveyFieldsFragment
    }
    startTime
    endTime
    createdAt
    collective {
      id
    }
    proposedBy {
      id
      user {
        id
        address
        platformUser {
          ...BaseProfileFields
        }
      }
    }
    thread {
      id
      replyCount
      title
    }
  }
  ${SurveyFieldsFragment}
  ${BaseProfileFields}
`
export const ThreadFields = gql`
  fragment ThreadFields on Thread {
    id
    title
    body
    type
    replyCount
    viewsCount
    publicUrl
    isPinned
    pinnedBy {
      ...BaseProfileFields
    }
    user {
      ...BaseProfileFields
    }
    proposal {
      ...CollectiveProposalSummaryFields
    }
    notificationSettings {
      id
      allowNotifications
    }
    attachments {
      id
      url
      type
      name
    }
    category {
      id
    }
    createdAt
    updatedAt
    deletedAt
    collaboratorsCount
    notificationSettings {
      allowNotifications
    }
  }
  ${BaseProfileFields}
  ${CollectiveProposalSummaryFields}
`
export const GenericMutationResponseFields = gql`
  fragment GenericMutationResponseFields on GenericMutationResponse {
    success
    code
    message
  }
`
export const ChangePasswordMutation = gql`
  mutation ChangePasswordMutation($password: String!) {
    changePassword(password: $password) {
      success
    }
  }
`
export const LoginCryptoMutation = gql`
  mutation LoginCryptoMutation($login: CryptoWalletAuth!) {
    loginCrypto(login: $login) {
      success
      jwtToken
    }
  }
`
export const LoginMutation = gql`
  mutation LoginMutation($login: LoginInput!) {
    login(login: $login) {
      success
      jwtToken
    }
  }
`
export const LoginWithAppleIdMutation = gql`
  mutation LoginWithAppleIdMutation($login: LoginWithAppleIdInput!) {
    loginWithAppleId(login: $login) {
      success
      jwtToken
    }
  }
`
export const PrioritizeUserForAssetSyncMutation = gql`
  mutation PrioritizeUserForAssetSyncMutation($id: ID!) {
    prioritizeUserForAssetSync(id: $id) {
      id
    }
  }
`
export const RecoverPasswordMutation = gql`
  mutation RecoverPasswordMutation($email: String!) {
    recoverPassword(email: $email) {
      success
      message
    }
  }
`
export const ReportContentMutation = gql`
  mutation ReportContentMutation($report: ReportContentInput!) {
    reportContent(report: $report) {
      id
      success
      message
    }
  }
`
export const ResendVerificationEmailMutation = gql`
  mutation ResendVerificationEmailMutation {
    resendVerificationEmail {
      success
      message
    }
  }
`
export const ResetPasswordMutation = gql`
  mutation ResetPasswordMutation($reset: ResetPasswordInput!) {
    resetPassword(reset: $reset) {
      success
      jwtToken
    }
  }
`
export const SignupWithAppleIdMutation = gql`
  mutation SignupWithAppleIdMutation($signup: SignupWithAppleIdInput!) {
    signupWithAppleId(signup: $signup) {
      success
      jwtToken
    }
  }
`
export const SetPreferenceMutation = gql`
  mutation SetPreferenceMutation($preference: SetPreferenceInput!) {
    setPreference(preference: $preference) {
      key
      value
    }
  }
`
export const SignupMutation = gql`
  mutation SignupMutation($signup: SignupInput!) {
    signup(signup: $signup) {
      success
      jwtToken
    }
  }
`
export const StartEventMutation = gql`
  mutation StartEventMutation($eventId: ID!) {
    startEvent(eventId: $eventId) {
      ...EventFields
    }
  }
  ${EventFields}
`
export const UpdateUserMutation = gql`
  mutation UpdateUserMutation($update: UpdateUserInput!) {
    updateUser(update: $update) {
      id
      email
      emailVerified
      username
      name
      shortDisplayName
      displayName
      url
      twitter
      linkedin
      instagram
    }
  }
`
export const UploadAvatarMutation = gql`
  mutation UploadAvatarMutation($file: Upload!) {
    uploadAvatar(file: $file) {
      id
      thumb
    }
  }
`
export const UploadAvatarURLMutation = gql`
  mutation UploadAvatarURLMutation($avatarURL: String!) {
    uploadAvatarURL(avatarURL: $avatarURL) {
      id
      thumb
    }
  }
`
export const VerifyEmailMutation = gql`
  mutation VerifyEmailMutation($emailVerificationKey: String!) {
    verifyEmail(emailVerificationKey: $emailVerificationKey) {
      id
      emailVerified
    }
  }
`
export const SubmitCollectiveApplicationMutation = gql`
  mutation SubmitCollectiveApplicationMutation($payload: JSON!) {
    submitCollectiveApplication(payload: $payload) {
      id
      thumb
      payload
      status
      createdAt
    }
  }
`
export const UpdateCollectiveApplicationMutation = gql`
  mutation UpdateCollectiveApplicationMutation(
    $applicationId: ID!
    $payload: JSON!
  ) {
    updateCollectiveApplication(
      applicationId: $applicationId
      payload: $payload
    ) {
      id
      thumb
      payload
      status
      createdAt
    }
  }
`
export const UploadCollectiveApplicationAvatarMutation = gql`
  mutation UploadCollectiveApplicationAvatarMutation(
    $applicationId: ID!
    $file: Upload!
  ) {
    uploadCollectiveApplicationAvatar(
      applicationId: $applicationId
      file: $file
    ) {
      id
      thumb
    }
  }
`
export const CreateCollectiveDraftMutation = gql`
  mutation CreateCollectiveDraftMutation($input: CollectiveDraftInput!) {
    createCollectiveDraft(input: $input) {
      ...CollectiveDraftFields
    }
  }
  ${CollectiveDraftFields}
`
export const deleteCollectiveDraftMutation = gql`
  mutation deleteCollectiveDraftMutation($collectiveDraftId: ID!) {
    deleteCollectiveDraft(collectiveDraftId: $collectiveDraftId) {
      ...CollectiveDraftFields
    }
  }
  ${CollectiveDraftFields}
`
export const UpdateCollectiveDraftMutation = gql`
  mutation UpdateCollectiveDraftMutation(
    $collectiveDraftId: ID!
    $input: CollectiveDraftInput!
  ) {
    updateCollectiveDraft(
      collectiveDraftId: $collectiveDraftId
      input: $input
    ) {
      ...CollectiveDraftFields
    }
  }
  ${CollectiveDraftFields}
`
export const PostCollectiveSubmissionLogMutation = gql`
  mutation PostCollectiveSubmissionLogMutation(
    $log: CollectiveSubmissionLogInput!
  ) {
    postCollectiveSubmissionLog(log: $log) {
      success
    }
  }
`
export const AgreeToCollectiveTosMutation = gql`
  mutation AgreeToCollectiveTosMutation($type: TosAgreementType!, $id: ID) {
    agreeToCollectiveTos(type: $type, id: $id) {
      id
      isSigned
    }
  }
`
export const AnswerCollectiveProposalSurveyMutation = gql`
  mutation AnswerCollectiveProposalSurveyMutation($survey: SurveyInput!) {
    answerCollectiveProposalSurvey(survey: $survey) {
      ...CollectiveProposalVoteBaseFieldsFragment
      proposal {
        id
        myVote
        totalVoters
        totalVotes
        totalVotersFor
        votesFor
        survey {
          ...SurveyFieldsFragment
        }
        isVotable @client
        isShortForm @client
        canVote @client
      }
    }
  }
  ${CollectiveProposalVoteBaseFieldsFragment}
  ${SurveyFieldsFragment}
`
export const AuthCryptoMutation = gql`
  mutation AuthCryptoMutation($address: String!, $signature: String!) {
    authCrypto(address: $address, signature: $signature) {
      id
      collectiveUser {
        id
        address
      }
    }
  }
`
export const BanMemberFromEventsMutation = gql`
  mutation BanMemberFromEventsMutation($collectiveId: ID!, $userId: ID!) {
    banMemberFromEvents(collectiveId: $collectiveId, userId: $userId) {
      id
      bannedFromEvents
    }
  }
`
export const BatchProposalsMutation = gql`
  mutation BatchProposalsMutation($proposalIds: [ID!]!) {
    batchProposals(proposalIds: $proposalIds) {
      ...CollectiveProposalFields
    }
  }
  ${CollectiveProposalFields}
`
export const CancelProposalMutation = gql`
  mutation CancelProposalMutation($proposalId: ID!, $reason: String) {
    cancelProposal(proposalId: $proposalId, reason: $reason) {
      ...CollectiveProposalFields
    }
  }
  ${CollectiveProposalFields}
`
export const CastVoteMutation = gql`
  mutation CastVoteMutation($vote: CastVoteInput!) {
    castVote(vote: $vote) {
      id
      type
      weight
      signatureUrl
      proposal {
        id
        myVote
        totalVoters
        totalVotes
        totalVotersFor
        votesFor
        totalVotersAgainst
        votesAgainst
        totalVotersAbstain
        votesAbstain
        isAuthor @client
        isUsingMatchingWallet @client
        isVotable @client
        isShortForm @client
        canVote @client
      }
    }
  }
`
export const CastVoteWithDelegationsMutation = gql`
  mutation CastVoteWithDelegationsMutation($vote: CastVoteInput!) {
    castVoteWithDelegations(vote: $vote) {
      ...CastVoteMutationResponseFields
    }
  }
  ${CastVoteMutationResponseFields}
`
export const CreateCollectiveMutation = gql`
  mutation CreateCollectiveMutation($input: CreateCollectiveInput!) {
    createCollective(input: $input) {
      id
      name
      publicUrl
    }
  }
`
export const CreateMemberFileExportMutation = gql`
  mutation CreateMemberFileExportMutation(
    $collectiveId: ID!
    $where: FileExportInput!
  ) {
    createMemberFileExport(collectiveId: $collectiveId, where: $where) {
      id
      status
      errorMessage
      type
    }
  }
`
export const CreateProposalMutation = gql`
  mutation CreateProposalMutation(
    $collectiveId: ID!
    $proposal: ProposalInput!
  ) {
    createProposal(collectiveId: $collectiveId, proposal: $proposal) {
      ...CollectiveProposalFields
    }
  }
  ${CollectiveProposalFields}
`
export const DeleteAnnouncementMutation = gql`
  mutation DeleteAnnouncementMutation($id: ID!) {
    deleteAnnouncement(id: $id) {
      id
      announcement {
        ...CollectiveAnnouncementFields
      }
    }
  }
  ${CollectiveAnnouncementFields}
`
export const EditAnnouncementMutation = gql`
  mutation EditAnnouncementMutation(
    $announcementId: ID!
    $announcement: PostAnnouncementInput!
  ) {
    editAnnouncement(
      announcementId: $announcementId
      announcement: $announcement
    ) {
      ...CollectiveAnnouncementFields
    }
  }
  ${CollectiveAnnouncementFields}
`
export const ExecProposalMutation = gql`
  mutation ExecProposalMutation(
    $proposalId: ID!
    $txHash: String!
    $blockNumber: Int!
  ) {
    execProposal(
      proposalId: $proposalId
      txHash: $txHash
      blockNumber: $blockNumber
    ) {
      ...CollectiveProposalFields
    }
  }
  ${CollectiveProposalFields}
`
export const JoinExternalCollectiveMutation = gql`
  mutation JoinExternalCollectiveMutation($collectiveId: ID!) {
    joinExternalCollective(collectiveId: $collectiveId) {
      id
      membership {
        id
        totalTokens
        percentOfTotalTokenSupply
        privileged
        approved
      }
    }
  }
`
export const PostAnnouncementMutation = gql`
  mutation PostAnnouncementMutation(
    $collectiveId: ID!
    $announcement: PostAnnouncementInput!
  ) {
    postAnnouncement(collectiveId: $collectiveId, announcement: $announcement) {
      id
      announcement {
        ...CollectiveAnnouncementFields
      }
    }
  }
  ${CollectiveAnnouncementFields}
`
export const ProposeCollectiveMembershipMutation = gql`
  mutation ProposeCollectiveMembershipMutation(
    $requestId: ID!
    $notes: String
  ) {
    proposeCollectiveMembership(requestId: $requestId, notes: $notes) {
      id
      status
      publicUrl
    }
  }
`
export const RequestCollectiveMembershipMutation = gql`
  mutation RequestCollectiveMembershipMutation(
    $collectiveId: ID!
    $notes: String
  ) {
    requestCollectiveMembership(collectiveId: $collectiveId, notes: $notes) {
      id
      status
    }
  }
`
export const ResetExecutionMutation = gql`
  mutation ResetExecutionMutation($proposalId: ID!) {
    resetExecution(proposalId: $proposalId) {
      ...CollectiveProposalFields
    }
  }
  ${CollectiveProposalFields}
`
export const SignProposalMutation = gql`
  mutation SignProposalMutation(
    $proposalId: ID!
    $signature: String!
    $nonce: Int!
    $txHash: String
  ) {
    signProposal(
      proposalId: $proposalId
      signature: $signature
      nonce: $nonce
      txHash: $txHash
    ) {
      ...CollectiveProposalFields
    }
  }
  ${CollectiveProposalFields}
`
export const SyncCollectiveMutation = gql`
  mutation SyncCollectiveMutation(
    $collectiveId: ID!
    $proposalConnection: ConnectionInput!
  ) {
    syncCollective(collectiveId: $collectiveId) {
      id
      address
      safeAddress
      safeThreshold
      network
      name
      description
      parameters {
        votingDelayInMinutes
        votingPeriodInMinutes
        quorumNeededInPercentage
        proposalThreshold
        depositFeeInPercentage
        withdrawFeeInPercentage
        enableNewMembershipRequests
        enableDeposits
        enableWithdraws
        gateDeposits
        exchangeRate
        minEthContribution
        disableTokenTransfers
      }
      archived
      syncStatus {
        syncStartedAt
        lastSyncedAt
        status
      }
      totalProposals
      activeProposals
      proposals(proposalConnection: $proposalConnection)
        @connection(key: "collectiveProposals") {
        edges {
          cursor
          node {
            id
            title
            status
            totalVoters
            totalVotes
            votesFor
            votesAgainst
            votesAbstain
            myVote
            isAuthor @client
            isUsingMatchingWallet @client
            isVotable @client
            isShortForm @client
            canVote @client
            createdAt
            collective {
              id
            }
            proposedBy {
              id
              user {
                id
                address
                platformUser {
                  ...BaseProfileFields
                }
              }
            }
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
      totalMembers
      totalVotingMembers
      treasuryBalance
      governanceType
      tokens {
        id
        isMainToken
        type
        address
        voteWeight
        symbol
        decimals
        totalSupply
        totalVotingSupply
        thumb
        name
      }
      membership {
        id
        totalTokens
        percentOfTotalTokenSupply
        privileged
        approved
      }
      createdAt
    }
  }
  ${BaseProfileFields}
  ${PageInfoFields}
`
export const UnlinkWalletMutation = gql`
  mutation UnlinkWalletMutation($address: String!) {
    unlinkWallet(address: $address) {
      id
      collectiveUser {
        id
        address
      }
    }
  }
`
export const UploadCollectiveAvatarMutation = gql`
  mutation UploadCollectiveAvatarMutation($collectiveId: ID!, $file: Upload!) {
    uploadCollectiveAvatar(collectiveId: $collectiveId, file: $file) {
      id
      thumb
    }
  }
`
export const UploadCollectiveDraftAvatarMutation = gql`
  mutation UploadCollectiveDraftAvatarMutation(
    $file: Upload!
    $collectiveDraftId: ID
  ) {
    uploadCollectiveDraftAvatar(
      file: $file
      collectiveDraftId: $collectiveDraftId
    )
  }
`
export const CreateCategoryMutation = gql`
  mutation CreateCategoryMutation(
    $discussionBoardId: ID!
    $categoryDetails: CategoryInput!
  ) {
    createCategory(
      discussionBoardId: $discussionBoardId
      categoryDetails: $categoryDetails
    ) {
      ...CategoryFields
    }
  }
  ${CategoryFields}
`
export const CreateCommentMutation = gql`
  mutation CreateCommentMutation(
    $threadId: ID!
    $commentDetails: CommentCreateInput!
  ) {
    createComment(threadId: $threadId, commentDetails: $commentDetails) {
      ...CommentFields
    }
  }
  ${CommentFields}
`
export const CreateThreadMutation = gql`
  mutation CreateThreadMutation(
    $categoryId: ID!
    $threadDetails: ThreadCreateInput!
  ) {
    createThread(categoryId: $categoryId, threadDetails: $threadDetails) {
      ...ThreadFields
    }
  }
  ${ThreadFields}
`
export const DeleteCategoryMutation = gql`
  mutation DeleteCategoryMutation($categoryId: ID!) {
    deleteCategory(categoryId: $categoryId) {
      ...GenericMutationResponseFields
    }
  }
  ${GenericMutationResponseFields}
`
export const DeleteThreadMutation = gql`
  mutation DeleteThreadMutation($threadId: ID!) {
    deleteThread(threadId: $threadId) {
      ...GenericMutationResponseFields
    }
  }
  ${GenericMutationResponseFields}
`
export const PinThreadMutation = gql`
  mutation PinThreadMutation($threadId: ID!, $pinned: Boolean!) {
    pinThread(threadId: $threadId, pinned: $pinned) {
      ...ThreadFields
    }
  }
  ${ThreadFields}
`
export const UpdateCategoryMutation = gql`
  mutation UpdateCategoryMutation(
    $categoryId: ID!
    $categoryDetails: CategoryInput!
  ) {
    updateCategory(categoryId: $categoryId, categoryDetails: $categoryDetails) {
      ...CategoryFields
    }
  }
  ${CategoryFields}
`
export const UpdateCommentMutation = gql`
  mutation UpdateCommentMutation(
    $threadId: ID!
    $commentDetails: CommentUpdateInput!
  ) {
    updateComment(threadId: $threadId, commentDetails: $commentDetails) {
      ...CommentFields
    }
  }
  ${CommentFields}
`
export const UpdateDiscussionBoardCategoryNotificationSettingMutation = gql`
  mutation UpdateDiscussionBoardCategoryNotificationSettingMutation(
    $categoryId: ID!
    $allowNotifications: Boolean!
  ) {
    updateDiscussionBoardCategoryNotificationSetting(
      categoryId: $categoryId
      allowNotifications: $allowNotifications
    ) {
      ...DiscussionBoardFields
    }
  }
  ${DiscussionBoardFields}
`
export const UpdateDiscussionBoardNotificationSettingMutation = gql`
  mutation UpdateDiscussionBoardNotificationSettingMutation(
    $discussionBoardId: ID!
    $allowGlobalNotifications: Boolean!
  ) {
    updateDiscussionBoardNotificationSetting(
      discussionBoardId: $discussionBoardId
      allowGlobalNotifications: $allowGlobalNotifications
    ) {
      ...DiscussionBoardFields
    }
  }
  ${DiscussionBoardFields}
`
export const UpdateDiscussionBoardThreadNotificationSettingMutation = gql`
  mutation UpdateDiscussionBoardThreadNotificationSettingMutation(
    $threadId: ID!
    $allowNotifications: Boolean!
  ) {
    updateDiscussionBoardThreadNotificationSetting(
      threadId: $threadId
      allowNotifications: $allowNotifications
    ) {
      ...ThreadFields
    }
  }
  ${ThreadFields}
`
export const UpdateThreadMutation = gql`
  mutation UpdateThreadMutation(
    $threadId: ID!
    $threadDetails: ThreadUpdateInput!
  ) {
    updateThread(threadId: $threadId, threadDetails: $threadDetails) {
      ...ThreadFields
    }
  }
  ${ThreadFields}
`
export const ArchiveCategoryMutation = gql`
  mutation ArchiveCategoryMutation($categoryId: ID!, $archived: Boolean!) {
    archiveCategory(categoryId: $categoryId, archived: $archived) {
      ...CategoryFields
    }
  }
  ${CategoryFields}
`
export const BanAttendeeMutation = gql`
  mutation BanAttendeeMutation($eventId: ID!, $userId: ID!) {
    banAttendee(eventId: $eventId, userId: $userId) {
      id
      banned
    }
  }
`
export const CreateEventMutation = gql`
  mutation CreateEventMutation($event: EventInput!) {
    createEvent(event: $event) {
      ...EventFields
    }
  }
  ${EventFields}
`
export const DeleteEventMutation = gql`
  mutation DeleteEventMutation($eventId: ID!) {
    deleteEvent(eventId: $eventId) {
      ...EventFields
    }
  }
  ${EventFields}
`
export const EditEventAssetMutation = gql`
  mutation EditEventAssetMutation(
    $assetId: ID!
    $caption: String!
    $public: Boolean!
  ) {
    editEventAsset(assetId: $assetId, caption: $caption, public: $public) {
      id
      public
      caption
    }
  }
`
export const EndSpeakerMutation = gql`
  mutation EndSpeakerMutation($eventId: ID!, $userId: ID!) {
    endSpeaker(eventId: $eventId, userId: $userId) {
      success
    }
  }
`
export const LeaveEventMutation = gql`
  mutation LeaveEventMutation($eventId: ID!) {
    leaveEvent(eventId: $eventId) {
      ...EventFields
    }
  }
  ${EventFields}
`
export const RSVPToEventMutation = gql`
  mutation RSVPToEventMutation($eventId: ID!, $rsvp: EventRegistrationRSVP!) {
    rsvpToEvent(eventId: $eventId, rsvp: $rsvp) {
      ...EventFields
    }
  }
  ${EventFields}
`
export const SetAttendeeRoleMutation = gql`
  mutation SetAttendeeRoleMutation(
    $eventId: ID!
    $userId: ID!
    $role: EventRegistrationRole!
  ) {
    setAttendeeRole(eventId: $eventId, userId: $userId, role: $role) {
      id
      role
    }
  }
`
export const SetEventStateMutation = gql`
  mutation SetEventStateMutation($eventId: ID!, $eventState: EventState!) {
    setEventState(eventId: $eventId, eventState: $eventState) {
      ...EventFields
    }
  }
  ${EventFields}
`
export const SetPassiveModeMutation = gql`
  mutation SetPassiveModeMutation($eventId: ID!, $passive: Boolean!) {
    setPassiveMode(eventId: $eventId, passive: $passive) {
      id
      passive
    }
  }
`
export const SetRecordingStateMutation = gql`
  mutation SetRecordingStateMutation(
    $eventId: ID!
    $newRecordingState: Boolean!
  ) {
    setRecordingState(
      eventId: $eventId
      newRecordingState: $newRecordingState
    ) {
      id
      recording
    }
  }
`
export const SetSpeakerMutation = gql`
  mutation SetSpeakerMutation(
    $eventId: ID!
    $userId: ID
    $layout: BroadcastVideoLayout
  ) {
    setSpeaker(eventId: $eventId, userId: $userId, layout: $layout) {
      success
    }
  }
`
export const SignalAttendeeMutation = gql`
  mutation SignalAttendeeMutation($signal: SignalInput!) {
    signalAttendee(signal: $signal) {
      success
    }
  }
`
export const SignalAttendeesMutation = gql`
  mutation SignalAttendeesMutation($signal: BroadcastSignalInput!) {
    signalAttendees(signal: $signal) {
      success
    }
  }
`
export const UpdateEventMutation = gql`
  mutation UpdateEventMutation($eventId: ID!, $event: EventInput!) {
    updateEvent(eventId: $eventId, event: $event) {
      ...EventFields
    }
  }
  ${EventFields}
`
export const UpgradeWaitlistedAttendeeMutation = gql`
  mutation UpgradeWaitlistedAttendeeMutation($eventId: ID!, $userId: ID!) {
    upgradeWaitlistedAttendee(eventId: $eventId, userId: $userId) {
      id
      rsvp
      waitlisted
    }
  }
`
export const UploadEventThumbMutation = gql`
  mutation UploadEventThumbMutation($eventId: ID!, $file: Upload!) {
    uploadEventThumb(eventId: $eventId, file: $file) {
      id
      thumb
    }
  }
`
export const DeleteAccountMutation = gql`
  mutation DeleteAccountMutation {
    deleteAccount {
      success
    }
  }
`
export const BaseUserQuery = gql`
  query BaseUserQuery($id: ID!) {
    user(id: $id) {
      ...BaseProfileFields
    }
  }
  ${BaseProfileFields}
`
export const BlockedUsersQuery = gql`
  query BlockedUsersQuery {
    me {
      id
      blockedUsers {
        ...BaseProfileFields
      }
    }
  }
  ${BaseProfileFields}
`
export const CurrentCollectiveIdQuery = gql`
  query CurrentCollectiveIdQuery {
    currentCollectiveId @client
  }
`
export const DiscoverEngagementsQuery = gql`
  query DiscoverEngagementsQuery(
    $engagementConnection: ConnectionInput! = { first: 50 }
    $sort: EngagementSortingInput!
    $filters: [FilterableEngagementField!]!
    $seriesId: ID
    $seriesSlug: String
    $ignoreEventId: ID
  ) {
    discoverEngagements(
      engagementConnection: $engagementConnection
      sort: $sort
      filters: $filters
      seriesId: $seriesId
      seriesSlug: $seriesSlug
      ignoreEventId: $ignoreEventId
    )
      @connection(
        key: "discoverEngagements"
        filter: ["sort", "filters", "seriesId", "seriesSlug", "ignoreEventId"]
      ) {
      edges {
        cursor
        node {
          ...EngagementFields
        }
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${EngagementFields}
  ${PageInfoFields}
`
export const EnrichmentsQuery = gql`
  query EnrichmentsQuery {
    enrichments {
      awaitingEnrichments
      suggestions {
        nfts {
          name
          tokenId
          url
          address
          collectionName
        }
      }
    }
  }
`
export const EventUserPlanQuery = gql`
  query EventUserPlanQuery($id: ID!) {
    event(id: $id) {
      id
      state
      actualStartTime
      userPlan {
        ...EventUserPlanFields
      }
    }
  }
  ${EventUserPlanFields}
`
export const GetEmailPreferencesQuery = gql`
  query GetEmailPreferencesQuery($emailId: ID!) {
    getEmailPreferences(emailId: $emailId) {
      truncatedEmail
      allowedTypes
      unsubscribedFromAll
    }
  }
`
export const HasChromeQuery = gql`
  query HasChromeQuery {
    hasChrome @client
  }
`
export const HasGmailQuery = gql`
  query HasGmailQuery {
    hasGmail @client
  }
`
export const IsConnectedQuery = gql`
  query IsConnectedQuery {
    isConnected @client(always: true)
  }
`
export const LanguageQuery = gql`
  query LanguageQuery($language: String!) {
    language(language: $language)
  }
`
export const NonceQuery = gql`
  query NonceQuery {
    me {
      id
      nonce
    }
  }
`
export const ResolveSubdomainQuery = gql`
  query ResolveSubdomainQuery($slug: String!) {
    resolveSubdomain(slug: $slug) {
      __typename
      ... on Collective {
        id
        publicUrl
      }
    }
  }
`
export const SEOQuery = gql`
  query SEOQuery($path: ID!) {
    seo(path: $path) {
      path
      title
      metaDescription
      metaKeywords
      additionalMetaTags {
        name
        content
      }
    }
  }
`
export const SearchQuery = gql`
  query SearchQuery($query: String!) {
    search(query: $query) {
      hits {
        __typename
        ... on Collective {
          id
          publicUrl
          thumb
          name
          description
          totalMembers
          tokens {
            id
            symbol
          }
          membership {
            id
            privileged
            approved
          }
          membershipRequest {
            id
            status
          }
        }
        ... on CollectiveProposal {
          id
          publicUrl
          status
          title
          type
          startTime
          endTime
          myVote
          proposedBy {
            id
            user {
              id
              address
              platformUser {
                id
                thumb
                shortDisplayName
                userType
                isBot @client
              }
            }
          }
          isBatch
        }
        ... on User {
          id
          publicUrl
          thumb
          shortDisplayName
          userType
          isBot @client
        }
        ... on Event {
          id
          publicUrl
          thumb
          eventTitle: title
          location
          counts {
            attending
          }
          user {
            id
            thumb
            shortDisplayName
          }
          series {
            id
            label
          }
        }
      }
      totalHits
    }
  }
`
export const SiteFlagsQuery = gql`
  query SiteFlagsQuery {
    flags {
      ... on SiteFlagString {
        id
        key
        stringValue: value
        type
      }
      ... on SiteFlagNumber {
        id
        key
        numberValue: value
        type
      }
      ... on SiteFlagBoolean {
        id
        key
        booleanValue: value
        type
      }
    }
  }
`
export const TagsQuery = gql`
  query TagsQuery {
    tags {
      ...TagFields
    }
  }
  ${TagFields}
`
export const TokenQuery = gql`
  query TokenQuery {
    token @client
  }
`
export const UserEngagementDatesQuery = gql`
  query UserEngagementDatesQuery($id: ID!) {
    user(id: $id) {
      id
      engagementDates
    }
  }
`
export const UserEngagementsQuery = gql`
  query UserEngagementsQuery(
    $id: ID!
    $engagementConnection: ConnectionInput!
    $sort: EngagementSortingInput!
    $filters: [FilterableEngagementField!]!
    $dateFilter: Date
  ) {
    user(id: $id) {
      id
      engagements(
        engagementConnection: $engagementConnection
        sort: $sort
        filters: $filters
        dateFilter: $dateFilter
      ) @connection(key: "userEngagements") {
        edges {
          cursor
          node {
            ...EngagementFields
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${EngagementFields}
  ${PageInfoFields}
`
export const UserQuery = gql`
  query UserQuery($id: ID!) {
    user(id: $id) {
      ...ProfileFields
      canSendDM
      syncStatus
      syncStatusDate
    }
  }
  ${ProfileFields}
`
export const ViewerQuery = gql`
  query ViewerQuery($deviceInfo: DeviceInfoInput) {
    me(deviceInfo: $deviceInfo) {
      ...ViewerFields
    }
  }
  ${ViewerFields}
`
export const EphemeralCryptoAuthQuery = gql`
  query EphemeralCryptoAuthQuery {
    ephemeralCryptoAuth {
      id
      authMessage
    }
  }
`
export const CollectiveApplicationQuery = gql`
  query CollectiveApplicationQuery($applicationId: ID!) {
    collectiveApplication(applicationId: $applicationId) {
      id
      thumb
      payload
      status
      createdAt
    }
  }
`
export const CollectiveApplicationsQuery = gql`
  query CollectiveApplicationsQuery {
    collectiveApplications {
      id
      thumb
      payload
      status
      createdAt
    }
  }
`
export const CollectiveDraftQuery = gql`
  query CollectiveDraftQuery($collectiveDraftId: ID!) {
    collectiveDraft(collectiveDraftId: $collectiveDraftId) {
      ...CollectiveDraftFields
    }
  }
  ${CollectiveDraftFields}
`
export const ViewerCollectiveDraftsQuery = gql`
  query ViewerCollectiveDraftsQuery($collectiveConnection: ConnectionInput!) {
    me {
      id
      collectiveDrafts(collectiveConnection: $collectiveConnection)
        @connection(key: "userCollectiveDrafts") {
        edges {
          cursor
          node {
            ...CollectiveDraftFields
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${CollectiveDraftFields}
  ${PageInfoFields}
`
export const AuthMessageQuery = gql`
  query AuthMessageQuery {
    authMessage
  }
`
export const BaseCollectiveQuery = gql`
  query BaseCollectiveQuery($collectiveId: ID!) {
    collective(collectiveId: $collectiveId) {
      id
      thumb
      network
      name
      description
      totalMembers
      totalVotingMembers
      signators {
        id
        user {
          id
          platformUser {
            ...BaseProfileFields
          }
        }
      }
      governanceType
      tokens {
        symbol
      }
      parameters {
        enableNewMembershipRequests
        minEthContribution
      }
      archived
      syncStatus {
        syncStartedAt
        lastSyncedAt
        status
      }
      ogImageUrl
      publicUrl
      createdAt
    }
  }
  ${BaseProfileFields}
`
export const CollectiveAssetsQuery = gql`
  query CollectiveAssetsQuery(
    $collectiveId: ID!
    $assetsConnection: ConnectionInput!
    $where: CollectiveAssetsQueryInput!
  ) {
    collective(collectiveId: $collectiveId) {
      id
      assets(assetsConnection: $assetsConnection, where: $where)
        @connection(key: "collectiveAssets", filter: ["where"]) {
        edges {
          cursor
          node {
            ...CollectiveAssetFieldsFragment
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${CollectiveAssetFieldsFragment}
  ${PageInfoFields}
`
export const CollectiveDemoQuery = gql`
  query CollectiveDemoQuery($collectiveDemoId: ID!) {
    collectiveDemo(collectiveDemoId: $collectiveDemoId) {
      id
      collective
    }
  }
`
export const CollectiveEngagementsQuery = gql`
  query CollectiveEngagementsQuery(
    $collectiveId: ID!
    $engagementConnection: ConnectionInput!
    $sort: EngagementSortingInput!
    $filters: [FilterableEngagementField!]!
  ) {
    collective(collectiveId: $collectiveId) {
      id
      engagements(
        engagementConnection: $engagementConnection
        sort: $sort
        filters: $filters
      ) {
        edges {
          cursor
          node {
            ...EngagementFields
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${EngagementFields}
  ${PageInfoFields}
`
export const CollectiveFaqsQuery = gql`
  query CollectiveFaqsQuery($screen: CollectiveScreen, $limit: Int) {
    collectiveFaqs(screen: $screen, limit: $limit) {
      question
      snippet
      link
    }
  }
`
export const ListCollectiveLogsQuery = gql`
  query ListCollectiveLogsQuery(
    $collectiveId: ID!
    $requestConnection: ConnectionInput!
    $where: CollectiveLogsQueryInput!
  ) {
    collective(collectiveId: $collectiveId) {
      id
      logs(requestConnection: $requestConnection, where: $where)
        @connection(key: "collectiveLogs", filter: ["where"]) {
        edges {
          cursor
          node {
            ...BundledCollectiveLogFieldsFragment
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${BundledCollectiveLogFieldsFragment}
  ${PageInfoFields}
`
export const CollectiveMemberFileExportQuery = gql`
  query CollectiveMemberFileExportQuery(
    $collectiveId: ID!
    $fileExportId: ID!
  ) {
    collective(collectiveId: $collectiveId) {
      id
      memberFileExport(fileExportId: $fileExportId) {
        id
        status
        errorMessage
        type
        file
      }
    }
  }
`
export const CollectiveMembersQuery = gql`
  query CollectiveMembersQuery(
    $collectiveId: ID!
    $memberConnection: ConnectionInput!
    $searchString: String
  ) {
    collective(collectiveId: $collectiveId) {
      id
      members(memberConnection: $memberConnection, searchString: $searchString)
        @connection(key: "collectiveMembers", filter: ["searchString"]) {
        edges {
          cursor
          node {
            ...CollectiveMemberFields
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${CollectiveMemberFields}
  ${PageInfoFields}
`
export const CollectiveMembershipRequestsQuery = gql`
  query CollectiveMembershipRequestsQuery($collectiveId: ID!) {
    collective(collectiveId: $collectiveId) {
      id
      membershipRequests {
        id
        user {
          id
          address
          platformUser {
            ...BaseProfileFields
          }
        }
        notes
        status
        createdAt
      }
    }
  }
  ${BaseProfileFields}
`
export const CollectiveProposalDelegationsQuery = gql`
  query CollectiveProposalDelegationsQuery($proposalId: ID!) {
    collectiveProposal(proposalId: $proposalId) {
      id
      eligibleDelegations {
        ...CollectiveMemberFields
      }
    }
  }
  ${CollectiveMemberFields}
`
export const CollectiveProposalOgImageQuery = gql`
  query CollectiveProposalOgImageQuery($proposalId: ID!) {
    collectiveProposal(proposalId: $proposalId) {
      id
      status
      title
      description
      startTime
      endTime
      proposedBy {
        ...CollectiveMemberFields
      }
      collective {
        id
        thumb
        network
        name
        description
      }
      ogImageUrl
      publicUrl
      createdAt
    }
  }
  ${CollectiveMemberFields}
`
export const CollectiveProposalQuery = gql`
  query CollectiveProposalQuery($proposalId: ID!) {
    collectiveProposal(proposalId: $proposalId) {
      ...CollectiveProposalFields
    }
  }
  ${CollectiveProposalFields}
`
export const CollectiveVotesQuery = gql`
  query CollectiveVotesQuery(
    $proposalId: ID!
    $voteConnection: ConnectionInput!
  ) {
    collectiveProposal(proposalId: $proposalId) {
      id
      votes(voteConnection: $voteConnection)
        @connection(key: "collectiveProposalVotes") {
        edges {
          cursor
          node {
            ...CollectiveProposalVoteFieldsFragment
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${CollectiveProposalVoteFieldsFragment}
  ${PageInfoFields}
`
export const CollectiveProposalsQuery = gql`
  query CollectiveProposalsQuery(
    $collectiveId: ID!
    $proposalConnection: ConnectionInput!
    $searchString: String
  ) {
    collective(collectiveId: $collectiveId) {
      id
      proposals(
        proposalConnection: $proposalConnection
        searchString: $searchString
      ) @connection(key: "collectiveProposals", filter: ["searchString"]) {
        edges {
          cursor
          node {
            ...CollectiveProposalSummaryFields
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${CollectiveProposalSummaryFields}
  ${PageInfoFields}
`
export const CollectiveQuery = gql`
  query CollectiveQuery(
    $collectiveId: ID!
    $proposalConnection: ConnectionInput!
  ) {
    collective(collectiveId: $collectiveId) {
      id
      thumb
      address
      delegationAddress
      safeAddress
      safeThreshold
      network
      name
      description
      parameters {
        votingDelayInMinutes
        votingPeriodInMinutes
        quorumNeededInPercentage
        proposalThreshold
        depositFeeInPercentage
        withdrawFeeInPercentage
        enableNewMembershipRequests
        enableDeposits
        enableWithdraws
        gateDeposits
        exchangeRate
        minEthContribution
        disableTokenTransfers
        requireVotingSignature
        votingAnonymity
      }
      archived
      syncStatus {
        syncStartedAt
        lastSyncedAt
        status
      }
      totalProposals
      activeProposals
      proposals(proposalConnection: $proposalConnection)
        @connection(key: "collectiveProposals") {
        edges {
          cursor
          node {
            ...CollectiveProposalSummaryFields
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
      totalMembers
      totalVotingMembers
      totalMembershipRequests
      treasuryBalance
      governanceType
      tokens {
        id
        isMainToken
        type
        address
        voteWeight
        symbol
        decimals
        totalSupply
        totalVotingSupply
        thumb
        name
      }
      erc20Assets {
        id
        name
        symbol
        address
        balance
        decimals
        thumb
        lastSynced
        estimatedValueEth
        estimatedValueUSD
        wallet {
          id
          isMainWallet
        }
      }
      nftAssets {
        id
        tokenId
        name
        symbol
        address
        amount
        contractType
        uri
        mediaUrls
        description
        externalUrl
        lastSynced
        estimatedValueEth
        estimatedValueUSD
        wallet {
          id
          isMainWallet
        }
      }
      externalAssets {
        id
        type
        address
        thumb
        externalUrl
        symbol
        formattedAmount
        lastSynced
        estimatedValueUSD
        wallet {
          id
          isMainWallet
        }
      }
      wallets {
        id
        balance
        address
        isMainWallet
        nickname
        type
      }
      announcement {
        ...CollectiveAnnouncementFields
      }
      membership {
        id
        totalTokens
        percentOfTotalTokenSupply
        privileged
        approved
        user {
          id
          address
        }
      }
      membershipRequest {
        id
        status
        notes
        createdAt
      }
      ogImageUrl
      publicUrl
      createdAt
      discussionBoard {
        id
      }
    }
  }
  ${CollectiveProposalSummaryFields}
  ${PageInfoFields}
  ${CollectiveAnnouncementFields}
`
export const CollectiveSignatorsQuery = gql`
  query CollectiveSignatorsQuery($collectiveId: ID!) {
    collective(collectiveId: $collectiveId) {
      id
      signators {
        id
        user {
          id
          address
          platformUser {
            ...BaseProfileFields
          }
        }
      }
    }
  }
  ${BaseProfileFields}
`
export const CollectiveStatsQuery = gql`
  query CollectiveStatsQuery(
    $collectiveId: ID!
    $startDate: Date
    $endDate: Date
  ) {
    collective(collectiveId: $collectiveId) {
      id
      statsDatapoints(startDate: $startDate, endDate: $endDate) {
        time
        estimatedTotalValueEth
        estimatedTotalValueUSD
        totalMembers
        totalProposals
      }
      wallets {
        id
        nickname
        statsDatapoints(startDate: $startDate, endDate: $endDate) {
          time
          estimatedValueEth
          estimatedValueUSD
          estimatedNFTValueEth
          estimatedNFTValueUSD
          estimatedTokensValueEth
          estimatedTokensValueUSD
          estimatedTotalValueEth
          estimatedTotalValueUSD
          conversionRateUSD
        }
      }
    }
  }
`
export const CollectiveTosQuery = gql`
  query CollectiveTosQuery($collectiveId: ID!) {
    collectiveTos(collectiveId: $collectiveId) {
      id
      revision
      tosHtml
      createdAt
      isSigned
      collectiveId
    }
  }
`
export const CollectivesDashboardQuery = gql`
  query CollectivesDashboardQuery($collectiveConnection: ConnectionInput!) {
    me {
      id
      collectiveDrafts(collectiveConnection: $collectiveConnection) {
        edges {
          cursor
          node {
            ...CollectiveDraftFields
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
      collectives(collectiveConnection: $collectiveConnection) {
        edges {
          cursor
          node {
            id
            thumb
            address
            network
            name
            description
            totalProposals
            activeProposals
            totalMembers
            totalVotingMembers
            totalMembershipRequests
            governanceType
            tokens {
              id
              isMainToken
              type
              address
              voteWeight
              symbol
              decimals
              totalSupply
              totalVotingSupply
              thumb
              name
            }
            membership {
              id
              percentOfTotalTokenSupply
              privileged
              approved
            }
            membershipRequest {
              id
              status
            }
            publicUrl
            createdAt
            announcement {
              ...CollectiveAnnouncementFields
            }
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
      proposalsToVote {
        ...CollectiveProposalFields
        collective {
          totalVotingMembers
          membership {
            id
            percentOfTotalTokenSupply
            privileged
            approved
          }
          tokens {
            totalVotingSupply
            decimals
          }
          parameters {
            quorumNeededInPercentage
          }
          archived
        }
      }
      proposalsToExecute {
        ...CollectiveProposalFields
        collective {
          totalVotingMembers
          membership {
            id
            percentOfTotalTokenSupply
            privileged
            approved
          }
          tokens {
            totalVotingSupply
            decimals
          }
          parameters {
            quorumNeededInPercentage
          }
          archived
        }
      }
    }
  }
  ${CollectiveDraftFields}
  ${PageInfoFields}
  ${CollectiveAnnouncementFields}
  ${CollectiveProposalFields}
`
export const CollectivesQuery = gql`
  query CollectivesQuery(
    $collectiveConnection: ConnectionInput = { first: 50 }
  ) {
    me {
      id
      collectives(collectiveConnection: $collectiveConnection)
        @connection(key: "collectives") {
        edges {
          cursor
          node {
            id
            thumb
            network
            name
            description
            activeProposals
            totalMembers
            membership {
              id
              privileged
              approved
            }
            membershipRequest {
              id
              status
            }
            publicUrl
            archived
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${PageInfoFields}
`
export const ContractAbiQuery = gql`
  query ContractAbiQuery($address: String!, $network: EthNetwork!) {
    contractAbi(address: $address, network: $network) {
      abi
      network
    }
  }
`
export const DelegatesGrantedByViewerQuery = gql`
  query DelegatesGrantedByViewerQuery($collectiveId: ID!) {
    collective(collectiveId: $collectiveId) {
      id
      delegatesGrantedByViewer {
        command
        wildcard
        surveys
        collectiveMember {
          ...CollectiveMemberFields
        }
        maxValue
      }
    }
  }
  ${CollectiveMemberFields}
`
export const DelegatesGrantedToViewerQuery = gql`
  query DelegatesGrantedToViewerQuery($collectiveId: ID!) {
    collective(collectiveId: $collectiveId) {
      id
      delegatesGrantedToViewer {
        command
        collectiveMember {
          ...CollectiveMemberFields
        }
        maxValue
        wildcard
        surveys
      }
    }
  }
  ${CollectiveMemberFields}
`
export const EstimatedExecutionGasQuery = gql`
  query EstimatedExecutionGasQuery($proposalId: ID!) {
    collectiveProposal(proposalId: $proposalId) {
      id
      estimatedExecutionGas
    }
  }
`
export const CategoryThreadsQuery = gql`
  query CategoryThreadsQuery(
    $categoryId: ID!
    $threadConnection: ConnectionInput!
    $collaboratorConnection: ConnectionInput!
  ) {
    category(categoryId: $categoryId) {
      ...CategoryFields
      threads(threadConnection: $threadConnection)
        @connection(key: "discussionBoardThreads") {
        edges {
          cursor
          node {
            ...ThreadFields
            collaborators(collaboratorConnection: $collaboratorConnection)
              @connection(key: "discussionBoardThreadCollaborators") {
              edges {
                cursor
                node {
                  ...ProfileFields
                }
              }
              pageInfo {
                ...PageInfoFields
              }
            }
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${CategoryFields}
  ${ThreadFields}
  ${ProfileFields}
  ${PageInfoFields}
`
export const CommentQuery = gql`
  query CommentQuery($commentId: ID!) {
    comment(commentId: $commentId) {
      ...CommentFields
    }
  }
  ${CommentFields}
`
export const CommentRepliesQuery = gql`
  query CommentRepliesQuery(
    $commentId: ID!
    $commentConnection: ConnectionInput!
  ) {
    comment(commentId: $commentId) {
      id
      comments(commentConnection: $commentConnection)
        @connection(key: "discussionBoardSubComments") {
        edges {
          cursor
          node {
            ...CommentFields
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${CommentFields}
  ${PageInfoFields}
`
export const DiscussionBoardQuery = gql`
  query DiscussionBoardQuery($discussionBoardId: ID!) {
    discussionBoard(discussionBoardId: $discussionBoardId) {
      ...DiscussionBoardFields
    }
  }
  ${DiscussionBoardFields}
`
export const ThreadCollaboratorsQuery = gql`
  query ThreadCollaboratorsQuery(
    $threadId: ID!
    $collaboratorConnection: ConnectionInput!
  ) {
    thread(threadId: $threadId) {
      id
      collaborators(collaboratorConnection: $collaboratorConnection)
        @connection(key: "discussionBoardThreadCollaborators") {
        edges {
          cursor
          node {
            ...ProfileFields
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${ProfileFields}
  ${PageInfoFields}
`
export const ThreadCommentsQuery = gql`
  query ThreadCommentsQuery(
    $threadId: ID!
    $commentConnection: ConnectionInput!
    $repliesConnection: ConnectionInput!
    $collaboratorConnection: ConnectionInput!
  ) {
    thread(threadId: $threadId) {
      id
      comments(commentConnection: $commentConnection)
        @connection(key: "discussionBoardComments") {
        edges {
          cursor
          node {
            ...CommentFields
            comments(commentConnection: $repliesConnection)
              @connection(key: "discussionBoardComments") {
              edges {
                cursor
                node {
                  ...CommentFields
                }
              }
              pageInfo {
                ...PageInfoFields
              }
            }
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${CommentFields}
  ${PageInfoFields}
`
export const ThreadFileGetURLQuery = gql`
  query ThreadFileGetURLQuery($discussionBoardId: ID!, $key: String!) {
    threadFileGetUrl(discussionBoardId: $discussionBoardId, key: $key) {
      url
    }
  }
`
export const ThreadFileUploadURLQuery = gql`
  query ThreadFileUploadURLQuery(
    $discussionBoardId: ID!
    $fileName: String
    $fileExtension: String
    $fileType: String
  ) {
    threadFileUploadUrl(
      discussionBoardId: $discussionBoardId
      fileName: $fileName
      fileType: $fileType
    ) {
      url
      key
    }
  }
`
export const ThreadQuery = gql`
  query ThreadQuery($threadId: ID!, $collaboratorConnection: ConnectionInput!) {
    thread(threadId: $threadId) {
      ...ThreadFields
      collaborators(collaboratorConnection: $collaboratorConnection)
        @connection(key: "discussionBoardThreadCollaborators") {
        edges {
          cursor
          node {
            ...ProfileFields
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${ThreadFields}
  ${ProfileFields}
  ${PageInfoFields}
`
export const CurrentEventQuery = gql`
  query CurrentEventQuery {
    currentEvent {
      ...EventFields
    }
  }
  ${EventFields}
`
export const EventHeartbeatQuery = gql`
  query EventHeartbeatQuery($id: ID!) {
    eventHeartbeat(id: $id) {
      success
    }
  }
`
export const EventHostQuery = gql`
  query EventHostQuery($id: ID!) {
    event(id: $id) {
      ...EventFields
      onlineGuestCount
      onlineGuestIds
      joinLobbyInMute
      plan {
        mainChannelSessionId
      }
    }
  }
  ${EventFields}
`
export const EventMessagesQuery = gql`
  query EventMessagesQuery($id: ID!, $messageConnection: ConnectionInput!) {
    event(id: $id) {
      id
      messages(messageConnection: $messageConnection)
        @connection(key: "eventMessages") {
        edges {
          cursor
          node {
            id
            type
            text
            user {
              ...BaseProfileFields
            }
            createdAt
            deleted
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${BaseProfileFields}
  ${PageInfoFields}
`
export const EventNoShowsQuery = gql`
  query EventNoShowsQuery($id: ID!) {
    eventNoShows(id: $id) {
      ...BaseProfileFields
    }
  }
  ${BaseProfileFields}
`
export const EventQuery = gql`
  query EventQuery($id: ID!) {
    event(id: $id) {
      ...EventFields
      ogImageUrl
      onlineGuestCount
      onlineGuestIds
      joinLobbyInMute
      recording
      media {
        enableBorders
        borders {
          id
          meetingTopUrl
          meetingBottomUrl
          meetingHeightRatio
          lobbyTopUrl
          lobbyHeightRatio
        }
        enableDJ
        djUser {
          id
        }
        djPlaylist {
          id
          title
          artist
          url
        }
      }
      assets {
        id
        public
        url
        caption
        thumbnail
        type
        createdAt
        deleted
      }
    }
  }
  ${EventFields}
`
export const EventRegistrationsQuery = gql`
  query EventRegistrationsQuery(
    $id: ID!
    $registrationConnection: ConnectionInput!
  ) {
    event(id: $id) {
      id
      registrations(registrationConnection: $registrationConnection)
        @connection(key: "registrations") {
        edges {
          cursor
          node {
            id
            role
            rsvp
            waitlisted
            passive
            online
            attended
            user {
              ...ProfileFields
            }
            createdAt
            deleted
          }
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${ProfileFields}
  ${PageInfoFields}
`
export const EventSeriesBySlugQuery = gql`
  query EventSeriesBySlugQuery($slug: String!) {
    eventSeriesBySlug(slug: $slug) {
      ...EventSeriesFields
    }
  }
  ${EventSeriesFields}
`
export const EventSeriesSuggestionQuery = gql`
  query EventSeriesSuggestionQuery($query: String!) {
    eventSeriesSuggestion(query: $query) {
      ...EventSeriesFields
    }
  }
  ${EventSeriesFields}
`
export const FeaturedEventsQuery = gql`
  query FeaturedEventsQuery($first: Int = 5) {
    featuredEvents(first: $first) {
      id
      title
      description
      format
      formatLabel
      startTime
    }
  }
`
export const FeaturedSpeakersQuery = gql`
  query FeaturedSpeakersQuery($first: Int = 5) {
    featuredSpeakers(first: $first) {
      speaker {
        ...BaseProfileFields
      }
      event {
        id
        title
        description
        format
        formatLabel
        startTime
      }
    }
  }
  ${BaseProfileFields}
`
export const PreviousEventsQuery = gql`
  query PreviousEventsQuery($first: Int = 10, $skip: Int = 0) {
    previousEvents(first: $first, skip: $skip) {
      id
      url
      thumb
      caption
      createdAt
    }
  }
`
export const UserAssetQuery = gql`
  query UserAssetQuery(
    $userId: ID!
    $assetConnectionInput: ConnectionInput!
    $assetFilters: AssetQueryInput!
  ) {
    user(id: $userId) {
      id
      assets(
        assetConnectionInput: $assetConnectionInput
        assetFilters: $assetFilters
      ) @connection(key: "userAssets") {
        edges {
          cursor
          node {
            ...UserAssetFields
          }
        }
        pageInfo {
          ...PageInfoFields
        }
        awaitingSync
      }
    }
  }
  ${UserAssetFields}
  ${PageInfoFields}
`
export const EmailVerifiedChangedSubscription = gql`
  subscription EmailVerifiedChangedSubscription {
    emailVerifiedChanged {
      id
      emailVerified
    }
  }
`
export const CollectiveChangedSubscription = gql`
  subscription CollectiveChangedSubscription($collectiveId: ID!) {
    collectiveChanged(collectiveId: $collectiveId) {
      id
    }
  }
`
export const ProposalChangedSubscription = gql`
  subscription ProposalChangedSubscription($proposalId: ID!) {
    proposalChanged(proposalId: $proposalId) {
      id
    }
  }
`
export const CategoryAddedSubscription = gql`
  subscription CategoryAddedSubscription($discussionBoardId: ID!) {
    categoryAdded(discussionBoardId: $discussionBoardId) {
      ...CategoryFields
    }
  }
  ${CategoryFields}
`
export const CategoryChangedSubscription = gql`
  subscription CategoryChangedSubscription($categoryId: ID!) {
    categoryChanged(categoryId: $categoryId) {
      ...CategoryFields
    }
  }
  ${CategoryFields}
`
export const CommentAddedSubscription = gql`
  subscription CommentAddedSubscription($threadId: ID!) {
    commentAdded(threadId: $threadId) {
      ...CommentFields
      parentThread {
        id
        replyCount
      }
    }
  }
  ${CommentFields}
`
export const CommentChangedSubscription = gql`
  subscription CommentChangedSubscription($commentId: ID!) {
    commentChanged(commentId: $commentId) {
      ...CommentFields
    }
  }
  ${CommentFields}
`
export const DiscussionBoardChangedSubscription = gql`
  subscription DiscussionBoardChangedSubscription($discussionBoardId: ID!) {
    discussionBoardChanged(discussionBoardId: $discussionBoardId) {
      id
      categories {
        ...CategoryFields
      }
    }
  }
  ${CategoryFields}
`
export const ReplyAddedSubscription = gql`
  subscription ReplyAddedSubscription($commentId: ID!) {
    replyAdded(commentId: $commentId) {
      ...CommentFields
      parentComment {
        id
        replyCount
      }
    }
  }
  ${CommentFields}
`
export const ThreadAddedSubscription = gql`
  subscription ThreadAddedSubscription($categoryId: ID!) {
    threadAdded(categoryId: $categoryId) {
      ...ThreadFields
      category {
        id
        totalThreads
      }
    }
  }
  ${ThreadFields}
`
export const ThreadChangedSubscription = gql`
  subscription ThreadChangedSubscription($threadId: ID!) {
    threadChanged(threadId: $threadId) {
      ...ThreadFields
    }
  }
  ${ThreadFields}
`
export const ThreadPinnedSubscription = gql`
  subscription ThreadPinnedSubscription($categoryId: ID!) {
    threadPinned(categoryId: $categoryId) {
      ...ThreadFields
    }
  }
  ${ThreadFields}
`
export const EventMessageAddedSubscription = gql`
  subscription EventMessageAddedSubscription($eventId: ID!) {
    eventMessageAdded(eventId: $eventId) {
      id
      text
      type
      user {
        ...BaseProfileFields
      }
      event {
        id
      }
      createdAt
      deleted
    }
  }
  ${BaseProfileFields}
`
export const EventOnlineGuestCountChangedSubscription = gql`
  subscription EventOnlineGuestCountChangedSubscription($eventId: ID!) {
    eventOnlineGuestCountChanged(eventId: $eventId) {
      id
      onlineGuestCount
      onlineGuestIds
    }
  }
`
export const EventSignalReceivedSubscription = gql`
  subscription EventSignalReceivedSubscription($eventId: ID!) {
    eventSignalReceived(eventId: $eventId) {
      command
      data
    }
  }
`
export const EventStateChangedSubscription = gql`
  subscription EventStateChangedSubscription($eventId: ID!) {
    eventStateChanged(eventId: $eventId) {
      id
      state
      actualStartTime
      joinLobbyInMute
      recording
    }
  }
`
export const EventUserPlanUpdatedSubscription = gql`
  subscription EventUserPlanUpdatedSubscription($eventId: ID!) {
    eventUserPlanUpdated(eventId: $eventId) {
      id
      state
      actualStartTime
      userPlan {
        ...EventUserPlanFields
      }
    }
  }
  ${EventUserPlanFields}
`
