import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'
import { twMerge } from 'tailwind-merge'

interface IProps {
  size?: SizeProp
  className?: string
}

const Spinner: React.FC<IProps> = ({ size = 'lg', className }) => {
  return (
    <div
      className={twMerge(
        'flex justify-center items-center h-full w-full',
        className
      )}
    >
      <FontAwesomeIcon icon={faSpinner} spin size={size} />
    </div>
  )
}

export default Spinner
