import React, { PropsWithChildren, MouseEvent } from 'react'

interface IBaseProps {
  event: string
  props?: object
}

type TTrackingProps =
  | {
      trackAll?: boolean
      destinations?: never
    }
  | {
      trackAll?: false
      destinations?: TAnalyticsDestination[]
    }

type TProps = IBaseProps & TTrackingProps

const TrackableClick = React.forwardRef<HTMLElement, PropsWithChildren<TProps>>(
  (
    {
      event: eventName,
      props: eventProperties,
      destinations,
      trackAll = false,
      children
    },
    ref
  ) => {
    const child = React.Children.only(children)

    return React.isValidElement(child)
      ? React.cloneElement(child, {
          // @ts-ignore
          ref,
          onClick: function (e?: MouseEvent<HTMLElement>) {
            trackAll
              ? window.analytics.trackAll(eventName, eventProperties)
              : window.analytics.track(eventName, eventProperties, destinations)

            child.props.onClick?.(e)
          }
        })
      : null
  }
)

TrackableClick.displayName = 'TrackableClick'

export default TrackableClick
