import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

if (!(Bugsnag as any)._client) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY!,
    appVersion: process.env.NEXT_PUBLIC_VERSION_HASH!,
    releaseStage:
      process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE || 'development',
    enabledReleaseStages: ['production'],
    plugins: [new BugsnagPluginReact()]
  })
}

export default Bugsnag
