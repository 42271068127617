import { UserType } from 'graphql/generated'
import Qs from 'qs'

export const isProd = process.env.NODE_ENV === 'production'

export function toCursor(input: any): string {
  return input ? Buffer.from(input.toString()).toString('base64') : ''
}

//helper method to create correctly formatted share URLs
export function shareLinkOnExternalSite(
  externalUrl: string,
  internalLinkObject: KVMap<Maybe<string | number>>
) {
  return externalUrl + '?' + Qs.stringify(internalLinkObject)
}

// determines if user is bot
export function isUserBot(userType: UserType) {
  return userType === UserType.BOT
}

export function shouldShowOnIndex(skipBy: number, startingPoint: number) {
  const adjustSkipForIndex = 1 + skipBy - startingPoint
  return index => {
    return (
      (index + adjustSkipForIndex) % skipBy === 0 && index >= startingPoint - 1
    )
  }
}
