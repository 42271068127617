import { Placement } from '@popperjs/core'
import { Typography } from 'components/common'
import Popover from 'components/common/Popover'
import React from 'react'

export const StyledTooltip = React.forwardRef<
  HTMLElement,
  {
    arrow?: boolean
    title?: string | number | false
    placement?: Placement
  } & React.PropsWithChildren
>(({ arrow = true, title, children, placement = 'bottom' }, ref) => {
  if (!title) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <React.Fragment>{children}</React.Fragment>
  }

  const childTrigger = (
    ref: (instance: HTMLElement | null) => void,
    _onClick: Callback<void>,
    _visible: boolean,
    show: Callback<void>,
    hide: Callback<void>
  ) =>
    React.isValidElement(children) ? (
      React.cloneElement(children, {
        // @ts-ignore
        ref,
        // className: `${children.props.className || ''} tooltip`,
        onMouseEnter: () => {
          show()
          children.props.onMouseEnter?.()
        },
        onMouseLeave: () => {
          hide()
          children.props.onMouseLeave?.()
        }
      })
    ) : (
      <span />
    )

  return (
    <Popover
      from={childTrigger}
      placement={placement}
      className="bg-gray-700 border-0 tooltip flex justify-center items-center rounded px-3 my-1 py-0.5"
      useArrow={arrow}
      arrowClassName="text-gray-700"
      usePortal
      useFade
      fadeProps={{ duration: 300 }}
      ref={ref}
    >
      <Typography component={'div'} size="xs" color="white">
        {title}
      </Typography>
    </Popover>
  )
})

StyledTooltip.displayName = 'Tooltip'

export default StyledTooltip
