import {
  FetchPolicy,
  OperationVariables,
  useApolloClient
} from '@apollo/client'
import { useCallback } from 'react'
import { DocumentNode } from 'graphql'

/**
 * Use this wrapper around Apollo's native useLazyQuery() when you
 * need to wait for the lazy query's response
 */
export function useLazyQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(query: DocumentNode, fetchPolicy: FetchPolicy = 'no-cache') {
  const client = useApolloClient()
  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        variables: variables,
        fetchPolicy
      }),
    [client, query, fetchPolicy]
  )
}
