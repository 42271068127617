import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'
import { noop } from 'lodash'
import Link from 'next/link'
import React, {
  ForwardedRef,
  forwardRef,
  HTMLAttributeAnchorTarget,
  MouseEvent
} from 'react'
import { twMerge } from 'tailwind-merge'

type Size = 'xs' | 'sm' | 'md'
export interface IProps {
  icon?: IconProp
  size?: Size
  color?: 'gray' | 'white' | 'transparent' | 'lightGray' | 'lightBlue'
  open?: boolean
  href?: string
  target?: HTMLAttributeAnchorTarget
  onClick?: CallbackWithParam<MouseEvent<HTMLElement>> | undefined
  loading?: boolean
  className?: string
  openClassName?: string
  disabled?: boolean
  addtionalIconProps?: Partial<Omit<FontAwesomeIconProps, 'icon' | 'size'>>
  children?: React.ReactNode
}

const IconSizeMap: Record<Size, FontAwesomeIconProps['size']> = {
  xs: 'sm',
  sm: '1x',
  md: 'lg'
}

function RoundedIconButton(
  {
    icon,
    size = 'sm',
    color = 'gray',
    open = false,
    className,
    openClassName,
    onClick,
    href,
    target,
    loading,
    children,
    disabled,
    addtionalIconProps,
    ...rest
  }: IProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const button = (
    <div
      ref={ref}
      className={twMerge(
        'relative h-10 px-3 rounded-full flex justify-center items-center cursor-pointer group',
        color === 'gray' &&
          (open ? 'bg-gray-400' : 'bg-gray-200 hover:bg-gray-300'),
        color === 'lightGray' &&
          (open ? 'bg-gray-300' : 'bg-gray-100 hover:bg-gray-200'),
        color === 'white' &&
          (open ? 'bg-gray-100' : 'bg-white hover:bg-gray-50'),
        color === 'lightBlue' &&
          (open ? 'bg-blue-50' : 'bg-blue-100 hover:bg-blue-200'),
        color === 'transparent' && (open ? 'bg-gray-200' : 'hover:bg-gray-100'),

        size === 'xs' && 'h-9 w-9',

        className,
        open && openClassName
      )}
      onClick={disabled ? noop : onClick}
      {...rest}
    >
      {icon && (
        <FontAwesomeIcon
          spin={loading}
          icon={loading ? faSpinner : icon}
          className={twMerge(
            color === 'gray' &&
              (open
                ? 'text-gray-50'
                : 'text-gray-600 group-hover:text-gray-800'),
            color === 'lightGray' &&
              (open
                ? 'text-gray-700'
                : 'text-gray-400 group-hover:text-gray-600'),
            color === 'white' &&
              (open
                ? 'text-gray-700'
                : 'text-gray-500 group-hover:text-gray-800'),
            color === 'lightBlue' &&
              (open
                ? 'text-blue-600'
                : 'text-blue-600 group-hover:text-blue-700'),
            color === 'transparent' &&
              (open
                ? 'text-gray-800'
                : 'text-gray-500 group-hover:text-gray-700')
          )}
          size={IconSizeMap[size]}
          {...addtionalIconProps}
        />
      )}
      {children}
    </div>
  )

  return href ? (
    <Link href={href} target={target}>
      {button}
    </Link>
  ) : (
    button
  )
}

export default forwardRef<HTMLDivElement, IProps>(RoundedIconButton)
