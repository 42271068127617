import { ApolloError } from '@apollo/client'
import { useCallback } from 'react'
import { isEmpty } from 'lodash'
import useToasts from 'hooks/useToasts'

export function parseGqlErrors(err: ApolloError | null | undefined): IError {
  if (!err) {
    return {
      _generic: '',
      code: 'UNEXPECTED_ERROR'
    }
  }

  const code = err?.graphQLErrors?.[0]?.extensions?.code as string
  const messsage = err.graphQLErrors?.[0]?.message
  const data = err?.graphQLErrors?.[0]?.extensions || {}

  const ret: IError<string> = {
    _generic: messsage,
    code,
    ...data
  }

  // merge errors by field path (e.g. signup.firstName)
  if (code === 'BAD_USER_INPUT' && !isEmpty(data.invalidArgs)) {
    // @ts-ignore
    data.invalidArgs.forEach((arg: any) => {
      ret[arg.path] = arg.message
    })
  }

  return ret
}

function useQueryErrorHandler(fallbackMessage = 'Something went wrong...') {
  const { addToast } = useToasts()
  const onError = useCallback(
    (err: ApolloError) => {
      const parsedError = parseGqlErrors(err)
      addToast(parsedError._generic || fallbackMessage, {
        appearance: 'warning'
      })
    },
    [fallbackMessage, addToast]
  )

  return onError
}

export default useQueryErrorHandler
