import { useCallback } from 'react'
import { useSetPreferenceMutation, UserPreferenceType } from 'graphql/generated'
import { useQueryErrorHandler } from 'hooks'
import useCurrentUser from './useCurrentUser'
import { updateViewerPreferences } from 'lib/cache/updateViewerPreference'

function useSetPreference() {
  const { id } = useCurrentUser()
  const onError = useQueryErrorHandler(
    'Failed to save Preference. Please try again later.'
  )
  const [setPreference] = useSetPreferenceMutation({
    onError
  })

  const getTypeForValue = useCallback((value: any) => {
    switch (typeof value) {
      case 'boolean':
        return UserPreferenceType.BOOLEAN
      case 'number':
        return UserPreferenceType.NUMBER
      default:
        return UserPreferenceType.STRING
    }
  }, [])

  return useCallback(
    (key, value) =>
      setPreference({
        variables: {
          preference: {
            key,
            type: getTypeForValue(value),
            value: String(value)
          }
        },
        update: (cache, { data }) => {
          if (data?.setPreference) {
            updateViewerPreferences(cache, id, data)
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          setPreference: {
            __typename: 'UserPreference',
            key,
            value
          }
        }
      }),
    [setPreference, getTypeForValue, id]
  )
}

export default useSetPreference
