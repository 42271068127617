import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast, Toaster, resolveValue, ToastOptions } from 'react-hot-toast'

import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useEffect,
  useState
} from 'react'

type TToastOptions = {
  appearance?: 'warning'
} & ToastOptions

interface ToastContextShape {
  addToast: (msg: string, options?: TToastOptions) => void
  error: (msg: string, options?: TToastOptions) => void
  success: (msg: string, options?: TToastOptions) => void
}

const ToastContext = createContext<ToastContextShape>({} as ToastContextShape)

export const useToastContext = () => useContext(ToastContext)

export function ToastProvider({ children }: PropsWithChildren<{}>) {
  const [ToasterEl, setToasterEl] = useState<React.ReactNode>()

  useEffect(() => {
    setToasterEl(
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          duration: 5000,
          success: {
            duration: 3000
          },
          error: {
            duration: 5000
          }
        }}
      >
        {t => (
          <div className="bg-white shadow-lg rounded-lg w-full sm:w-96 mb-2">
            <div className="p-4">
              <div className="flex items-start">
                <div className="shrink-0">
                  {t.type === 'error' ? (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="h-6 w-6 text-red-400"
                    />
                  ) : t.type === 'success' ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="h-6 w-6 text-green-400"
                    />
                  ) : null}
                </div>
                <div className="ml-3 flex-1">{resolveValue(t.message, t)}</div>
              </div>
            </div>
          </div>
        )}
      </Toaster>
    )
  }, [])

  const addToast = (msg: string, options: TToastOptions = {}) => {
    if (options.appearance === 'warning') {
      toast.error(msg, options)
    } else {
      toast(msg, options)
    }
  }
  const error = (msg: string, options: TToastOptions = {}) => {
    toast.error(msg, options)
  }
  const success = (msg: string, options: TToastOptions = {}) => {
    toast.success(msg, options)
  }

  return (
    <ToastContext.Provider value={{ addToast, error, success }}>
      {children}

      {ToasterEl}
    </ToastContext.Provider>
  )
}
