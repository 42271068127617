import { useModalContext } from 'context/ModalContext'
import { useCallback, useEffect, useState } from 'react'
import BaseModal from './BaseModal'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { faBadgerHoney } from '@fortawesome/pro-duotone-svg-icons'
import { useIsLoggedIn } from 'hooks'
import { useRouter } from 'next/router'
import Login from 'components/auth/Login'
import Button from 'components/Button'

export function useAuthPromptModal() {
  const { setModalContent } = useModalContext()
  return useCallback(
    (
      title?: Maybe<string>,
      body?: Maybe<string>,
      url?: Maybe<string>,
      onLogin?: Callback
    ) => {
      setModalContent(
        <AuthPromptModal
          title={title}
          body={body}
          url={url}
          onLogin={onLogin}
        />
      )
    },
    [setModalContent]
  )
}

export function useAuthPromptHandler(
  title?: Maybe<string>,
  body?: Maybe<string>,
  url?: Maybe<string>
) {
  const showAuthPromptModal = useAuthPromptModal()
  const isLoggedIn = useIsLoggedIn()
  return useCallback(
    (
      callback: Callback,
      overrideTitle?: Maybe<string>,
      overrideBody?: Maybe<string>,
      overrideUrl?: Maybe<string>
    ) => {
      if (!isLoggedIn) {
        showAuthPromptModal(
          overrideTitle || title,
          overrideBody || body,
          overrideUrl || url,
          callback
        )
        return
      }

      return callback()
    },
    [isLoggedIn, showAuthPromptModal, title, body, url]
  )
}

export function AuthPromptModal({
  title,
  body,
  url,
  onLogin
}: {
  title?: Maybe<string>
  body?: Maybe<string>
  url?: Maybe<string>
  onLogin?: Callback
}) {
  const { hideModal } = useModalContext()
  const router = useRouter()
  const [showLogin, setShowLogin] = useState(false)
  const onLoginCallback = useCallback(() => {
    hideModal()
    onLogin?.()
  }, [hideModal, onLogin])

  useEffect(() => {
    window.localStorage.setItem('_r', router.asPath)
    // eslint-disable-next-line
  }, [])

  return (
    <BaseModal>
      <div className="relative flex flex-col justify-center items-center px-10 pt-8 pb-4 space-y-2">
        <div
          className="absolute top-2 right-2 flex justify-center items-center text-blue-400 text-xl hover:bg-blue-100 active:bg-blue-200 w-7 h-7 rounded-full cursor-pointer"
          onClick={hideModal}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>

        {showLogin ? (
          <Login onLogin={onLoginCallback} />
        ) : (
          <>
            <FontAwesomeIcon
              icon={faBadgerHoney}
              className="text-blue-400"
              size={'3x'}
            />

            <div className="py-10">
              <p className="text-center font-medium text-xl">
                {title || `Keep up with your Communities & meet new people.`}
              </p>

              <p className="text-center font-light text-sm">{body}</p>
            </div>

            <Button
              label={'Login'}
              onClick={() => setShowLogin(true)}
              className="w-full"
              rounded={'full'}
            />

            <Link
              className="block hover:bg-blue-50 border border-blue rounded-full py-2 w-full text-blue font-medium cursor-pointer text-center"
              onClick={() => hideModal()}
              href={url || '/auth/signup'}
            >
              Sign up
            </Link>
          </>
        )}
      </div>
    </BaseModal>
  )
}
