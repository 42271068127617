import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useMemo,
  useCallback
} from 'react'
import { createPortal } from 'react-dom'

interface ModalContextShape {
  setModalContent: CallbackWithParam<Maybe<JSX.Element>>
  hideModal: Callback
}

const ModalContext = createContext<ModalContextShape>({} as ModalContextShape)

export const useModalContext = () => useContext(ModalContext)

export function ModalProvider({ children }: PropsWithChildren<{}>) {
  const [modalContent, setModalContent] = useState<Maybe<JSX.Element>>()
  const hideModal = useCallback(() => setModalContent(undefined), [])
  const el = useMemo(() => {
    // no portals in SSR
    if (typeof document === 'undefined') {
      return null
    }

    let modalRoot = document.querySelector('#modal-root')
    if (!modalRoot) {
      modalRoot = document.createElement('div')
      modalRoot.setAttribute('id', 'modal-root')
      document.body.appendChild(modalRoot)
    }

    return modalRoot
  }, [])

  return (
    <ModalContext.Provider value={{ setModalContent, hideModal }}>
      {children}

      {modalContent && el ? createPortal(modalContent, el) : null}
    </ModalContext.Provider>
  )
}
