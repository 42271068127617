import { UserPreference } from 'graphql/generated'
import { useCurrentUser } from 'hooks'
import { isEmpty } from 'lodash'

type Value = string | number | boolean | null | undefined

export function findPreference(
  preferences: (UserPreference | null)[] | null | undefined,
  key: string,
  defaultValue?: Value
) {
  if (!preferences || isEmpty(preferences)) {
    return defaultValue
  }

  const pref = preferences.find(p => p?.key === key)
  if (!pref) {
    return defaultValue
  }

  return pref.value
}

function usePreference(key: string): Value
function usePreference(key: string, defaultValue: string): string
function usePreference(key: string, defaultValue: number): number
function usePreference(key: string, defaultValue: boolean): boolean
function usePreference(key: string, defaultValue?: Value) {
  const { preferences } = useCurrentUser()
  const value = findPreference(preferences, key, defaultValue)
  if (!value) {
    return defaultValue
  }

  const valueStr = String(value).toLowerCase()
  if (valueStr === 'true') {
    return true
  } else if (valueStr === 'false') {
    return false
  }

  return value
}

export default usePreference
