import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { twMerge } from 'tailwind-merge'
import Typography from './Typography'

type TFill =
  | 'blue'
  | 'white'
  | 'gray'
  | 'yellow'
  | 'black'
  | 'lightYellow'
  | 'darkOrange'
type TColor =
  | 'blue'
  | 'white'
  | 'gray'
  | 'yellow'
  | 'black'
  | 'lightYellow'
  | 'darkOrange'
type TSize = 'sm' | 'md' | 'lg' | 'xs' | 'xxs'

const fillToColorMap: Record<TFill, TColor> = {
  blue: 'white',
  gray: 'black',
  white: 'blue',
  yellow: 'black',
  black: 'white',
  lightYellow: 'darkOrange',
  darkOrange: 'white'
}

const Chip: React.FC<{
  fill?: TFill
  border?: TFill
  color?: TColor
  size?: TSize
  icon?: IconProp
  label?: string | number | boolean
  iconPlacement?: 'before' | 'after'
  className?: string
  children?: React.ReactNode
}> = ({
  color,
  border,
  size = 'md',
  fill,
  label,
  icon,
  iconPlacement = 'before',
  children,
  className
}) => {
  color = color || (fill ? fillToColorMap[fill] : 'blue') // auto choose color based on fill if none provided, dafault to blue

  if (!border) {
    // auto choose border based on fill if none provided, dafault to blue (color)
    border = fill || color
  }

  fill = fill || 'white' // default fill to white if none provided

  const wrapperClasses = twMerge(
    'flex items-center border rounded-full',
    // border
    border === 'blue' && 'border-blue-500',
    border === 'gray' && 'border-gray-500',
    border === 'white' && 'border-white',
    border === 'yellow' && 'border-yellow-500',
    border === 'black' && 'border-gray-800',
    // text
    color === 'black' && 'text-gray-800',
    color === 'blue' && 'text-blue-500',
    color === 'gray' && 'text-gray-500',
    color === 'white' && 'text-white',
    color === 'yellow' && 'text-yellow-500',
    color === 'darkOrange' && 'text-orange-800',
    color === 'lightYellow' && 'bg-yellow-200',
    // bg
    fill === 'blue' && 'bg-blue-600',
    fill === 'gray' && 'bg-gray-500',
    fill === 'white' && 'bg-white',
    fill === 'black' && 'bg-gray-800',
    fill === 'yellow' && 'bg-yellow-500',
    fill === 'lightYellow' && 'bg-yellow-200',
    fill === 'darkOrange' && 'bg-orange-800',
    // spacing
    size === 'md' && 'px-2 py-1',
    size === 'lg' && 'px-3 py-1.5',
    size === 'sm' && 'px-1.5 py-0.5',
    size === 'xs' && 'px-1.5',
    size === 'xxs' && 'px-1.5',
    // text size
    size === 'md' && 'text-sm',
    size === 'lg' && 'text-md',
    size === 'sm' && 'text-xs',
    size === 'xs' && 'text-[0.65rem]',
    size === 'xxs' && 'text-[0.65rem] leading-snug',
    className
  )
  return (
    <Typography className={wrapperClasses} component={'span'}>
      {iconPlacement === 'before' && icon && (
        <FontAwesomeIcon icon={icon} className="mr-1" />
      )}
      {children}
      {label}
      {iconPlacement === 'after' && icon && (
        <FontAwesomeIcon icon={icon} className="ml-1" />
      )}
    </Typography>
  )
}
export default Chip
